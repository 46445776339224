import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "redux/store";
import { tokenAPICALL } from "services/authService";
import {
  resetPeriodsToInit,
  setJWTToken,
  setPeriodsList,
  setPeriodsMessage,
  setUserProfie,
  startLoadingPeriods,
  stopLoadingPeriods,
} from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Card, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import { getPeriods } from "services/periodsService";
import Layout from "template";

type Props = {
  section?: any;
};

const IndexPeriode: React.FC<Props> = ({ section }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const rData = useSelector((state) => state.authToken);
  // const periodsLoading = useSelector((state) => state.periods.loading);
  // const periodsData = useSelector((state) => state.periods);
  const periodsLoading = useSelector(
    (state: RootState) => state.periods.loading
  );
  const periodsData = useSelector((state: RootState) => state.periods);

  const period = localStorage.getItem("periode_id");

  console.log("period", period);
  const [selectedPeriods, setSelectedPeriods] = useState(
    period.toString() || "0"
  );

  // const token = localStorage.getItem("token");
  const roles = localStorage.getItem("user");
  // const [loading, setLoading] = useState(false);
  // const [section, setSection] = useState("");
  const handlePeriodsChange = (event) => {
    const newValue = event.target.value;
    setSelectedPeriods(newValue);
  };

  useEffect(
    () => {
      dispatch(startLoadingPeriods());
      getPeriods(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setPeriodsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingPeriods());
        } else {
          dispatch(setPeriodsMessage("Data kosong"));
          dispatch(stopLoadingPeriods());
        }
      });

      // return () => {
      //   second
      // }
    },
    [
      // third
    ]
  );

  const handlePeriods = () => {
    const periods = JSON.parse(selectedPeriods);
    localStorage.setItem("periode_id", periods?.id);
    localStorage.setItem("periode_year", periods?.year);
    localStorage.setItem("periode_month", periods?.month);
    navigate(
      roles === "Super Admin"
        ? "/pbp"
        : roles === "Admin / Juru Serah"
        ? "/bnba"
        : roles === "Admin STPJM"
        ? "/sptjm"
        : "/dashboard",
      { replace: true, state: { from: "login" } }
    );
    navigate(0);
  };

  return (
    <Layout>
      <div className="container-fluid">
        <Card className="shadow mb-4">
          <Card.Header className="py-3">
            <h6 className="m-0 font-weight-bold text-primary text-capitalize">
              <label htmlFor="">Pilih Periode</label>
              {/* {action === "add" ? "Tambah" : action === "edit" ? "Ubah" : "Lihat"}{" "}
            {editAction === "ubah-alamat"
              ? "Alamat PBP"
              : location?.pathname === "/verval/add"
              ? "Verval"
              : "PBP"} */}
              {/* <Button
                className="btn-icon-split float-right"
                onClick={
                  () => {}
                  // handleBack
                }>
                <span className="icon text-white-50">
                  <i className="fas fa-arrow-left"></i>
                </span>
                <span className="text">Kembali</span>
              </Button> */}
            </h6>
          </Card.Header>
          <Card.Body>
            <Form className=" mt-3">
              {/* <pre>{JSON.stringify(periodsData, null, 2)}</pre>
      <pre>{JSON.stringify(rData, null, 2)}</pre> */}
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control
                  as="select"
                  custom
                  onChange={handlePeriodsChange}
                  value={selectedPeriods}
                  required>
                  <option disabled value={period}>
                    {periodsLoading ? "Loading..." : "Pilih Periode"}
                  </option>
                  {periodsData.list.map((item, i) => {
                    return (
                      <option value={JSON.stringify(item)} key={`periods-${i}`}>
                        {item.id} ({item.month} - {item.year})
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Button
                style={{ borderRadius: 10 }}
                type="submit"
                className="btn-user btn-block"
                variant="primary"
                onClick={() => handlePeriods()}
                disabled={selectedPeriods === "0"}>
                Lanjutkan
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default IndexPeriode;
