import { useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { postExport } from "services/vervalService";
import Layout from "template";
import { Constant } from "template/Constant";

export const ExportLog = () => {
  const periodsId = localStorage.getItem("periode_id");
  const cityName = localStorage.getItem("city_name");
  const provinceName = localStorage.getItem("province_name");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleStartDate = (event) => {
    setStartDate(event.target.value); // Memperbarui nilai state saat input date berubah
  };
  const handleEndDate = (event) => {
    setEndDate(event.target.value); // Memperbarui nilai state saat input date berubah
  };

  async function handleExport() {
    setLoading(true);
    const params = {
      date_start: startDate,
      date_end: endDate,
      periode_id: periodsId,
    };
    try {
      const res = await postExport(params);
      if (res) {
        setLoading(false);
        setMessage("Sukses export log");
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `${provinceName} - ${cityName}/${startDate} sd ${endDate}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();  
        link.remove();
      }
    } catch (error) {
      setLoading(false);
      setMessage("Gagal export log");
    }
  }

  return (
    <Layout>
      <div className="container-fluid">
        {message && (
          <Alert
            variant={Constant.defaultAlertVarient}
            className="alert-dismissible fade"
          >
            {message}
          </Alert>
        )}

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Export Log Aktivitas Verval</h1>
        </div>
        <Form className="w-50">
          <Form.Group controlId="exampleForm.SelectCustom">
          <label className="form -control-label">Tanggal Mulai</label>
            <Form.Control
              type="date"
              name="start_date"
              custom
              className="form-control"
              value={startDate}
              onChange={handleStartDate} //
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.SelectCustom">
          <label className="form -control-label">Tanggal Akhir</label>
            <Form.Control
              type="date"
              name="end_date"
              custom
              className="form-control"
              value={endDate}
              onChange={handleEndDate} //
            ></Form.Control>
          </Form.Group>
          <Form.Group className="w-100">
            <Button
              type="submit"
              className="float-left"
              variant="primary"
              disabled={!startDate || !endDate || loading}
              onClick={handleExport}
            >
              {loading && (
                <Spinner
                  variant="light"
                  animation="border"
                  size="sm"
                ></Spinner>
              )}
              Export
            </Button>
          </Form.Group>
        </Form>
      </div>
    </Layout>
  );
};
