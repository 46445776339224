import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  setVervalApproveList,
  startLoadingVervalApprove,
  stopLoadingVervalApprove,
  setVervalApproveMessage,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import Layout from "template";
import { Constant } from "template/Constant";
import { KpmsForm } from "../../kpms/form";
import { VervalApproveTable } from "./table";
import { KpmsUploadImages } from "../../kpms/upload/images";
import { getApproveVerval } from "services/vervalService";

type Props = {
  section?: any;
};

export const VervalApprove: React.FC<Props> = ({ section }) => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.vervalApprove);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");
  const [editAction, setEditAction] = useState("");
  const [isFiltered, setIsFiltered] = useState(undefined);
  const getData = (
    page,
    pageSize,
    searchKey,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id
  ) => {
    dispatch(startLoadingVervalApprove());
    getApproveVerval(
      page,
      pageSize,
      searchKey,
      periode_id,
      date,
      province_id,
      city_id,
      district_id,
      subdistrict_id,
      section
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setVervalApproveList({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: searchKey,
            lastPage: response.lastPage,
          })
        );
        dispatch(stopLoadingVervalApprove());
      } else {
        dispatch(setVervalApproveMessage("Data kosong"));
        dispatch(stopLoadingVervalApprove());
      }
    });
  };

  const handleRowEdit = (rowData, editAction) => {
    setRow(rowData);
    dispatch(setVervalApproveMessage(""));
    setAction("edit");
    setEditAction(editAction);
  };

  const handleRowDetail = (
    rowData,
    isSelectedProvince,
    isSelectedCity,
    isSelectedDistrict,
    isSelectedSubDistrict
  ) => {
    setRow(rowData);
    dispatch(setVervalApproveMessage(""));
    setAction("detail");
    setIsFiltered({
      isProvinceSelected: isSelectedProvince,
      isCitySelected: isSelectedCity,
      isDistrictSelected: isSelectedDistrict,
      isSubDistrictSelected: isSelectedSubDistrict,
    });
  };
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">
            {section === "images" ? "Dashboard Foto" : "VERVAL"}
          </h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message ? (
            <Alert
              variant={Constant.defaultAlertVarient}
              className="alert-dismissible fade"
            >
              {rData.message}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => dispatch(setVervalApproveMessage(""))}
              >
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {section === "add" || section === "detail" || action ? (
            <KpmsForm
              hideShowForm={setAction}
              action={action || section}
              row={row}
              getData={getData}
              isFiltered={isFiltered}
              editAction={editAction}
            />
          ) : section === "upload-images" ? (
            <KpmsUploadImages />
          ) : (
            <VervalApproveTable
              section={section}
              handleRowEdit={handleRowEdit}
              handleRowDetail={handleRowDetail}
              hideShowForm={setAction}
              getData={getData}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
