import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setWarehousesMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addWarehouses, updateWarehouses } from "services/warehousesService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { useNavigate } from "react-router";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const WarehousesForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue = { id: 0, code: '', name: '', subdistrict: '', district: '', city: '', state: '', postal_code: '', district_code: '', address1: '', address2: '', latitude: 0, longitude: 0, telepon: '', deleted_at: '', created_at: '', updated_at: '' };
    const initialValue = action === 'edit' ? row : iValue;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            setLoading(true)
            if (action === 'edit') {
                const response = await updateWarehouses(values);
                if (response && response.data && response.data.code === 1) {
                    setLoading(false)
                    dispatch(setWarehousesMessage("Berhasil mengubah data"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                    // navigate('/warehouses')
                } else {
                    dispatch(setWarehousesMessage("Terjadi kesalahan"));
                    setLoading(false)
                }
            } else if (action === 'add') {
                const response = await addWarehouses(values);
                if (response && response.data && response.data.code === 1) {
                    setLoading(false)
                    dispatch(setWarehousesMessage("Berhasil menambah data"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    navigate('/warehouses')
                    // hideShowForm('');
                } else {
                    dispatch(setWarehousesMessage("Terjadi kesalahan"));
                    setLoading(false)
                }
            }
        },
        validationSchema: yup.object({
            code: yup.string().required(),
            name: yup.string().nullable(),
            subdistrict: yup.string().nullable(),
            district: yup.string().nullable(),
            city: yup.string().nullable(),
            state: yup.string().nullable(),
            postal_code: yup.string().nullable(),
            district_code: yup.string().nullable(),
            address1: yup.string().nullable(),
            address2: yup.string().nullable(),
            latitude: yup.number().nullable(),
            longitude: yup.number().nullable(),
            telepon: yup.string().nullable(),
            // deleted_at: yup.date().nullable(),
            // created_at: yup.date().nullable(),
            // updated_at: yup.date().nullable(),

        }),
    });
    const resetForm = () => {
        formik.resetForm()
    }

    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">

                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action === 'add' ? 'Tambah' : 'Ubah'} Gudang
                    {/* <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Warehouses</span>
                    </Button> */}
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit} className="w-100 d-flex flex-wrap">
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Kode</label>
                        <Form.Control type="text" name="code" className="form-control" value={formik.values.code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.code && !!formik.errors.code}
                            isValid={!!formik.touched.code && !formik.errors.code}
                        ></Form.Control>
                        {
                            formik.errors.code && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.code}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Nama</label>
                        <Form.Control type="text" name="name" className="form-control" value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.name && !!formik.errors.name}
                            isValid={!!formik.touched.name && !formik.errors.name}
                        ></Form.Control>
                        {
                            formik.errors.name && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.name}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Kelurahan</label>
                        <Form.Control type="text" name="subdistrict" className="form-control" value={formik.values.subdistrict}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.subdistrict && !!formik.errors.subdistrict}
                            isValid={!!formik.touched.subdistrict && !formik.errors.subdistrict}
                        ></Form.Control>
                        {
                            formik.errors.subdistrict && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.subdistrict}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Kecamatan</label>
                        <Form.Control type="text" name="district" className="form-control" value={formik.values.district}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.district && !!formik.errors.district}
                            isValid={!!formik.touched.district && !formik.errors.district}
                        ></Form.Control>
                        {
                            formik.errors.district && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.district}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Kota</label>
                        <Form.Control type="text" name="city" className="form-control" value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.city && !!formik.errors.city}
                            isValid={!!formik.touched.city && !formik.errors.city}
                        ></Form.Control>
                        {
                            formik.errors.city && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.city}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Negara</label>
                        <Form.Control type="text" name="state" className="form-control" value={formik.values.state}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.state && !!formik.errors.state}
                            isValid={!!formik.touched.state && !formik.errors.state}
                        ></Form.Control>
                        {
                            formik.errors.state && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.state}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Kode POS</label>
                        <Form.Control type="text" name="postal_code" className="form-control" value={formik.values.postal_code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.postal_code && !!formik.errors.postal_code}
                            isValid={!!formik.touched.postal_code && !formik.errors.postal_code}
                        ></Form.Control>
                        {
                            formik.errors.postal_code && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.postal_code}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Kode kecamatan</label>
                        <Form.Control type="text" name="district_code" className="form-control" value={formik.values.district_code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.district_code && !!formik.errors.district_code}
                            isValid={!!formik.touched.district_code && !formik.errors.district_code}
                        ></Form.Control>
                        {
                            formik.errors.district_code && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.district_code}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Alamat 1</label>
                        <Form.Control type="text" name="address1" className="form-control" value={formik.values.address1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.address1 && !!formik.errors.address1}
                            isValid={!!formik.touched.address1 && !formik.errors.address1}
                        ></Form.Control>
                        {
                            formik.errors.address1 && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.address1}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Alamat 2</label>
                        <Form.Control type="text" name="address2" className="form-control" value={formik.values.address2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.address2 && !!formik.errors.address2}
                            isValid={!!formik.touched.address2 && !formik.errors.address2}
                        ></Form.Control>
                        {
                            formik.errors.address2 && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.address2}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Latitude</label>
                        <Form.Control type="text" name="latitude" className="form-control" value={formik.values.latitude}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.latitude && !!formik.errors.latitude}
                            isValid={!!formik.touched.latitude && !formik.errors.latitude}
                        ></Form.Control>
                        {
                            formik.errors.latitude && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.latitude}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Longitude</label>
                        <Form.Control type="text" name="longitude" className="form-control" value={formik.values.longitude}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.longitude && !!formik.errors.longitude}
                            isValid={!!formik.touched.longitude && !formik.errors.longitude}
                        ></Form.Control>
                        {
                            formik.errors.longitude && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.longitude}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Telepon</label>
                        <Form.Control type="text" name="telepon" className="form-control" value={formik.values.telepon}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.telepon && !!formik.errors.telepon}
                            isValid={!!formik.touched.telepon && !formik.errors.telepon}
                        ></Form.Control>
                        {
                            formik.errors.telepon && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.telepon}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    {/* <Form.Group>
                        <label className="form -control-label">deleted_at</label>
                        <Form.Control type="text" name="deleted_at" className="form-control" value={formik.values.deleted_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.deleted_at && !!formik.errors.deleted_at}
                            isValid={!!formik.touched.deleted_at && !formik.errors.deleted_at}
                        ></Form.Control>
                        {
                            formik.errors.deleted_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.deleted_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">created_at</label>
                        <Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.created_at && !!formik.errors.created_at}
                            isValid={!!formik.touched.created_at && !formik.errors.created_at}
                        ></Form.Control>
                        {
                            formik.errors.created_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.created_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">updated_at</label>
                        <Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.updated_at && !!formik.errors.updated_at}
                            isValid={!!formik.touched.updated_at && !formik.errors.updated_at}
                        ></Form.Control>
                        {
                            formik.errors.updated_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.updated_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}

                    <Form.Group className="w-100 px-2">
                        <Button type="submit" className="float-left mr-3" variant="primary" disabled={loading}>{loading && <Spinner variant="light" animation="border" size="sm" className="mr-1"></Spinner>}Simpan</Button>
                        <Button className="float-left" variant="secondary" onClick={() => resetForm()}>Reset</Button>

                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

