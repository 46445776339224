import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IVervalApprove {
  id: number;
  periode_id: number;
  type: string;
  status: string;
  change_type: string;
  request_data: Data;
  affected_id: number;
  affected_data: AffectedData;
  new_id: number;
  new_data: Data;
  created_at: Date;
  created_by: string;
  updated_at: Date;
  periods: Periods;
}
export interface AffectedData {
  id: number;
  rt: string;
  rw: string;
  status: string;
  address: string;
  bnba_id: null;
  city_id: number;
  kpm_age: string;
  city_lat: null;
  city_lng: null;
  kpm_name: string;
  finish_at: null;
  finish_by: string;
  approve_at: Date;
  approve_by: string;
  created_at: Date;
  created_by: string;
  periode_id: number;
  process_at: null;
  process_by: string;
  reg_number: string;
  updated_at: Date;
  updated_by: string;
  district_id: number;
  kpm_barcode: string;
  province_id: number;
  shipment_id: null;
  batch_number: number;
  district_lat: null;
  district_lng: null;
  province_lat: null;
  province_lng: null;
  kpm_reg_number: string;
  subdistrict_id: number;
  subdistrict_lat: null;
  subdistrict_lng: null;
  approve_cancel_at: null;
  approve_cancel_by: null;
  provinces: Provinces;
  cities: Cities;
  districts: Districts;
  subdistricts: Subdistricts;
}

export interface Cities {
  city_id: number;
  city_name: string;
}

export interface Districts {
  district_id: number;
  district_name: string;
}

export interface Provinces {
  province_id: number;
  province_name: string;
}

export interface Subdistricts {
  subdistrict_id: number;
  subdistrict_name: string;
}

export interface Data {
  id?: number;
  rt: string;
  rw: string;
  status: string;
  address: string;
  bnba_id: number | null;
  city_id: number;
  kpm_age: string;
  kpm_name: string;
  created_by: number;
  periode_id?: number;
  reg_number: string;
  district_id: number;
  kpm_barcode: string;
  province_id: number;
  shipment_id: number | null;
  kpm_reg_number: string;
  subdistrict_id: number;
  provinces: Provinces;
  cities: Cities;
  districts: Districts;
  subdistricts: Subdistricts;
}

export interface Periods {
  id: number;
  month: string;
  year: string;
  periode_name: string;
}

interface IVervalApproveData {
  list?: Array<IVervalApprove>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: string;
  loading?: boolean;
  lastPage?: number;
}

const initialState: IVervalApproveData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: "",
  loading: false,
  lastPage: 0,
};

const vervalApproveSlice = createSlice({
  name: "vervalApprove",
  initialState,
  reducers: {
    setVervalApproveList: (state, _action: PayloadAction<IVervalApproveData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
      state.lastPage = _action.payload.lastPage;
    },
    resetVervalApproveToInit: (state) => {
      state = initialState;
    },
    setVervalApproveMessage: (state, _action: PayloadAction<string>) => {
      state.message = _action.payload;
    },
    startLoadingVervalApprove: (state) => {
      state.loading = true;
    },
    stopLoadingVervalApprove: (state) => {
      state.loading = false;
    },
  },
});

export const {
  setVervalApproveList,
  resetVervalApproveToInit,
  setVervalApproveMessage,
  startLoadingVervalApprove,
  stopLoadingVervalApprove,
} = vervalApproveSlice.actions;

export default vervalApproveSlice.reducer;
