import { APIService } from "services";

export const getApproveVerval = async (
  pageNo,
  pageSize,
  search,
  periode_id,
  date,
  province_id,
  city_id,
  district_id,
  subdistrict_id,
  section
) => {
  let res;
  res = await getAllApproveVerval(
    pageNo,
    pageSize,
    search,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id
  );

  if (res && res.data && res.data.data) {
    return {
      records: res.data.data.data,
      total_count: res.data.data.total,
      lastPage: res.data.data.last_page,
    };
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const getAllApproveVerval = (
  page,
  paginator,
  search,
  periode_id,
  date,
  province_id,
  city_id,
  district_id,
  subdistrict_id
) => {
  return APIService.api().get(
    `/list-verval?search=${search}&page=${page}&paginator=${paginator}&periode_id=${periode_id}&date=${date}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`
  );
};

export const postApprove = (data) => {
  return APIService.api().post(`/action-verval`, data);
};
export const postExport = (params) => {
  const { date_start, date_end, periode_id } = params;
  return APIService.api().get(
    `/export-verval?date_start=${date_start}&date_end=${date_end}&periode_id=${periode_id}`,
    { responseType: "arraybuffer" }
  );
};
