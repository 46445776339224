import Layout from "template";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  resetProvincesToInit,
  startLoadingProvinces,
  setProvincesList,
  stopLoadingProvinces,
  setProvincesMessage,
  resetCitiesToInit,
  startLoadingCities,
  setCitiesList,
  stopLoadingCities,
  setCitiesMessage,
  resetPeriodsToInit,
  startLoadingPeriods,
  setPeriodsList,
  stopLoadingPeriods,
  setPeriodsMessage,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { getCities } from "services/citiesService";
import { getProvinces } from "services/provincesService";
import { Constant } from "template/Constant";
import { useAppDispatch } from "redux/store";
import { ImportFileBNBA, insertBNBA } from "services/fileUploadService";
import { useFormik } from "formik";
import * as yup from "yup";
import { getPeriods } from "services/periodsService";
export const ImportPBP = () => {
  const provincesData = useSelector((state: RootState) => state.provinces);
  const citiesData = useSelector((state: RootState) => state.cities);
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const periodsLoading = useSelector(
    (state: RootState) => state.periods.loading
  );
  const periodsData = useSelector((state: RootState) => state.periods);
  const citiesLoading = useSelector((state: RootState) => state.cities.loading);
  const roles = localStorage.getItem("user");
  const [loading, setLoading] = useState(undefined);
  const [message, setMessage] = useState("");
  const dispatch = useAppDispatch();
  const [validasiModal, setValidasiModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [inputModal, setInputModal] = useState(false);
  const [validasiData, setValidasiData] = useState(undefined);
  const [step, setStep] = useState(1);
  const formikFileValidasiData = useFormik({
    initialValues: { fileData: [], periode_tuju: "" },
    onSubmit: async (values) => {
      setLoading(true);
      var formData = new FormData();
      formData.append("file", values.fileData[0]);
      formData.append("periode_tuju", values.periode_tuju);
      ImportFileBNBA(formData)
        .then((res: any) => {
          if (res) {
            setMessage("Dokumen berhasil di validasi");
            setTimeout(() => {
              setMessage("");
            }, 3000);
            formikFileValidasiData.resetForm();
            setLoading(false);
            setIsSuccess(true);
            setStep(2);
            setValidasiModal(false);
            setInputModal(true);
            setValidasiData(res.data.message);
          }
        })
        .catch((error) => {
          setMessage(error.data.error || error.data.message);
          setTimeout(() => {
            setMessage("");
          }, 3000);
          formikFileValidasiData.resetForm();
          setLoading(false);
          setIsSuccess(false);
        });
    },
    validationSchema: yup.object({
      fileData: yup.array().min(1, "Pilih minimal 1 file"),
      periode_tuju: yup.number().required(),
    }),
  });

  const formikFileInputData = useFormik({
    initialValues: {
      periode_tuju:
        step === 2 ? validasiData?.areas?.periode?.id?.toString() : "",
      periode_awal: "",
      is_new: "",
      province_id:
        step === 2 ? validasiData?.areas?.prov?.province_id?.toString() : "",
      city_id: step === 2 ? validasiData?.areas?.prov?.city_id?.toString() : "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      var formData = new FormData();
      formData.append(
        "periode_tuju",
        step === 2
          ? validasiData?.areas?.periode?.id?.toString()
          : values.periode_tuju
      );
      formData.append("periode_awal", values.periode_awal);
      formData.append("is_new", step === 2 ? "true" : "false");
      formData.append(
        "province_id",
        step === 2
          ? validasiData?.areas?.prov?.province_id?.toString()
          : values.province_id
      );
      formData.append(
        "city_id",
        step === 2
          ? validasiData?.areas?.city?.city_id?.toString()
          : values.city_id
      );

      insertBNBA(formData)
        .then((res: any) => {
          if (res) {
            setMessage("Dokumen berhasil di input");
            setTimeout(() => {
              setMessage("");
            }, 3000);

            formikFileInputData.resetForm();
            setLoading(false);
            setIsSuccess(true);
            setStep(1);
          }
        })
        .catch((error) => {
          setMessage(error.data.error || error.data.message);
          setTimeout(() => {
            setMessage("");
          }, 3000);
          formikFileInputData.resetForm();
          setLoading(false);
          setIsSuccess(false);
        });
    },
    validationSchema: yup.object({
      periode_tuju: yup.number().nullable(),
      periode_awal: yup.number().nullable(),
      is_new: yup.number().nullable(),
      province_id: yup.number().nullable(),
      city_id: yup.number().nullable(),
    }),
  });
  const selectedProvince = formikFileInputData.values.province_id;
  useEffect(() => {
    if (
      provincesData &&
      provincesData.list &&
      provincesData.list.length === 0 &&
      roles === "Super Admin"
    ) {
      dispatch(resetProvincesToInit());
      dispatch(startLoadingProvinces());
      getProvinces(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setProvincesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingProvinces());
        } else {
          dispatch(setProvincesMessage("Data kosong"));
          dispatch(stopLoadingProvinces());
        }
      });
    }
  }, []);
  useEffect(() => {
    if (selectedProvince) {
      dispatch(resetCitiesToInit());
      dispatch(startLoadingCities());
      getCities(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedProvince
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setCitiesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingCities());
        } else {
          dispatch(setCitiesMessage("Data kosong"));
          dispatch(stopLoadingCities());
        }
      });
    }
  }, [selectedProvince]);

  //   useEffect(() => {
  //     if (roles === "Super Admin") {
  //       if (selectedCity) {
  //         dispatch(resetDistrictsToInit());
  //         dispatch(startLoadingDistricts());
  //         getDistricts(
  //           Constant.defaultPageNumber,
  //           Constant.defaultDropdownPageSize,
  //           "",
  //           selectedCity
  //         ).then((response) => {
  //           if (response && response.records) {
  //             dispatch(
  //               setDistrictsList({
  //                 pageNo: Constant.defaultPageNumber,
  //                 pageSize: Constant.defaultDropdownPageSize,
  //                 list: response.records,
  //                 totalCount: response.total_count,
  //                 searchKey: "",
  //               })
  //             );
  //             dispatch(stopLoadingDistricts());
  //           } else {
  //             dispatch(setDistrictsMessage("Data kosong"));
  //             dispatch(stopLoadingDistricts());
  //           }
  //         });
  //       }
  //     } else {
  //       dispatch(resetDistrictsToInit());
  //       dispatch(startLoadingDistricts());
  //       getDistricts(
  //         Constant.defaultPageNumber,
  //         Constant.defaultDropdownPageSize,
  //         "",
  //         cityId
  //       ).then((response) => {
  //         if (response && response.records) {
  //           dispatch(
  //             setDistrictsList({
  //               pageNo: Constant.defaultPageNumber,
  //               pageSize: Constant.defaultDropdownPageSize,
  //               list: response.records,
  //               totalCount: response.total_count,
  //               searchKey: "",
  //             })
  //           );
  //           dispatch(stopLoadingDistricts());
  //         } else {
  //           dispatch(setDistrictsMessage("Data kosong"));
  //           dispatch(stopLoadingDistricts());
  //         }
  //       });
  //     }
  //   }, [selectedCity]);

  //   useEffect(() => {
  //     if (selectedDistrict) {
  //       dispatch(resetSubdistrictsToInit());
  //       dispatch(startLoadingSubDistricts());
  //       getSubdistricts(
  //         Constant.defaultPageNumber,
  //         Constant.defaultDropdownPageSize,
  //         "",
  //         selectedDistrict
  //       ).then((response) => {
  //         if (response && response.records) {
  //           dispatch(
  //             setSubdistrictsList({
  //               pageNo: Constant.defaultPageNumber,
  //               pageSize: Constant.defaultDropdownPageSize,
  //               list: response.records,
  //               totalCount: response.total_count,
  //               searchKey: "",
  //             })
  //           );
  //           dispatch(stopLoadingSubDistricts());
  //         } else {
  //           dispatch(setSubdistrictsMessage("Data kosong"));
  //           dispatch(stopLoadingSubDistricts());
  //         }
  //       });
  //     }
  //   }, [selectedDistrict]);

  const handleCloseInputModal = () => {
    setInputModal(false);
    setStep(1);
  };

  useEffect(() => {
    if (periodsData && periodsData.list && periodsData.list.length === 0) {
      dispatch(resetPeriodsToInit());
      dispatch(startLoadingPeriods());
      getPeriods(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setPeriodsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingPeriods());
        } else {
          dispatch(setPeriodsMessage("Data kosong"));
          dispatch(stopLoadingPeriods());
        }
      });
    }
  }, []);

  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Import PBP</h1>
        </div>
        <Button
          variant="dark"
          className="rounded-0 rounded-right mr-2 mt-3"
          id="button-search"
          onClick={() => setValidasiModal(true)}
        >
          Validasi Data
        </Button>
        <Button
          variant="dark"
          className="rounded-0 rounded-right mr-2 mt-3"
          id="button-search"
          onClick={() => setInputModal(true)}
        >
          Input Data
        </Button>
        <Modal
          show={validasiModal}
          onHide={() => setValidasiModal(false)}
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title>Validasi Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {message ? (
              <Col md={12}>
                <Alert variant={isSuccess === false ? "danger" : "info"}>
                  {message}
                </Alert>
              </Col>
            ) : null}
            <Form onSubmit={formikFileValidasiData.handleSubmit}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Group className="w-100 px-2">
                  <label className="form -control-label">Periode Tuju</label>
                  <Form.Control
                    as="select"
                    name="periode_tuju"
                    className="form-control"
                    value={formikFileValidasiData.values.periode_tuju}
                    onChange={formikFileValidasiData.handleChange}
                    onBlur={formikFileValidasiData.handleBlur}
                    isInvalid={
                      !!formikFileValidasiData.touched.periode_tuju &&
                      !!formikFileValidasiData.errors.periode_tuju
                    }
                    isValid={
                      !!formikFileValidasiData.touched.periode_tuju &&
                      !formikFileValidasiData.errors.periode_tuju
                    }
                    disabled={periodsLoading}
                  >
                    <option value={0}>
                      {periodsLoading ? "Loading..." : "Pilih Periode"}
                    </option>
                    {periodsData.list.map((item, i) => {
                      return (
                        <option value={item.id} key={`periods-${i}`}>
                          {item.month} - {item.year}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {formikFileValidasiData.errors.periode_tuju && (
                    <Form.Control.Feedback type="invalid">
                      {formikFileValidasiData.errors.periode_tuju}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Control
                  type="file"
                  onChange={(event: React.ChangeEvent) => {
                    const dFile = event.target as HTMLInputElement;
                    if (dFile.files && dFile.files[0]) {
                      formikFileValidasiData.setFieldValue(
                        "fileData",
                        Array.from(dFile.files)
                      );
                    }
                  }}
                  onBlur={formikFileValidasiData.handleBlur}
                  isInvalid={
                    !!formikFileValidasiData.touched.fileData &&
                    !!formikFileValidasiData.errors.fileData
                  }
                  isValid={
                    !!formikFileValidasiData.touched.fileData &&
                    !formikFileValidasiData.errors.fileData
                  }
                />
                {formikFileValidasiData.errors.fileData && (
                  <Form.Control.Feedback type="invalid">
                    {formikFileValidasiData.errors.fileData}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group>
                <Button
                  type="submit"
                  className="float-right"
                  variant="primary"
                  disabled={
                    loading ||
                    formikFileValidasiData.values.fileData.length === 0
                  }
                >
                  {loading && (
                    <Spinner
                      className="mr-2"
                      animation="border"
                      role="status"
                      size="sm"
                    ></Spinner>
                  )}
                  Validasi
                </Button>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={inputModal}
          onHide={handleCloseInputModal}
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title>Input Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {message ? (
              <Col md={12}>
                <Alert variant={isSuccess === false ? "danger" : "info"}>
                  {message}
                </Alert>
              </Col>
            ) : null}
            <Form onSubmit={formikFileInputData.handleSubmit}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Group className="w-100 px-2">
                  <label className="form -control-label">Periode Tuju</label>
                  <Form.Control
                    as="select"
                    name="periode_tuju"
                    className="form-control"
                    value={formikFileInputData.values.periode_tuju}
                    onChange={formikFileInputData.handleChange}
                    onBlur={formikFileInputData.handleBlur}
                    isInvalid={
                      !!formikFileInputData.touched.periode_tuju &&
                      !!formikFileInputData.errors.periode_tuju
                    }
                    isValid={
                      !!formikFileInputData.touched.periode_tuju &&
                      !formikFileInputData.errors.periode_tuju
                    }
                    disabled={periodsLoading || step === 2}
                  >
                    <option value={0}>
                      {periodsLoading
                        ? "Loading..."
                        : step === 2
                        ? validasiData?.areas?.periode?.id
                        : "Pilih Periode"}
                    </option>
                    {periodsData.list.map((item, i) => {
                      return (
                        <option value={item.id} key={`periods-${i}`}>
                          {item.month} - {item.year}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {formikFileInputData.errors.periode_tuju && (
                    <Form.Control.Feedback type="invalid">
                      {formikFileInputData.errors.periode_tuju}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="w-100 px-2">
                  <label className="form -control-label">Periode Awal</label>
                  <Form.Control
                    as="select"
                    name="periode_awal"
                    className="form-control"
                    value={formikFileInputData.values.periode_awal}
                    onChange={formikFileInputData.handleChange}
                    onBlur={formikFileInputData.handleBlur}
                    isInvalid={
                      !!formikFileInputData.touched.periode_awal &&
                      !!formikFileInputData.errors.periode_awal
                    }
                    isValid={
                      !!formikFileInputData.touched.periode_awal &&
                      !formikFileInputData.errors.periode_awal
                    }
                    disabled={periodsLoading || step === 2}
                  >
                    <option value={0}>
                      {periodsLoading ? "Loading..." : "Pilih Periode"}
                    </option>
                    {periodsData.list.map((item, i) => {
                      return (
                        <option value={item.id} key={`periods-${i}`}>
                          {item.month} - {item.year}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {formikFileInputData.errors.periode_awal && (
                    <Form.Control.Feedback type="invalid">
                      {formikFileInputData.errors.periode_awal}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="w-100 px-2">
                  <label className="form -control-label">ID Provinsi</label>
                  <Form.Control
                    as="select"
                    name="province_id"
                    className="form-control"
                    value={formikFileInputData.values.province_id}
                    onChange={formikFileInputData.handleChange}
                    onBlur={formikFileInputData.handleBlur}
                    isInvalid={
                      !!formikFileInputData.touched.province_id &&
                      !!formikFileInputData.errors.province_id
                    }
                    isValid={
                      !!formikFileInputData.touched.province_id &&
                      !formikFileInputData.errors.province_id
                    }
                    disabled={provincesLoading || step === 2}
                  >
                    <option value={0}>
                      {provincesLoading
                        ? "Loading..."
                        : step === 2
                        ? validasiData?.areas?.prov?.province_name
                        : "Pilih Provinsi"}
                    </option>
                    {provincesData.list.map((item, i) => {
                      return (
                        <option value={item.province_id} key={`provinces-${i}`}>
                          {item.province_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {formikFileInputData.errors.province_id && (
                    <Form.Control.Feedback type="invalid">
                      {formikFileInputData.errors.province_id}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="w-100 px-2">
                  <label className="form -control-label">ID Kota</label>
                  <Form.Control
                    as="select"
                    name="city_id"
                    className="form-control"
                    value={formikFileInputData.values.city_id}
                    onChange={formikFileInputData.handleChange}
                    onBlur={formikFileInputData.handleBlur}
                    isInvalid={
                      !!formikFileInputData.touched.city_id &&
                      !!formikFileInputData.errors.city_id
                    }
                    isValid={
                      !!formikFileInputData.touched.city_id &&
                      !formikFileInputData.errors.city_id
                    }
                    disabled={citiesLoading || step === 2}
                  >
                    <option value={0}>
                      {citiesLoading
                        ? "Loading..."
                        : step === 2
                        ? validasiData?.areas?.city?.city_name
                        : "Pilih Kota"}
                    </option>
                    {citiesData.list.map((item, i) => {
                      return (
                        <option value={item.city_id} key={`cities-${i}`}>
                          {item.city_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {formikFileInputData.errors.city_id && (
                    <Form.Control.Feedback type="invalid">
                      {formikFileInputData.errors.city_id}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Group>
              <Form.Group>
                <Button
                  type="submit"
                  className="float-right"
                  variant="primary"
                  disabled={loading}
                >
                  {loading && (
                    <Spinner
                      className="mr-2"
                      animation="border"
                      role="status"
                      size="sm"
                    ></Spinner>
                  )}
                  Simpan
                </Button>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};
