import { APIService } from "services";

export const getUsers = async (pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    let res;
    if (search.length === 0) {
        res = await getAllUsers(pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id);
    }
    else {
        try {
            res = await searchUsers(pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id);
        } catch (err) {
            return { records: [], total_count: 0 }
        }
    }
    if (res && res.data && res.data.data) {
        return { records: res.data.data.data, total_count: res.data.data.total, lastPage: res.data.data.last_page }
    } else {
        return { records: [], totalCount: 0 }
    }

}


export const addUsers = (data) => {
    return APIService.api().post(`/users`, data)
}
export const updateUsers = (data) => {
    return APIService.api().put(`/users/${data.id}`, data)
}
export const getAllUsers = (page, paginator, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    return APIService.api().get(`/users?page=${page}&paginator=${paginator}&periode_id=${periode_id}&date=${date}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`)
}
export const getOneUsers = (id) => {
    return APIService.api().get(`/users/${id}`)
}
export const searchUsers = (page, paginator, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    return APIService.api().get(`/users/search/${search}/?page=${page}&paginator=${paginator}`)
}
export const deleteUsers = (id) => {
    return APIService.api().delete(`/users/${id}`)
}
