import React from "react";
interface Props {
  data: any;
}
export const PrintImages = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { data } = props;
    return (
      <>
        {data && (
          <div className="" ref={ref} style={{ padding: 50 }}>
            <div
              className=""
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <img src="../../logos/bulog-logo.png" alt="" width={350} />
              <img src="../../logos/yat-logo.jpeg" alt="" width={250} />
            </div>
            <hr />
            <div className="mt-5" style={{ textAlign: "center" }}>
              <div className="">
                <h3>
                  <strong>LAMPIRAN</strong>
                </h3>
              </div>
              <div className="">
                <h3>FOTO VERVAL</h3>
              </div>
              <div className="">
                <h5>
                  {data[0]?.provinces?.province_name.toUpperCase() || "-"} -{" "}
                  {data[0]?.cities?.city_name.toUpperCase() || "-"} -{" "}
                  {data[0]?.districts?.district_name.toUpperCase() || "-"} -{" "}
                  {data[0]?.subdistricts?.subdistrict_name.toUpperCase() || "-"}
                </h5>
              </div>
            </div>
            <table className="table table-bordered mt-5">
              <thead>
                <tr>
                  <th scope="col">Foto Barang Sampai</th>
                  <th scope="col">Foto Diterima</th>
                  <th scope="col">Foto Diwakilkan</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((value) => (
                  <tr>
                    <td>
                      {value?.attachments !== null &&
                      value?.attachments.attachment_arrived ? (
                        <img
                          src={value?.attachments.attachment_arrived}
                          alt=""
                          width={120}
                          height={120}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {value?.attachments !== null &&
                      value?.attachments.attachment_received ? (
                        <img
                          src={value?.attachments.attachment_received}
                          alt=""
                          width={120}
                          height={120}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {value?.attachments && value?.attachments_represent ? (
                        <img
                          src={value?.attachments.attachments_represent}
                          alt=""
                          width={120}
                          height={120}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }
);
