import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setWarehousesList, setWarehousesMessage, startLoadingWarehouses, stopLoadingWarehouses } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getWarehouses } from "services/warehousesService";
import Layout from "template";
import { Constant } from "template/Constant";
import { WarehousesForm } from "./form";
import { WarehousesTable } from "./table";

type Props ={
  section? : any;
}

export const Warehouses: React.FC<Props> = ({section}) => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.warehouses);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const getData = (page, pageSize, searchKey) => {
    dispatch(startLoadingWarehouses())
    getWarehouses(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(setWarehousesList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        dispatch(stopLoadingWarehouses())
      } else {
        dispatch(setWarehousesMessage("Data kosong"));
        dispatch(stopLoadingWarehouses())
      }
    })
  }

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setWarehousesMessage(''));
    setAction('edit');
  }
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Gudang</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setWarehousesMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null}
          {section === 'add' ||  action ? <WarehousesForm hideShowForm={setAction} action={action || section} row={row} getData={getData} /> :
            <WarehousesTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} />}
        </div>

      </div>
    </Layout >
  );
};

