import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import {
    setReportsList,
    setReportsMessage,
    startLoadingReports,
    stopLoadingReports,
} from "redux/actions";
import { Card } from "react-bootstrap";
import { getAllReports } from "services/reportsService";

const periodsId = localStorage.getItem('periode_id')

interface ParamsState {
    type: string;
    id: string;
    period: number;
    code: number;
    doc_id: number;
    periode_id: any;
}


export const ReportsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const initialState: ParamsState = {
        type: "prov",
        id: "",
        period: 0,
        code: 0,
        doc_id: 0,
        periode_id: periodsId
    };

    const [params, setParams] = useState<ParamsState>(initialState);
    const [loading, setLoading] = useState(false);
    const rData = useSelector((state: RootState) => state.reports);
    const reportsLoading = useSelector((state: RootState) => state.reports.loading)

    const getData = () => {
        setLoading(true);
        dispatch(startLoadingReports())
        getAllReports(params).then((response) => {
            if (response) {
                dispatch(
                    setReportsList({
                        list: response.data.data,
                        date: response.data.date,
                    })
                );
                setLoading(false);
                dispatch(stopLoadingReports())
            } else {
                dispatch(setReportsMessage("No Record Found"));
                setLoading(false)
                dispatch(stopLoadingReports())
            }
        });
    };


    useEffect(() => {
        getData()
    }, []);
    const columns = [
        { name: "Kode", selector: (row) => row.province_id, sortable: true },
        { name: "Provinsi", selector: (row) => row.province_name, sortable: true },
        { name: "Jumlah Alokasi", selector: (row) => row.total_alokasi, sortable: true },
        {
            name: "Jumlah Realisasi",
            selector: (row) => row.realisasi,
            sortable: true,
        },

        { name: "Sisa", selector: (row) => row.sisa, sortable: true },
        {
            name: "Pencapaian (%)",
            selector: (row) => row.percentage_selesai,
            sortable: true,
        },
    ];

    return (
        <>
            <Card className="shadow mb-4">
                <Card.Header className="py-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                        Daftar Laporan
                    </h6>
                </Card.Header>
                <Card.Body>
                    <div className="table-responsive">

                        <DataTable
                            // selectableRows={true}
                            // onSelectedRowsChange={handleRowSelection}
                            // paginationPerPage={Constant.defaultPageNumber}
                            // paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                            columns={columns}
                            data={rData.list}
                            progressPending={loading}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // paginationTotalRows={rData.totalCount}
                            className="table table-bordered"
                            // pagination
                            // paginationServer
                            // onChangePage={handlePageChange}
                        />
                    </div>
                </Card.Body>
            </Card></>
    );
};
