import React from "react";
import { QRCodeCanvas } from 'qrcode.react';

interface Props {
    data: any;
}
export const ComponentInvitationPrint = React.forwardRef<HTMLInputElement, Props>(
    (props, ref) => {
        const { data } = props
        return (
            <>
                <div className="" ref={ref} style={{ paddingLeft: 50, paddingRight: 50, color: 'black' }}>
                    {data.map((inv,index) => (
                        <div className="">
                            <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src="../logos/logo-bulog.jpeg"
                                    alt=""
                                    width={155}
                                    height={55}
                                />
                                <img src="../logos/logo-bapanas.png" alt="" style={{ marginLeft: 200 }} width={110} height={55} />
                                <img src="../logos/yat-logo.jpeg" alt="" style={{ marginLeft: 200 }} width={110} height={55} />
                            </div>
                            <div className="" style={{ display: 'flex', marginTop: 10 }}>
                                <div className="">
                                    <h6 style={{ fontWeight: 'bold' }}>PEMBERITAHUAN</h6>
                                    <h6 style={{fontSize:13}}>Nomor: {inv.reg_number}</h6>
                                </div>
                                <div className="" style={{ marginLeft: 100 }}>
                                    <h6 style={{ fontWeight: 'bold' }}>KEPADA:</h6>
                                    <h6>{inv.kpm_name}</h6>
                                    <h6 style={{fontSize:13}}>{inv.address} RT {inv.rt} RW {inv.rw}</h6>
                                </div>
                            </div>
                            <div className="" style={{ marginBottom: 20,fontSize:'15px' }}>
                                <div className="mb-2">Dengan Hormat,</div>
                                <div>Berdasarkan Keputusan Pemerintah Republik Indonesia c.q. Badan Pangan Nasional Republik Indonesia, Bapak/Ibu/Sdr/i dinyatakan
                                    berhak memperoleh Bantuan Pangan Tahun {inv.periods?.year} dari Pemerintah RI. Harap menjadi perhatian Bapak/Ibu penerima Bantuan
                                    Pangan {inv.periods?.month} {inv.periods?.year}:</div>
                                <ol>
                                    <li>Persyaratan pengambilan/penerimaan Bantuan Pangan Tahun {inv.periods?.year} dengan membawa dan menunjukkan Surat Undangan,
                                        KTP-el dan/atau Kartu Keluarga asli.</li>
                                    <li>Dalam penyaluran Bantuan Pangan Tahun {inv.periods?.year} PT. Yasa Artha Trimanunggal tidak memungut biaya apapun. Jika ada
                                        pungutan oleh Petugas silahkan laporkan dengan menghubungi PIC Kantor Pusat PT. Yasa Artha Trimanunggal, dengan
                                        melampirkan bukti terkait.</li>
                                    <li>Pada saat penyerahan akan dilakukan pendataan geotagging dan foto diri PBP (Penerima Bantuan Pangan) Tahun {inv.periods?.year} oleh
                                        Juru Serah.</li>
                                </ol>
                            </div>
                            <div className="">
                                Note: Dilarang memakai/menggunakan pakaian yang beratribut Partai/Pemilihan Umum Lainnya
                            </div>
                            <div className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                                <div className="">
                                    <p style={{ marginBottom: 0 }}>Hormat Kami,</p>
                                    <p>PT. Yasa Artha Trimanunggal</p>
                                </div>
                                <div className="" style={{ display: 'flex', alignItems: 'end' }}>
                                    <h1 style={{ marginRight: 8 }}>{index + 1}</h1>
                                    {/* <img width={80} src={inv.kpm_barcode} alt="" /> */}
                                    <QRCodeCanvas size={80} id={`qr_${index}`} value={inv.reg_number} />
                                </div>
                            </div>
                            <hr /></div>
                    ))}

                </div>
            </>
        )
    }
)