import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetCitiesToInit, resetDistrictsToInit, resetKpmsToInit, resetProvincesToInit, resetShipmentsToInit, resetSubdistrictsToInit, setCitiesList, setCitiesMessage, setDistrictsList, setDistrictsMessage, setProvincesList, setProvincesMessage, setShipmentsMessage, setSubdistrictsList, setSubdistrictsMessage, startLoadingCities, startLoadingDistricts, startLoadingProvinces, startLoadingSubDistricts, stopLoadingCities, stopLoadingDistricts, stopLoadingProvinces, stopLoadingSubDistricts } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Modal, Spinner, Row, Toast } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteShipments, getBastShipmentById } from "services/shipmentsService";
import { useReactToPrint } from "react-to-print";
// import { ComponentBast } from "./print/printBast";
// import { ComponentLampiran } from "./print/printLampiran";
import { getBastById } from "services/bastService";
import { ComponentSj } from "./print/printSj";
import { getCities } from "services/citiesService";
import { getDistricts } from "services/districtsService";
import { getProvinces } from "services/provincesService";
import { getSubdistricts } from "services/subdistrictsService";
import { useLocation, useNavigate } from "react-router";

type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row, isSelectedProvince, isSelectedCity, isSelectedDistrict, isSelectedSubDistrict) => void;
    handleRowView: (row) => void;
    getData: (page, pageSize, searchKey, periode_id, date, province_id, city_id, district_id, subdistrict_id) => void;
};
interface MyObject {
    sj: any // Sesuaikan dengan tipe data yang sesuai
}
export const SjTable: React.FC<Props> = ({
    hideShowForm,
    handleRowEdit,
    handleRowView,
    getData,
}) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const [bastData, setBastData] = useState([]); // Inisialisasi objek
    const [showPrint, setShowPrint] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showLampiran, setShowLampiran] = useState(false);
    const [rowBast, setRowBast] = useState([]);
    const [dataDokSo, setDataDokSo] = useState({
        no_so: "",
        no_doc_out: "",
    });
    const rData = useSelector((state: RootState) => state.shipments);
    const isLoading = useSelector((state: RootState) => state.shipments.loading);
    // const periodsData = useSelector((state: RootState) => state.periods);
    const provincesData = useSelector((state: RootState) => state.provinces);
    const citiesData = useSelector((state: RootState) => state.cities);
    const districtsData = useSelector((state: RootState) => state.districts);
    const subdistrictsData = useSelector((state: RootState) => state.subdistricts);
    // const periodsLoading = useSelector((state: RootState) => state.periods.loading);
    const provincesLoading = useSelector((state: RootState) => state.provinces.loading);
    const citiesLoading = useSelector((state: RootState) => state.cities.loading);
    const districtsLoading = useSelector((state: RootState) => state.districts.loading);
    const subdistrictsLoading = useSelector((state: RootState) => state.subdistricts.loading);
    const periodsId = localStorage.getItem('periode_id')
    const provinceId = localStorage.getItem('province_id')
    const cityId = localStorage.getItem('city_id')
    const districtId = localStorage.getItem('district_id')
    const subdistrictId = localStorage.getItem('subdistrict_id')
    const roles = localStorage.getItem('user')
    const provinceName = localStorage.getItem('province_name')
    const cityName = localStorage.getItem('city_name')
    const [warningGenerate, setShowWarningGenerate] = useState(false)

    const isButtonGenerateDisabled = () => {
        const currentDateTime = new Date();
        const currentHour = currentDateTime.getUTCHours() + 7;
        if (roles !== 'Super Admin') {
            return currentHour >= 6 && currentHour < 12;
        } else {
            return false
        }
    };
    const handleSearch = () => {
        if (search.length > 0) {
            navigate('/sj', {
                replace: true
            })
            if (roles === 'Super Admin') {
                setSelectedDate('')
                setSelectedProvince(0)
                setSelectedCity(0)
                setSelectedDistrict(0)
                setSelectedSubdistrict(0)
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, search ?? '', periodsId, selectedDate, selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict);
            } else {
                setSelectedDate('')
                setSelectedDistrict(0)
                setSelectedSubdistrict(0)
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, search ?? '', periodsId, selectedDate, provinceId, cityId, '', '');
            }
        }
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        if (roles === 'Super Admin') {
            if (selectedProvince) {
                await getData(page, newPerPage, search ?? '', periodsId ?? '', selectedDate ?? '', selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict);
            } else {
                await getData(page, newPerPage, search ?? '', periodsId ?? '', selectedDate ?? '', '', '', '', '');
            }
        } else {
            if (selectedDistrict) {
                await getData(page, newPerPage, search ?? '', periodsId ?? '', selectedDate ?? '', provinceId, cityId, selectedDistrict, selectedSubdistrict);
            } else {
                await getData(page, newPerPage, search ?? '', periodsId ?? '', selectedDate ?? '', provinceId, cityId, '', '');
            }
        }

    };
    const handlePageChange = (page) => {
        if (roles === 'Super Admin') {
            if (selectedProvince) {
                getData(page, rData.pageSize, search ?? '', periodsId ?? '', selectedDate ?? '', selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict);
            } else {
                getData(page, rData.pageSize, search ?? '', periodsId ?? '', selectedDate ?? '', '', '', '', '');
            }
        } else {
            if (selectedDistrict) {
                getData(page, rData.pageSize, search ?? '', periodsId ?? '', selectedDate ?? '', provinceId, cityId, selectedDistrict, selectedSubdistrict);
            } else {
                getData(page, rData.pageSize, search ?? '', periodsId ?? '', selectedDate ?? '', provinceId, cityId, '', '');
            }
        }
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    };
    const componentSjRef = useRef(null);
    const componentLampiranRef = useRef(null);
    const componentUndanganRef = useRef(null);
    const navigate = useNavigate()
    const handlePrint = useReactToPrint({
        content: () => componentSjRef.current,
        pageStyle: `
    @page {
      margin: 6mm 0mm 17mm 0mm;
  }`,
        // documentTitle: `${bastData?.subdistricts?.subdistrict_name} - ${bastData?.districts?.district_name} - ${bastData?.cities?.city_name}`
    });
    const handlePrintLampiran = useReactToPrint({
        content: () => componentLampiranRef.current,
        pageStyle: `
    @page {
      margin: 6mm 0mm 17mm 0mm;
  }`,
    });
    const handlePrintUndangan = useReactToPrint({
        content: () => componentUndanganRef.current,
        pageStyle: `
    @page {
      margin: 6mm 0mm 17mm 0mm;
  }`,
    });
    // console.log(bastData.kpms[0].subdistricts.subdistrict_name)
    const handleChange =
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target;
            setDataDokSo((prevDataDokSo) => ({
                ...prevDataDokSo,
                [name]: value,
            }));
        };
    const handleRowPrint = async (row) => {
        // console.log(row)
        // if (row.verif_documents !== null) {
        //     window.open(row.verif_documents, "_blank", "noopener,noreferrer");
        // } else {
        //     setDataDokSo({
        //         no_doc_out: '',
        //         no_so: ''
        //     })
        //     setShowPrint(true);
        //     setRowBast(row.id);
        // }
        setDataDokSo({
            no_doc_out: '',
            no_so: ''
        })
        setShowPrint(true);
        setRowBast(row.id);
    };

    const handleRowPrintLampiran = async (row) => {
        var formData = new FormData();
        formData.append("no_so", row.no_so ?? null);
        formData.append("no_doc_out", row.doc_out ?? null);
        setShowLampiran(true);
        getBastById(row.id, formData).then((response) =>
            setBastData(response.data.data)
        );
    };

    const handleConfirmPrint = () => {
        var formData = new FormData();
        formData.append("no_so", dataDokSo.no_so);
        formData.append("no_doc_out", dataDokSo.no_doc_out);
        getBastShipmentById(rowBast, formData).then((response) =>
            setBastData(response.data.data)
        );
        setShowConfirmation(true);
    };
    useEffect(() => {
        window.history.replaceState({}, document.title)
        if (roles === 'Super Admin') {
            if (rData && rData.list && rData.list.length === 0 && !search && !selectedDate && !selectedProvince && !selectedCity && !selectedDistrict && !selectedSubdistrict) {
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId, '', '', '', '', '');
            }
        } else {
            if (rData && rData.list && rData.list.length === 0 && !search && !selectedDate && !selectedDistrict && !selectedSubdistrict) {
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId, '', provinceId, cityId, '', '');
            }
        }

        if (provincesData && provincesData.list && provincesData.list.length === 0 && roles === 'Super Admin') {
            dispatch(resetProvincesToInit());
            dispatch(startLoadingProvinces())
            getProvinces(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                    dispatch(setProvincesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingProvinces())
                } else {
                    dispatch(setProvincesMessage("Data kosong"));
                    dispatch(stopLoadingProvinces())
                }
            })
        }
        // if (provincesData && provincesData.list && provincesData.list.length === 0) {
        //     dispatch(resetProvincesToInit());
        //     dispatch(startLoadingProvinces())
        //     getProvinces(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
        //         if (response && response.records) {
        //             dispatch(setProvincesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
        //             dispatch(stopLoadingProvinces())
        //         } else {
        //             dispatch(setProvincesMessage("Data kosong"));
        //             dispatch(stopLoadingProvinces())
        //         }
        //     })
        // }
        // if (periodsData && periodsData.list && periodsData.list.length === 0) {
        //     dispatch(resetPeriodsToInit());
        //     dispatch(startLoadingPeriods())
        //     getPeriods(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
        //         if (response && response.records) {
        //             dispatch(setPeriodsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
        //             dispatch(stopLoadingPeriods())
        //         } else {
        //             dispatch(setPeriodsMessage("Data kosong"));
        //             dispatch(stopLoadingPeriods())
        //         }
        //     })
        // }
    }, [rData.list.length]);
    const handleReset = () => {
        setSearch("");
        setSelectedDate('')
        setSelectedProvince(0)
        setSelectedCity(0)
        setSelectedDistrict(0)
        setSelectedSubdistrict(0)
        dispatch(resetKpmsToInit());
        navigate('/sj', {
            replace: true
        })
        if (roles === 'Super Admin') {
            getData(Constant.defaultPageNumber, rData.pageSize, '', periodsId, '', '', '', '', '');
        } else {
            getData(Constant.defaultPageNumber, rData.pageSize, '', periodsId, '', provinceId, cityId, '', '');
        }
    };
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteShipments(rowData.id);
            if (response) {
                dispatch(resetShipmentsToInit());
                dispatch(setShipmentsMessage("Berhasil menghapus data"));
                if (roles === 'Super Admin') {
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId, '', '', '', '', '');

                } else {
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId, '', provinceId, cityId, '', '');
                }
                setShowDelete(false);
            } else {
                dispatch(setShipmentsMessage("Terjadi kesalahan"));
            }
        }
    };

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    };
    const handleAddButtonClick = () => {
        dispatch(setShipmentsMessage(""));
        hideShowForm("add");
    };

    const handleClose = () => {
        setShowPrint(false)
        // window.location.reload()
    }
    // const formik = useFormik({
    //     initialValues: initialValue,
    //     onSubmit: async (values) => {
    //         console.log(values)
    //     },
    //     validationSchema: yup.object({
    //         no_so: yup.string().required(),
    //         no_doc_out: yup.string().required()
    //     }),
    // });
    const WordingToCreated = () => {
        return (
            <div className="">
                <div className="" style={{ marginBottom: 20 }}>
                    Ketika klik Lanjut, akan membuat status <strong>PBP</strong> menjadi{" "}
                    <strong>PENGIRIMAN</strong>
                </div>
                {/* <Form>
          <Form.Group className="mb-3" controlId="no_so">
            <Form.Label>No. SO*</Form.Label>
            <Form.Control
              type="text"
              name="no_so"
              onChange={handleChange}
              value={dataDokSo.no_so}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="no_so">
            <Form.Label>No. DOK OUT*</Form.Label>
            <Form.Control
              type="text"
              name="no_doc_out"
              onChange={handleChange}
              value={dataDokSo.no_doc_out}
            />
          </Form.Group>
        </Form> */}
                {/* <Form>
                <Form.Group>
                    <label className="form -control-label">No. SO*</label>
                    <Form.Control type="text" name="no_so" className="form-control" value={formik.values.no_so}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.touched.no_so && !!formik.errors.no_so}
                        isValid={!!formik.touched.no_so && !formik.errors.no_so}
                    ></Form.Control>
                    {
                        formik.errors.no_so && (
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.no_so}
                            </Form.Control.Feedback>
                        )}
                </Form.Group>
                <Form.Group>
                    <label className="form -control-label">No. DOK OUT*</label>
                    <Form.Control type="text" name="no_doc_out" className="form-control" value={formik.values.no_doc_out}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.touched.no_doc_out && !!formik.errors.no_doc_out}
                        isValid={!!formik.touched.no_doc_out && !formik.errors.no_doc_out}
                    ></Form.Control>
                    {
                        formik.errors.no_doc_out && (
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.no_doc_out}
                            </Form.Control.Feedback>
                        )}
                </Form.Group>
            </Form> */}
                {/* <div className="" style={{ marginBottom: 20 }}>*wajib diisi</div> */}
            </div>
        );
    };
    const columns = [
        // { name: 'id', selector: row => row.id, sortable: true },
        { name: "Periode", selector: (row) => row.periode_id, sortable: true },
        { name: "Kode SJ", selector: (row) => row.shipment_code, sortable: true },

        // {
        //     name: "Nomor Antrian",
        //     selector: (row) => row.queue_number,
        //     sortable: true,
        // },
        // { name: "ID Dokumen", selector: (row) => row.document_id, sortable: true },
        // {
        //     name: "Barcode Lokasi",
        //     selector: (row) => row.kpms.location_barcode,
        //     sortable: true,
        // },
        // { name: 'driver_id', selector: row => row.driver_id, sortable: true },
        // { name: "Nama", selector: (row) => row.drivers.name, sortable: true },
        // { name: 'vehicle_id', selector: row => row.vehicle_id, sortable: true },
        // { name: "Merk", selector: (row) => row.vehicles.make, sortable: true },
        {
            name: "Tanggal Pengiriman",
            selector: (row) => row.shipment_date,
            sortable: true,
        },
        { name: "Tujuan", selector: (row) => row.destination, sortable: true },
        { name: "Status", selector: (row) => row.status, sortable: true },

        // {
        //     name: "Latitude Tujuan",
        //     selector: (row) => row.destination_latitude,
        //     sortable: true,
        // },
        // {
        //     name: "Longitude Tujuan",
        //     selector: (row) => row.destination_longitude,
        //     sortable: true,
        // },
        // {
        //     name: "Verifikasi Dokumen",
        //     selector: (row) => row.verif_documents,
        //     sortable: true,
        // },
        // { name: "Dibuat Oleh", selector: (row) => row.created_by, sortable: true },
        // {
        //     name: "Diperbarui Oleh",
        //     selector: (row) => row.updated_by,
        //     sortable: true,
        // },
        // {
        //     name: "Dokumen Diunggah Oleh",
        //     selector: (row) => row.upload_doc_by,
        //     sortable: true,
        // },
        // {
        //     name: "Foto Penjemputan",
        //     selector: (row) => row.photo_pickup,
        //     sortable: true,
        // },
        // {
        //     name: "Diselesaikan Oleh",
        //     selector: (row) => row.finish_by,
        //     sortable: true,
        // },
        // {
        //     name: "Tanggal Dibuat",
        //     selector: (row) => row.created_at,
        //     sortable: true,
        // },
        // {
        //     name: "Tanggal Diperbarui",
        //     selector: (row) => row.updated_at,
        //     sortable: true,
        // },
        // { name: 'loading_at', selector: row => row.loading_at, sortable: true },
        // { name: 'loading_by', selector: row => row.loading_by, sortable: true },
        // {
        //     name: "Kuantitas Paket",
        //     selector: (row) => row.package_qty,
        //     sortable: true,
        // },
        // { name: "Catatan", selector: (row) => row.notes, sortable: true },
        // { name: "Kode BAST", selector: (row) => row.bast_code, sortable: true },
        // { name: "Kode TTB", selector: (row) => row.ttb_code, sortable: true },
        // {
        //     name: "Nama Pejabat",
        //     selector: (row) => row.nama_pejabat,
        //     sortable: true,
        // },
        // {
        //     name: "Nama Pendamping",
        //     selector: (row) => row.nama_pendamping,
        //     sortable: true,
        // },
        // {
        //     name: "Nama Supir",
        //     selector: (row) => row.driver_name,
        //     sortable: true,
        // },
        // {
        //     name: "No. Telp Supir",
        //     selector: (row) => row.phone_number,
        //     sortable: true,
        // },
        // {
        //     name: "No. Pol. Kendaraan",
        //     selector: (row) => row.plate_number,
        //     sortable: true,
        // },

        {
            name: "",
            button: true,
            cell: (row) => (
                <Button
                    variant="link"
                    className="btn-sm"
                    onClick={() => handleRowView(row)}
                >
                    Lihat
                </Button>
            ),
        },
        {
            name: "",
            button: true,
            cell: (row) => (
                <Button
                    variant="link"
                    className="btn-sm"
                    onClick={() => handleRowEdit(row, isSelectedProvince, isSelectedCity, isSelectedDistrict, isSelectedSubDistrict)}
                >
                    Ubah
                </Button>
            ),
        },
        {
            name: "",
            button: true,
            cell: (row) => (
                <Button
                    variant="link"
                    className="btn-sm"
                    onClick={() => isButtonGenerateDisabled() === false ? handleRowPrint(row) : setShowWarningGenerate(true)}>
                    Cetak
                </Button>
            ),
        },
        // {
        //     name: "",
        //     button: true,
        //     cell: (row) => (
        //         <Button
        //             variant="link"
        //             className="btn-sm"
        //             onClick={() => handleRowPrintLampiran(row)}
        //         >
        //             Lampiran
        //         </Button>
        //     ),
        // },
        // {
        //     name: "",
        //     button: true,
        //     cell: (row) => (
        //         <Button
        //             variant="link"
        //             className="btn-sm"
        //             onClick={() => handleRowDeleteClick(row)}
        //         >
        //             Hapus
        //         </Button>
        //     ),
        // },
    ];
    const location = useLocation();
    const [selectedProvince, setSelectedProvince] = useState(0);
    const [selectedCity, setSelectedCity] = useState(0);
    const [selectedDistrict, setSelectedDistrict] = useState(0);
    const [selectedSubdistrict, setSelectedSubdistrict] = useState(0);
    const [selectedDate, setSelectedDate] = useState("");
    const [isSelectedProvince, setIsSelectedProvince] = useState(false)
    const [isSelectedCity, setIsSelectedCity] = useState(false)
    const [isSelectedDistrict, setIsSelectedDistrict] = useState(false)
    const [isSelectedSubDistrict, setIsSelectedSubDistrict] = useState(false)

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value); // Memperbarui nilai state saat input date berubah
    };
    const handleProvinceChange = (event) => {
        const newValue = event.target.value;
        setSelectedProvince(newValue);
        setIsSelectedProvince(true)
    };

    const handleCityChange = (event) => {
        const newValue = event.target.value;
        setSelectedCity(newValue);
        setIsSelectedCity(true)
    };

    const handleDistrictChange = (event) => {
        const newValue = event.target.value;
        setSelectedDistrict(newValue);
        setIsSelectedDistrict(true)
    };

    const handleSubdistrictChange = (event) => {
        const newValue = event.target.value;
        setSelectedSubdistrict(newValue);
        setIsSelectedSubDistrict(true)
    };
    useEffect(() => {
        if (selectedProvince) {
            dispatch(resetCitiesToInit());
            dispatch(startLoadingCities())
            getCities(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', selectedProvince).then((response) => {
                if (response && response.records) {
                    dispatch(setCitiesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingCities())
                } else {
                    dispatch(setCitiesMessage("Data kosong"));
                    dispatch(stopLoadingCities())
                }
            })
        }
    }, [selectedProvince]);

    useEffect(() => {
        if (roles === 'Super Admin') {
            if (selectedCity) {
                dispatch(resetDistrictsToInit());
                dispatch(startLoadingDistricts())
                getDistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', selectedCity).then((response) => {
                    if (response && response.records) {
                        dispatch(setDistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                        dispatch(stopLoadingDistricts())
                    } else {
                        dispatch(setDistrictsMessage("Data kosong"));
                        dispatch(stopLoadingDistricts())
                    }
                })
            }
        } else {
            dispatch(resetDistrictsToInit());
            dispatch(startLoadingDistricts())
            getDistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', cityId).then((response) => {
                if (response && response.records) {
                    dispatch(setDistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingDistricts())
                } else {
                    dispatch(setDistrictsMessage("Data kosong"));
                    dispatch(stopLoadingDistricts())
                }
            })
        }

    }, [selectedCity]);

    useEffect(() => {
        if (selectedDistrict) {
            dispatch(resetSubdistrictsToInit());
            dispatch(startLoadingSubDistricts())
            getSubdistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', selectedDistrict).then((response) => {
                if (response && response.records) {
                    dispatch(setSubdistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingSubDistricts())
                } else {
                    dispatch(setSubdistrictsMessage("Data kosong"));
                    dispatch(stopLoadingSubDistricts())
                }
            })
        }
    }, [selectedDistrict]);
    const filterData = () => {
        setSearch('')
        if (roles === 'Super Admin') {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId, selectedDate, selectedProvince || location.state && location.state.isFiltered.isProvinceSelected && location.state.row.provinces.province_id || 0, selectedCity || location.state && location.state.isFiltered.isCitySelected && location.state.row.cities.city_id || 0, selectedDistrict || location.state && location.state.isFiltered.isDistrictSelected && location.state.row.districts.district_id || 0, selectedSubdistrict || location.state && location.state.isFiltered.isSubDistrictSelected && location.state.row.subdistricts.subdistrict_id || 0)
        } else {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId, selectedDate, provinceId, cityId, selectedDistrict || location.state && location.state.isFiltered.isDistrictSelected && location.state.row.districts.district_id || 0, selectedSubdistrict || location.state && location.state.isFiltered.isSubDistrictSelected && location.state.row.subdistricts.subdistrict_id || 0)
        }
    }

    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                    Daftar Surat Jalan ({rData.totalCount})
                    <Button
                        variant="light"
                        className="btn-circle btn-sm ml-2"
                        onClick={handleReset}
                    >
                        <i className="fa fa-refresh"></i>
                    </Button>
                    {/* <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
                        <span className="icon text-white-50">
                            <i className="fas fa-add"></i>
                        </span>
                        <span className="text">Add New</span>
                    </Button> */}
                </h6>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col md={6}>
                        {/* <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control as="select" custom onChange={handlePeriodsChange} value={selectedPeriods} required>

                  <option value={0} disabled>{periodsLoading ? 'Loading...' : 'Pilih Periode'}</option>
                  {
                    periodsData.list.map((item, i) => {
                      return <option value={item.id} key={`periods-${i}`}>{item.id} ({item.month} - {item.year})</option>
                    })}
                </Form.Control>
              </Form.Group> */}
                        {/* <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control as="select" custom value={selectedProvince}
                  onChange={handleProvinceChange}>
                  <option value={0} disabled>{provincesLoading ? 'Loading...' : 'Pilih Provinsi'}</option>
                  {
                    provincesData.list.map((item, i) => {
                      return <option value={item.province_id} key={`provinces-${i}`}>{item.province_name}</option>
                    })}
                </Form.Control>
              </Form.Group> */}
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Control
                                type="date"
                                custom
                                className="form-control"
                                value={selectedDate} // Menampilkan nilai dari state pada input date
                                onChange={handleDateChange} //
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Control as="select" custom value={selectedProvince} disabled={roles !== 'Super Admin'}
                                onChange={handleProvinceChange}>
                                <option value={0} disabled>{provincesLoading ? 'Loading...' : location.state && location.state.isFiltered.isProvinceSelected ? location.state.row.provinces.province_name : roles !== 'Super Admin' ? provinceName : 'Pilih Provinsi'}</option>
                                {
                                    provincesData.list.map((item, i) => {
                                        return <option value={item.province_id} key={`provinces-${i}`}>{item.province_name}</option>
                                    })}
                            </Form.Control>
                        </Form.Group>

                    </Col>
                    <Col md={6} className="d-none d-md-block">
                        <InputGroup>
                            <Form.Control
                                placeholder="Cari"
                                aria-label="Search"
                                aria-describedby="basic-search"
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                            />
                            <Button
                                variant="dark"
                                className="rounded-0 rounded-right mr-2"
                                id="button-search"
                                onClick={handleSearch}
                            >
                                Cari
                            </Button>
                        </InputGroup>
                        <Toast autohide show={warningGenerate} onClose={() => setShowWarningGenerate(false)}>
                            <Toast.Header>
                                <strong className="me-auto">Perhatian!!!</strong>
                            </Toast.Header>
                            <Toast.Body>Cetak Surat Jalan tidak bisa dilakukan pada Pukul 06.00 - 12.00 WIB</Toast.Body>
                        </Toast>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Control as="select" custom value={selectedCity} disabled={roles !== 'Super Admin'}
                                onChange={handleCityChange}>
                                <option value={0} disabled>{citiesLoading ? 'Loading...' : location.state && location.state.isFiltered.isCitySelected ? location.state.row.cities.city_name : roles !== 'Super Admin' ? cityName : 'Pilih Kota'}</option>
                                {
                                    citiesData.list.map((item, i) => {
                                        return <option value={item.city_id} key={`cities-${i}`}>{item.city_name}</option>
                                    })}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Control as="select" custom value={selectedDistrict}
                                onChange={handleDistrictChange}>
                                <option value={0} disabled>{districtsLoading ? 'Loading...' : location.state && location.state.isFiltered.isDistrictSelected ? location.state.row.districts.district_name : 'Pilih Kecamatan'}</option>
                                {
                                    districtsData.list.map((item, i) => {
                                        return <option value={item.district_id} key={`districts-${i}`}>{item.district_name}</option>
                                    })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Control as="select" custom value={selectedSubdistrict}
                                onChange={handleSubdistrictChange}>
                                <option value={0} disabled>{subdistrictsLoading ? 'Loading...' : location.state && location.state.isFiltered.isSubDistrictSelected ? location.state.row.subdistricts.subdistrict_name : 'Pilih Kelurahan'}</option>
                                {
                                    subdistrictsData.list.map((item, i) => {
                                        return <option value={item.subdistrict_id} key={`subdistricts-${i}`}>{item.subdistrict_name}</option>
                                    })}
                            </Form.Control>
                        </Form.Group>
                        <Button className="float-right d-none d-md-block" type="primary" onClick={() => filterData()}>Filter</Button>
                        <Button className="float-right mb-3 d-md-none" type="primary" onClick={() => filterData()}>Filter</Button>
                        <div className="d-md-none">
                            <InputGroup>
                                <Form.Control
                                    placeholder="Cari"
                                    aria-label="Search"
                                    aria-describedby="basic-search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                />
                                <Button
                                    variant="dark"
                                    className="rounded-0 rounded-right"
                                    id="button-search"
                                    onClick={handleSearch}
                                >
                                    Cari
                                </Button>
                            </InputGroup>
                            <Toast autohide show={warningGenerate} onClose={() => setShowWarningGenerate(false)}>
                                <Toast.Header>
                                    <strong className="me-auto">Perhatian!!!</strong>
                                </Toast.Header>
                                <Toast.Body>Cetak Surat Jalan tidak bisa dilakukan pada Pukul 06.00 - 12.00 WIB</Toast.Body>
                            </Toast>
                        </div>
                    </Col>
                </Row>

                <div className="table-responsive mt-5">
                    {isLoading && rData.list.length === 0 ? <Spinner animation="border" variant="warning" className="d-flex mx-auto"></Spinner> : <DataTable
                        // selectableRows={true}
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageNumber}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={columns} data={rData.list}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-bordered"
                        pagination
                        paginationServer
                        progressPending={isLoading}
                        onChangePage={handlePageChange}></DataTable>}
                </div>
            </Card.Body>
            <ConfirmationModal
                buttonNegative="Batal"
                buttonPositive="Hapus"
                title="Konfirmasi Hapus"
                show={showDelete}
                body={"Apakah anda yakin?"}
                onNegative={() => setShowDelete(false)}
                onPositive={handleServerDelete}
            />
            {/* <ConfirmationModal
            // disableButton={!formik.values.no_doc_out || !formik.values.no_so}
            buttonNegative="Batal"
            buttonPositive="Lanjut"
            title="Konfirmasi Ubah Status"
            show={showPrint}
            body={<WordingToCreated />}
            onNegative={() => setShowPrint(false)}
            onPositive={() => handleConfirmPrint()}
      /> */}
            <ConfirmationModal
                buttonNegative="Batal"
                buttonPositive={bastData.length === 0 ? 'Loading..' : 'Cetak'}
                title="Konfirmasi Surat Jalan"
                show={showConfirmation}
                body={"Silahkan klik tombol cetak"}
                onNegative={() => setShowConfirmation(false)}
                onPositive={handlePrint}
                disableButton={bastData.length === 0}
            />
            <ConfirmationModal
                buttonNegative="Batal"
                title="Konfirmasi Cetak Lampiran"
                show={showLampiran}
                body={"Silahkan klik tombol cetak"}
                onNegative={() => setShowLampiran(false)}
                onPositive={handlePrintLampiran}
                disableButton={bastData.length === 0}
                buttonPositive={bastData.length === 0 ? 'Loading..' : 'Cetak'}
            />
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={showPrint}
                    onHide={() => handleClose()}
                    dialogClassName="modal-dialog-centered"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi Cetak Surat Jalan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="no_so">
                                <Form.Label>No. SO</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="no_so"
                                    onChange={handleChange}
                                    value={dataDokSo.no_so}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="no_so">
                                <Form.Label>No. DOK OUT</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="no_doc_out"
                                    onChange={handleChange}
                                    value={dataDokSo.no_doc_out}
                                />
                            </Form.Group>
                        </Form>
                        <p>*boleh dikosongkan</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose()}>
                            Batal
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => handleConfirmPrint()}
                        // disabled={!dataDokSo.no_doc_out || !dataDokSo.no_so}
                        >
                            Lanjut
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

            <div style={{ display: "none" }}>
                <ComponentSj
                    ref={componentSjRef}
                    bastData={bastData}
                />
            </div>
            {/* <div className="">
                <ComponentUndangan ref={componentUndanganRef}/>
            </div> */}
            {/* <div style={{ display: "none" }}>
                <ComponentLampiran ref={componentLampiranRef} bastData={bastData} />
            </div> */}
        </Card>
    );
};
