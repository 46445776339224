import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  resetCitiesToInit,
  resetDistrictsToInit,
  resetProvincesToInit,
  resetSubdistrictsToInit,
  setCitiesList,
  setCitiesMessage,
  setDistrictsList,
  setDistrictsMessage,
  setProvincesList,
  setProvincesMessage,
  setSubdistrictsList,
  setSubdistrictsMessage,
  startLoadingCities,
  startLoadingDistricts,
  startLoadingProvinces,
  startLoadingSubDistricts,
  stopLoadingCities,
  stopLoadingDistricts,
  stopLoadingProvinces,
  stopLoadingSubDistricts,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getCities } from "services/citiesService";
import { getDistricts } from "services/districtsService";
import { updateVerval } from "services/kpmsService";
import { getProvinces } from "services/provincesService";
import { getSubdistricts } from "services/subdistrictsService";
import Layout from "template";
import { Constant } from "template/Constant";
import * as yup from "yup";

export const NomenklaturKelurahan = () => {
  const periodsId = localStorage.getItem("periode_id");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("tambah");

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const iValue = {
    province_id: 0,
    city_id: 0,
    district_id: 0,
    subdistrict_id: 0,
    subdistrict_name: "",
  };

  const formik = useFormik({
    initialValues: iValue,
    onSubmit: async (values) => {
      if (selectedOption === "ubah") {
        setLoading(true);
        const data = {
          subdistrict_name: values.subdistrict_name,
        };
        const finalData = {
          periode_id: periodsId,
          type: "subdistrict",
          change_type: "update",
          data: data,
          affected_id: values.subdistrict_id,
        };
        try {
          const res = await updateVerval(finalData);
          if (res) {
            setLoading(false);
            setMessage("Sukses mengubah nama Kelurahan");
          }
        } catch (error) {
          setLoading(false);
          setMessage("Gagal mengubah nama Kelurahan");
        }
      } else if (selectedOption === "tambah") {
        setLoading(true);
        const data = {
          province_id: values.province_id,
          city_id: values.city_id,
          district_id: values.district_id,
          subdistrict_name: values.subdistrict_name,
        };
        const finalData = {
          periode_id: periodsId,
          type: "subdistrict",
          change_type: "add",
          data: data,
        };
        try {
          const res = await updateVerval(finalData);
          if (res) {
            setLoading(false);
            setMessage("Sukses menambah Kelurahan");
          }
        } catch (error) {
          setLoading(false);
          setMessage("Gagal menambah Kelurahan");
        }
      }
    },
    validationSchema: yup.object({
      subdistrict_name: yup.string().required("Nama Kelurahan wajib diisi"),
    }),
  });
  const formikProvinceId = formik.values.province_id;
  const formikCityId = formik.values.city_id;
  const formikDistrictId = formik.values.district_id;

  const provincesData = useSelector((state: RootState) => state.provinces);
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const citiesData = useSelector((state: RootState) => state.cities);
  const citiesLoading = useSelector((state: RootState) => state.cities.loading);
  const districtsData = useSelector((state: RootState) => state.districts);
  const districtsLoading = useSelector(
    (state: RootState) => state.districts.loading
  );
  const subdistrictsData = useSelector(
    (state: RootState) => state.subdistricts
  );
  const subdistrictsLoading = useSelector(
    (state: RootState) => state.subdistricts.loading
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (
      provincesData &&
      provincesData.list &&
      provincesData.list.length === 0
    ) {
      dispatch(resetProvincesToInit());
      dispatch(startLoadingProvinces());
      getProvinces(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setProvincesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingProvinces());
        } else {
          dispatch(setProvincesMessage("Data kosong"));
          dispatch(stopLoadingProvinces());
        }
      });
    }
  }, []);

  useEffect(() => {
    if (formikProvinceId) {
      dispatch(resetCitiesToInit());
      dispatch(startLoadingCities());
      getCities(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        formikProvinceId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setCitiesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingCities());
        } else {
          dispatch(setCitiesMessage("Data kosong"));
          dispatch(stopLoadingCities());
        }
      });
    }
  }, [formikProvinceId]);

  useEffect(() => {
    if (formikCityId) {
      dispatch(resetDistrictsToInit());
      dispatch(startLoadingDistricts());
      getDistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        formikCityId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setDistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingDistricts());
        } else {
          dispatch(setDistrictsMessage("Data kosong"));
          dispatch(stopLoadingDistricts());
        }
      });
    }
  }, [formikCityId]);

  useEffect(() => {
    if (formikDistrictId) {
      dispatch(resetSubdistrictsToInit());
      dispatch(startLoadingSubDistricts());
      getSubdistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        formikDistrictId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setSubdistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingSubDistricts());
        } else {
          dispatch(setSubdistrictsMessage("Data kosong"));
          dispatch(stopLoadingSubDistricts());
        }
      });
    }
  }, [formikDistrictId]);

  return (
    <Layout>
      <div className="container-fluid">
        {message && (
          <Alert
            variant={Constant.defaultAlertVarient}
            className="alert-dismissible fade"
          >
            {message}
          </Alert>
        )}

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Ubah Wilayah Kelurahan</h1>
        </div>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">Tipe</label>
            <Form.Check
              type="radio"
              label="Tambah"
              name="tipe"
              id="tambah"
              value="tambah"
              onChange={handleRadioChange}
              checked={selectedOption === "tambah"}
            />
            <Form.Check
              type="radio"
              label="Ubah"
              name="tipe"
              id="ubah"
              value="ubah"
              onChange={handleRadioChange}
              checked={selectedOption === "ubah"}
            />
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">ID Provinsi</label>
            <Form.Control
              as="select"
              name="province_id"
              className="form-control"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={provincesLoading}
            >
              <option value={0}>
                {provincesLoading ? "Loading..." : "Pilih Provinsi"}
              </option>
              {provincesData.list.map((item, i) => {
                return (
                  <option value={item.province_id} key={`provinces-${i}`}>
                    {item.province_name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">ID Kota</label>
            <Form.Control
              as="select"
              name="city_id"
              className="form-control"
              value={formik.values.city_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.city_id && !!formik.errors.city_id}
              isValid={!!formik.touched.city_id && !formik.errors.city_id}
              disabled={citiesLoading}
            >
              <option value={0}>
                {citiesLoading ? "Loading..." : "Pilih Kota"}
              </option>
              {citiesData.list.map((item, i) => {
                return (
                  <option value={item.city_id} key={`cities-${i}`}>
                    {item.city_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.city_id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.city_id}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">ID Kecamatan</label>
            <Form.Control
              as="select"
              name="district_id"
              className="form-control"
              value={formik.values.district_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.district_id && !!formik.errors.district_id
              }
              isValid={
                !!formik.touched.district_id && !formik.errors.district_id
              }
              disabled={districtsLoading}
            >
              <option value={0}>
                {districtsLoading ? "Loading..." : "Pilih Kecamatan"}
              </option>
              {districtsData.list.map((item, i) => {
                return (
                  <option value={item.district_id} key={`districts-${i}`}>
                    {item.district_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.district_id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.district_id}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            className="w-100 px-2 split-form"
            style={{ display: selectedOption === "tambah" && "none" }}
          >
            <label className="form -control-label">ID Kelurahan</label>
            <Form.Control
              as="select"
              name="subdistrict_id"
              className="form-control"
              value={formik.values.subdistrict_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.subdistrict_id &&
                !!formik.errors.subdistrict_id
              }
              isValid={
                !!formik.touched.subdistrict_id && !formik.errors.subdistrict_id
              }
              disabled={subdistrictsLoading}
            >
              <option value={0}>
                {subdistrictsLoading ? "Loading..." : "Pilih Kelurahan"}
              </option>
              {subdistrictsData.list.map((item, i) => {
                return (
                  <option value={item.subdistrict_id} key={`subdistricts-${i}`}>
                    {item.subdistrict_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.subdistrict_id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.subdistrict_id}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">Nama Kelurahan</label>
            <Form.Control
              type="text"
              name="subdistrict_name"
              className="form-control"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.subdistrict_name &&
                !!formik.errors.subdistrict_name
              }
              isValid={
                !!formik.touched.subdistrict_name &&
                !formik.errors.subdistrict_name
              }
            ></Form.Control>
            {formik.errors.subdistrict_name && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.subdistrict_name}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="px-2 w-100">
            <Button
              type="submit"
              className="float-left mr-3"
              variant="primary"
              disabled={loading}
            >
              {loading && (
                <Spinner
                  variant="light"
                  animation="border"
                  size="sm"
                  className="mr-1"
                ></Spinner>
              )}
              Simpan
            </Button>
          </Form.Group>
        </Form>
      </div>
    </Layout>
  );
};
