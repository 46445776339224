import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Spinner, Toast } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  resetProvincesToInit,
  startLoadingProvinces,
  setProvincesList,
  stopLoadingProvinces,
  setProvincesMessage,
  resetCitiesToInit,
  startLoadingCities,
  setCitiesList,
  stopLoadingCities,
  setCitiesMessage,
  resetDistrictsToInit,
  startLoadingDistricts,
  setDistrictsList,
  stopLoadingDistricts,
  setDistrictsMessage,
  resetSubdistrictsToInit,
  startLoadingSubDistricts,
  setSubdistrictsList,
  stopLoadingSubDistricts,
  setSubdistrictsMessage,
  setPengirimanMessage,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { getCities } from "services/citiesService";
import { getDistricts } from "services/districtsService";
import { getProvinces } from "services/provincesService";
import { getSubdistricts } from "services/subdistrictsService";
import { Constant } from "template/Constant";
import LogoBulog from "../../logos/logo-bulog.jpeg";
import LogoYAT from "../../logos/yat-logo.jpeg";
import { ListBNBA } from "./table/bnba";
import { useAppDispatch } from "redux/store";
type Props = {
  getData: (
    page,
    pageSize,
    searchKey,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id,
    print
  ) => void;
};
export const ShipmentsPrint: React.FC<Props> = ({ getData }) => {
  const provincesData = useSelector((state: RootState) => state.provinces);
  const citiesData = useSelector((state: RootState) => state.cities);
  const districtsData = useSelector((state: RootState) => state.districts);
  const subdistrictsData = useSelector(
    (state: RootState) => state.subdistricts
  );
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const citiesLoading = useSelector((state: RootState) => state.cities.loading);
  const districtsLoading = useSelector(
    (state: RootState) => state.districts.loading
  );
  const subdistrictsLoading = useSelector(
    (state: RootState) => state.subdistricts.loading
  );
  const [selectedProvince, setSelectedProvince] = useState(0);
  const [selectedCity, setSelectedCity] = useState(0);
  const [selectedDistrict, setSelectedDistrict] = useState(0);
  const [selectedSubdistrict, setSelectedSubdistrict] = useState(0);
  const periodsId = localStorage.getItem("periode_id");
  const provinceId = localStorage.getItem("province_id");
  const cityId = localStorage.getItem("city_id");
  const roles = localStorage.getItem("user");
  const provinceName = localStorage.getItem("province_name");
  const cityName = localStorage.getItem("city_name");
  const rData = useSelector((state: RootState) => state.pengiriman);
  const isLoading = useSelector((state: RootState) => state.pengiriman.loading);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [warningGenerate, setShowWarningGenerate] = useState(false)

  const handleProvinceChange = (event) => {
    const newValue = event.target.value;
    setSelectedProvince(newValue);
  };

  const handleCityChange = (event) => {
    const newValue = event.target.value;
    setSelectedCity(newValue);
  };

  const handleDistrictChange = (event) => {
    const newValue = event.target.value;
    setSelectedDistrict(newValue);
  };

  const handleSubdistrictChange = (event) => {
    const newValue = event.target.value;
    setSelectedSubdistrict(newValue);
  };
  useEffect(() => {
    if (
      provincesData &&
      provincesData.list &&
      provincesData.list.length === 0 &&
      roles === "Super Admin"
    ) {
      dispatch(resetProvincesToInit());
      dispatch(startLoadingProvinces());
      getProvinces(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setProvincesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingProvinces());
        } else {
          dispatch(setProvincesMessage("Data kosong"));
          dispatch(stopLoadingProvinces());
        }
      });
    }
  }, []);
  useEffect(() => {
    if (selectedProvince) {
      dispatch(resetCitiesToInit());
      dispatch(startLoadingCities());
      getCities(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedProvince
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setCitiesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingCities());
        } else {
          dispatch(setCitiesMessage("Data kosong"));
          dispatch(stopLoadingCities());
        }
      });
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (roles === "Super Admin") {
      if (selectedCity) {
        dispatch(resetDistrictsToInit());
        dispatch(startLoadingDistricts());
        getDistricts(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          "",
          selectedCity
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setDistrictsList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingDistricts());
          } else {
            dispatch(setDistrictsMessage("Data kosong"));
            dispatch(stopLoadingDistricts());
          }
        });
      }
    } else {
      dispatch(resetDistrictsToInit());
      dispatch(startLoadingDistricts());
      getDistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        cityId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setDistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingDistricts());
        } else {
          dispatch(setDistrictsMessage("Data kosong"));
          dispatch(stopLoadingDistricts());
        }
      });
    }
  }, [selectedCity]);

  const isButtonGenerateDisabled = () => {
    const currentDateTime = new Date();
    const currentHour = currentDateTime.getUTCHours() + 7;
    if (roles !== 'Super Admin') {
      return currentHour >= 6 && currentHour < 12;
    } else {
      return false
    }
  };

  useEffect(() => {
    if (selectedDistrict) {
      dispatch(resetSubdistrictsToInit());
      dispatch(startLoadingSubDistricts());
      getSubdistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedDistrict
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setSubdistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingSubDistricts());
        } else {
          dispatch(setSubdistrictsMessage("Data kosong"));
          dispatch(stopLoadingSubDistricts());
        }
      });
    }
  }, [selectedDistrict]);

  const handlePrintPDF = () => {
    if (!selectedDistrict && !selectedSubdistrict) {
      dispatch(setPengirimanMessage("Lengkapi data"));
    } else if (rData.list.length <= 0) {
      dispatch(setPengirimanMessage("Data kosong"));
    } else if (rData.list.length > 0) {
      dispatch(setPengirimanMessage(""));
      const doc = new jsPDF();
      const totalPagesExp = "{total_pages_count_string}";
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        autoTable(doc, {
          html: "#my-table",
          styles: {
            textColor: [0, 0, 0],
            cellPadding: 5,
            lineWidth: 0.1,
            lineColor: [0, 0, 0],
          },
          headStyles: { fillColor: [235, 195, 58], cellPadding: 3 },
          columnStyles: {
            1: { cellWidth: 40 },
            2: { cellWidth: 30, minCellHeight: 18 },
            3: { cellWidth: 45 },
          },

          willDrawPage: function (data) {
            doc.setFontSize(10);
            doc.setTextColor(40);
            doc.addImage(
              LogoBulog,
              "JPEG",
              data.settings.margin.left,
              10,
              30,
              10
            );
            doc.addImage(LogoYAT, "JPEG", 150, 5, 30, 15);
            doc.text(rData.list[0].bast_code ?? "-", 154, 22);
            doc.setFont("helvetica", "bold"); // Set the font style to bold
            doc.text("BERITA ACARA SERAH TERIMA (BAST)", 70, 30);
            doc.text(
              `PENERIMA BANTUAN PANGAN TAHUN ${rData.list[0].periods.year}`,
              67.5,
              35
            );
            doc.setFont("helvetica", "normal"); // Set the font style to bold
            doc.text("Nomor             : ", 67.5, 40);
            doc.text(
              `Alokasi Bulan  : ${rData.list[0].periods.month.toUpperCase() +
              " " +
              rData.list[0].periods.year ?? "-"
              }`,
              67.5,
              45
            );
            doc.text("PROVINSI", data.settings.margin.left, 55);
            doc.text(
              `: ${rData.list[0].provinces.province_name.toUpperCase() ?? "-"}`,
              50,
              55
            );
            doc.text("KABUPATEN", data.settings.margin.left, 60);
            doc.text(
              `: ${rData.list[0].cities.city_name.toUpperCase() ?? "-"}`,
              50,
              60
            );
            doc.text("KECAMATAN", data.settings.margin.left, 65);
            doc.text(
              `: ${rData.list[0].districts.district_name.toUpperCase() ?? "-"}`,
              50,
              65
            );
            doc.text("KELURAHAN/DESA", data.settings.margin.left, 70);
            doc.text(
              `: ${rData.list[0].subdistricts.subdistrict_name.toUpperCase() ?? "-"
              }`,
              50,
              70
            );
            doc.text(
              `Kami yang bertanda tangan pada daftar dibawah ini : `,
              data.settings.margin.left,
              80
            );
            doc.text(
              `menyatakan dengan sebenar-benarnya bahwa telah menerima 10 (sepuluh) KG beras dalam rangka Penyaluran CPP`,
              data.settings.margin.left,
              83.5
            );
            doc.text(
              `untuk pemberian Bantuan Pangan Tahun ${rData.list[0].periods.year} dengan kualitas baik:`,
              data.settings.margin.left,
              87
            );
          },
          didDrawPage: function (data) {
            let str = "Halaman " + doc.getNumberOfPages();
            if (typeof doc.putTotalPages === "function") {
              str =
                str +
                " | " +
                totalPagesExp +
                ` ${rData?.list[0].subdistricts?.subdistrict_name}`;
            }
            doc.setFontSize(10);
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
          },
          didDrawCell: function (data) {
            if (data.column.index === 4 && data.cell.section === "body") {
              const canvasId = `qr_${data.row.index}`;
              const canvas = document.getElementById(
                canvasId
              ) as HTMLCanvasElement;
              if (canvas) {
                const jpeg = canvas.toDataURL("image/jpeg");
                var td = data.cell.raw;
                if (td instanceof HTMLTableCellElement) {
                  var text = td.getElementsByTagName("span")[0];
                  doc.addImage(
                    jpeg,
                    "JPEG",
                    data.cell.x + 7.5,
                    data.cell.y + 2,
                    10,
                    10
                  );
                  doc.setFontSize(6);
                  doc.text(text.id, data.cell.x + 1.2, data.cell.y + 15);
                }
              }
            }
          },
          margin: { top: 90 },
        });

        if (doc.getNumberOfPages() > 0) {
          doc.addPage();
          doc.setFontSize(10);
          doc.setTextColor(40);
          doc.addImage(LogoBulog, "JPEG", 14.111111111111109, 10, 30, 10);
          doc.addImage(LogoYAT, "JPEG", 150, 5, 30, 15);
          doc.text(rData.list[0].bast_code ?? "-", 154, 22);
          doc.setFont("helvetica", "bold"); // Set the font style to bold
          doc.text("BERITA ACARA SERAH TERIMA (BAST)", 70, 30);
          doc.text(
            `PENERIMA BANTUAN PANGAN TAHUN ${rData.list[0].periods.year}`,
            67.5,
            35
          );
          doc.setFont("helvetica", "normal"); // Set the font style to bold
          doc.text("Nomor             : ", 67.5, 40);
          doc.text(
            `Alokasi Bulan  : ${rData.list[0].periods.month.toUpperCase() +
            " " +
            rData.list[0].periods.year ?? "-"
            }`,
            67.5,
            45
          );
          doc.text("PROVINSI", 14.111111111111109, 55);
          doc.text(
            `: ${rData.list[0].provinces.province_name.toUpperCase() ?? "-"}`,
            50,
            55
          );
          doc.text("KABUPATEN", 14.111111111111109, 60);
          doc.text(
            `: ${rData.list[0].cities.city_name.toUpperCase() ?? "-"}`,
            50,
            60
          );
          doc.text("KECAMATAN", 14.111111111111109, 65);
          doc.text(
            `: ${rData.list[0].districts.district_name.toUpperCase() ?? "-"}`,
            50,
            65
          );
          doc.text("KELURAHAN/DESA", 14.111111111111109, 70);
          doc.text(
            `: ${rData.list[0].subdistricts.subdistrict_name.toUpperCase() ?? "-"
            }`,
            50,
            70
          );
          doc.text(
            `Kami yang bertanda tangan pada daftar dibawah ini : `,
            14.111111111111109,
            80
          );
          doc.text(
            `menyatakan dengan sebenar-benarnya bahwa telah menerima 10 (sepuluh) KG beras dalam rangka Penyaluran CPP`,
            14.111111111111109,
            83.5
          );
          doc.text(
            `untuk pemberian Bantuan Pangan Tahun ${rData.list[0].periods.year} dengan kualitas baik:`,
            14.111111111111109,
            87
          );
          doc.text(`Mengetahui`, 50, 105);
          doc.text(`Aparat setempat*`, 47, 110);
          doc.text(`Yang menyerahkan`, 125, 105);
          doc.text(`Transporter`, 132, 110);
          doc.text(`...............................................`, 119, 140);
          doc.text(
            `...............................................`,
            38.5,
            140
          );
          doc.text(`(Nama Jelas)`, 130, 145);
          doc.text(`(Nama Jelas, TTD dan Stempel)`, 36, 145);
          doc.text(
            `..................,..............${rData.list[0].periods.year}`,
            155,
            95
          );
          doc.text(`Keterangan :`, 14.111111111111109, 155);
          doc.text(
            `* Aparat Setempat adalah Pengurus RT/RW atau sebutan nama lainnya atau aparat kelurahan / desa`,
            14.111111111111109,
            160
          );
          doc.text(
            `atau perwakilan penerima bantuan pangan sasaran.`,
            14.111111111111109,
            165
          );
          doc.text(`** Stempel bagi yang memiliki.`, 14.111111111111109, 170);
          doc.text(
            `Halaman ${doc.getNumberOfPages()} | ${doc.getNumberOfPages()} ${rData?.list[0].subdistricts?.subdistrict_name
            }`,
            14.111111111111109,
            297.0000833333333 - 10
          );
        }

        if (typeof doc.putTotalPages === "function") {
          doc.putTotalPages(totalPagesExp);
        }

        doc.save(
          `${rData?.list[0].subdistricts?.subdistrict_name} - ${rData?.list[0].districts?.district_name} - ${rData?.list[0].cities?.city_name}.pdf`
        );
      }, 1000);
    }
  };
  useEffect(() => {
    if (roles === "Super Admin") {
      if (
        selectedProvince &&
        selectedCity &&
        selectedDistrict &&
        selectedSubdistrict
      ) {
        getData(
          "",
          10000,
          "",
          periodsId,
          "",
          selectedProvince,
          selectedCity,
          selectedDistrict,
          selectedSubdistrict,
          "print"
        );
      }
    } else {
      if (selectedDistrict && selectedSubdistrict) {
        getData(
          "",
          10000,
          "",
          periodsId,
          "",
          provinceId,
          cityId,
          selectedDistrict,
          selectedSubdistrict,
          "print"
        );
      }
    }
  }, [selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict]);

  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">Cetak BNBA</h6>
      </Card.Header>
      {/* <pre>{JSON.stringify(rData, null, 2)}</pre> */}
      <Card.Body className="col-md-6">
        <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Control
            as="select"
            custom
            value={selectedProvince}
            disabled={roles !== "Super Admin" || loading || rData.loading}
            onChange={handleProvinceChange}
          >
            <option value={0} disabled>
              {provincesLoading
                ? "Loading..."
                : roles !== "Super Admin"
                  ? provinceName
                  : "Pilih Provinsi"}
            </option>
            {provincesData.list.map((item, i) => {
              return (
                <option value={item.province_id} key={`provinces-${i}`}>
                  {item.province_name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Control
            as="select"
            custom
            value={selectedCity}
            disabled={roles !== "Super Admin" || loading || rData.loading}
            onChange={handleCityChange}
          >
            <option value={0} disabled>
              {citiesLoading
                ? "Loading..."
                : roles !== "Super Admin"
                  ? cityName
                  : "Pilih Kota"}
            </option>
            {citiesData.list.map((item, i) => {
              return (
                <option value={item.city_id} key={`cities-${i}`}>
                  {item.city_name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Control
            as="select"
            custom
            value={selectedDistrict}
            onChange={handleDistrictChange}
            disabled={loading || rData.loading}
          >
            <option value={0} disabled>
              {districtsLoading ? "Loading..." : "Pilih Kecamatan"}
            </option>
            {districtsData.list.map((item, i) => {
              return (
                <option value={item.district_id} key={`districts-${i}`}>
                  {item.district_name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Control
            as="select"
            custom
            value={selectedSubdistrict}
            onChange={handleSubdistrictChange}
            disabled={loading || rData.loading}
          >
            <option value={0} disabled>
              {subdistrictsLoading ? "Loading..." : "Pilih Kelurahan"}
            </option>
            {subdistrictsData.list.map((item, i) => {
              return (
                <option value={item.subdistrict_id} key={`subdistricts-${i}`}>
                  {item.subdistrict_name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <ListBNBA data={rData.list} />
        <Button
          className="float-right"
          onClick={() => isButtonGenerateDisabled() === false ? handlePrintPDF() : setShowWarningGenerate(true)}
          disabled={loading || rData.loading}>

          {isLoading ||
            (loading && (
              <Spinner
                animation="border"
                variant="light"
                style={{ width: "15px", height: "15px", fontSize: "10px" }}
              ></Spinner>
            ))}{" "}
          {rData.loading ? "Loading..." : "Cetak"}
        </Button>
        <Toast autohide show={warningGenerate} onClose={() => setShowWarningGenerate(false)}>
          <Toast.Header>
            <strong className="me-auto">Perhatian!!!</strong>
          </Toast.Header>
          <Toast.Body>Cetak BNBA tidak bisa dilakukan pada Pukul 06.00 - 12.00 WIB</Toast.Body>
        </Toast>
      </Card.Body>
    </Card>
  );
};
