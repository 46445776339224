import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import {
  resetCitiesToInit,
  resetDistrictsToInit,
  resetKpmsToInit,
  resetPeriodsToInit,
  resetProvincesToInit,
  resetSubdistrictsToInit,
  setCitiesList,
  setCitiesMessage,
  setDistrictsList,
  setDistrictsMessage,
  setKpmsMessage,
  setPeriodsList,
  setPeriodsMessage,
  setProvincesList,
  setProvincesMessage,
  setSubdistrictsList,
  setSubdistrictsMessage,
  startLoadingCities,
  startLoadingDistricts,
  startLoadingPeriods,
  startLoadingProvinces,
  startLoadingSubDistricts,
  stopLoadingCities,
  stopLoadingDistricts,
  stopLoadingPeriods,
  stopLoadingProvinces,
  stopLoadingSubDistricts,
} from "redux/actions";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteImage, deleteKpms } from "services/kpmsService";
import { uploadFileKPMS } from "services/fileUploadService";
import { useFormik } from "formik";
import * as yup from "yup";
import { ComponentUndangan } from "../../kpms/print/printInvitation";
import { useReactToPrint } from "react-to-print";
import { getDistricts } from "services/districtsService";
import { getSubdistricts } from "services/subdistrictsService";
import { getCities } from "services/citiesService";
import { getProvinces } from "services/provincesService";
import { getPeriods } from "services/periodsService";
import ProgressBar from "@ramonak/react-progress-bar";
import axios from "axios";
import Toast from "react-bootstrap/Toast";
import { useLocation } from "react-router";
import ActionButton from "../../kpms/actionButton";
import { postApprove } from "services/vervalService";

type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: (row, editAction) => void;
  handleRowDetail: (
    row,
    isSelectedProvince,
    isSelectedCity,
    isSelectedDistrict,
    isSelectedSubDistrict
  ) => void;
  getData: (
    page,
    pageSize,
    searchKey,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id
  ) => void;
  section: string;
};
export const VervalApproveTable: React.FC<Props> = ({
  getData,
  section,
  handleRowDetail,
  handleRowEdit,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const [showUpload, setShowUpload] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const rData = useSelector((state: RootState) => state.vervalApprove);
  const periodsData = useSelector((state: RootState) => state.periods);
  const provincesData = useSelector((state: RootState) => state.provinces);
  const citiesData = useSelector((state: RootState) => state.cities);
  const districtsData = useSelector((state: RootState) => state.districts);
  const subdistrictsData = useSelector(
    (state: RootState) => state.subdistricts
  );
  const periodsLoading = useSelector(
    (state: RootState) => state.periods.loading
  );
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const citiesLoading = useSelector((state: RootState) => state.cities.loading);
  const districtsLoading = useSelector(
    (state: RootState) => state.districts.loading
  );
  const subdistrictsLoading = useSelector(
    (state: RootState) => state.subdistricts.loading
  );
  const isLoading = useSelector(
    (state: RootState) => state.vervalApprove.loading
  );
  const periodsId = localStorage.getItem("periode_id");
  const provinceId = localStorage.getItem("province_id");
  const cityId = localStorage.getItem("city_id");
  const roles = localStorage.getItem("user");
  const email = localStorage.getItem("userEmail");
  const provinceName = localStorage.getItem("province_name");
  const cityName = localStorage.getItem("city_name");
  const [showImage, setShowImage] = useState(false);
  const [showGenerate, setShowGenerate] = useState(false);
  const [generateProgress, setGenerateProgress] = useState(0);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [generateMessagesOnSuccess, setGenerateMessagesOnSuccess] =
    useState("");
  const [generateMessagesOnError, setGenerateMessagesOnError] = useState("");
  const [generateMessagesOnErrorBNBA, setGenerateMessagesOnErrorBNBA] =
    useState("");
  const [generateMessagesOnErrorSJ, setGenerateMessagesOnErrorSJ] =
    useState("");
  const [warningGenerate, setShowWarningGenerate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  interface ImageObject {
    attachment_arrived: string;
    attachment_received: string;
    attachments_represent: string;
    pbps_id: number;
  }

  const [image, setImage] = useState<ImageObject | null>(null);

  const location = useLocation();

  const handleSearch = () => {
    if (search.length > 0) {
      if (roles === "Super Admin" || roles === "Admin Cetak") {
        setSelectedDate("");
        setSelectedProvince(0);
        setSelectedCity(0);
        setSelectedDistrict(0);
        setSelectedSubdistrict(0);
        getData(
          Constant.defaultPageNumber,
          Constant.defaultPageSize,
          search ?? "",
          periodsId,
          selectedDate,
          selectedProvince,
          selectedCity,
          selectedDistrict,
          selectedSubdistrict
        );
      } else {
        setSelectedDate("");
        setSelectedDistrict(0);
        setSelectedSubdistrict(0);
        getData(
          Constant.defaultPageNumber,
          Constant.defaultPageSize,
          search ?? "",
          periodsId,
          selectedDate,
          provinceId,
          cityId,
          "",
          ""
        );
      }
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (roles === "Super Admin" || roles === "Admin Cetak") {
      if (
        selectedProvince ||
        (location.state && location.state.isFiltered.isProvinceSelected)
      ) {
        await getData(
          page,
          newPerPage,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          selectedProvince ||
            (location.state &&
              location.state.isFiltered.isProvinceSelected &&
              location.state.row.provinces.province_id) ||
            0,
          selectedCity ||
            (location.state &&
              location.state.isFiltered.isCitySelected &&
              location.state.row.cities.city_id) ||
            0,
          selectedDistrict ||
            (location.state &&
              location.state.isFiltered.isDistrictSelected &&
              location.state.row.districts.district_id) ||
            0,
          selectedSubdistrict ||
            (location.state &&
              location.state.isFiltered.isSubDistrictSelected &&
              location.state.row.subdistricts.subdistrict_id) ||
            0
        );
      } else {
        await getData(
          page,
          newPerPage,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          "",
          "",
          "",
          ""
        );
      }
    } else {
      if (
        selectedDistrict ||
        (location.state && location.state.isFiltered.isDistrictSelected)
      ) {
        await getData(
          page,
          newPerPage,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          provinceId,
          cityId,
          selectedDistrict ||
            (location.state &&
              location.state.isFiltered.isDistrictSelected &&
              location.state.row.districts.district_id) ||
            0,
          selectedSubdistrict ||
            (location.state &&
              location.state.isFiltered.isSubDistrictSelected &&
              location.state.row.subdistricts.subdistrict_id) ||
            0
        );
      } else {
        await getData(
          page,
          newPerPage,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          provinceId,
          cityId,
          "",
          ""
        );
      }
    }
  };

  const handlePageChange = (page) => {
    if (roles === "Super Admin" || roles === "Admin Cetak") {
      if (
        selectedProvince ||
        (location.state && location.state.isFiltered.isProvinceSelected)
      ) {
        getData(
          page,
          rData.pageSize,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          selectedProvince ||
            (location.state &&
              location.state.isFiltered.isProvinceSelected &&
              location.state.row.provinces.province_id) ||
            0,
          selectedCity ||
            (location.state &&
              location.state.isFiltered.isCitySelected &&
              location.state.row.cities.city_id) ||
            0,
          selectedDistrict ||
            (location.state &&
              location.state.isFiltered.isDistrictSelected &&
              location.state.row.districts.district_id) ||
            0,
          selectedSubdistrict ||
            (location.state &&
              location.state.isFiltered.isSubDistrictSelected &&
              location.state.row.subdistricts.subdistrict_id) ||
            0
        );
      } else {
        getData(
          page,
          rData.pageSize,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          "",
          "",
          "",
          ""
        );
      }
    } else {
      if (
        selectedDistrict ||
        (location.state && location.state.isFiltered.isDistrictSelected)
      ) {
        getData(
          page,
          rData.pageSize,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          provinceId,
          cityId,
          selectedDistrict ||
            (location.state &&
              location.state.isFiltered.isDistrictSelected &&
              location.state.row.districts.district_id) ||
            0,
          selectedSubdistrict ||
            (location.state &&
              location.state.isFiltered.isSubDistrictSelected &&
              location.state.row.subdistricts.subdistrict_id) ||
            0
        );
      } else {
        getData(
          page,
          rData.pageSize,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          provinceId,
          cityId,
          "",
          ""
        );
      }
    }
  };

  useEffect(() => {
    window.history.replaceState({}, document.title);
    if (roles === "Super Admin" || roles === "Admin Cetak") {
      if (
        rData &&
        rData.list &&
        rData.list.length === 0 &&
        !search &&
        !selectedDate &&
        !selectedProvince &&
        !selectedCity &&
        !selectedDistrict &&
        !selectedSubdistrict
      ) {
        // getData(
        //   Constant.defaultPageNumber,
        //   Constant.defaultPageSize,
        //   "",
        //   periodsId,
        //   "",
        //   "",
        //   "",
        //   "",
        //   ""
        // );
      }
    } else {
      if (
        rData &&
        rData.list &&
        rData.list.length === 0 &&
        !search &&
        !selectedDate &&
        !selectedDistrict &&
        !selectedSubdistrict
      ) {
        getData(
          Constant.defaultPageNumber,
          Constant.defaultPageSize,
          "",
          periodsId,
          "",
          provinceId,
          cityId,
          "",
          ""
        );
      }
    }

    if (
      (provincesData &&
        provincesData.list &&
        provincesData.list.length === 0 &&
        roles === "Super Admin") ||
      roles === "Admin Cetak"
    ) {
      dispatch(resetProvincesToInit());
      dispatch(startLoadingProvinces());
      getProvinces(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setProvincesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingProvinces());
        } else {
          dispatch(setProvincesMessage("Data kosong"));
          dispatch(stopLoadingProvinces());
        }
      });
    }
    // if (periodsData && periodsData.list && periodsData.list.length === 0) {
    //   dispatch(resetPeriodsToInit());
    //   dispatch(startLoadingPeriods())
    //   getPeriods(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
    //     if (response && response.records) {
    //       dispatch(setPeriodsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
    //       dispatch(stopLoadingPeriods())
    //     } else {
    //       dispatch(setPeriodsMessage("Data kosong"));
    //       dispatch(stopLoadingPeriods())
    //     }
    //   })
    // }
  }, [rData.list.length]);

  const handleReset = () => {
    setSearch("");
    setSelectedDate("");
    setSelectedProvince(0);
    setSelectedCity(0);
    setSelectedDistrict(0);
    setSelectedSubdistrict(0);
    dispatch(resetKpmsToInit());
    if (roles === "Super Admin" || roles === "Admin Cetak") {
      getData(
        Constant.defaultPageNumber,
        rData.pageSize,
        "",
        periodsId,
        "",
        "",
        "",
        "",
        ""
      );
    } else {
      getData(
        Constant.defaultPageNumber,
        rData.pageSize,
        "",
        periodsId,
        "",
        provinceId,
        cityId,
        "",
        ""
      );
    }
  };

  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deleteKpms(rowData.id);
      if (response) {
        dispatch(resetKpmsToInit());
        dispatch(setKpmsMessage("Berhasil menghapus data"));
        if (roles === "Super Admin" || roles === "Admin Cetak") {
          getData(
            Constant.defaultPageNumber,
            Constant.defaultPageSize,
            "",
            periodsId,
            "",
            "",
            "",
            "",
            ""
          );
        } else {
          getData(
            Constant.defaultPageNumber,
            Constant.defaultPageSize,
            "",
            periodsId,
            "",
            provinceId,
            cityId,
            "",
            ""
          );
        }
        setShowDelete(false);
      } else {
        dispatch(setKpmsMessage("Terjadi kesalahan"));
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };

  const formikFile = useFormik({
    initialValues: { fileData: [] },
    onSubmit: async (values) => {
      setLoading(true);
      var formData = new FormData();
      formData.append("file", values.fileData[0]);
      formData.append("periode_id", selectedPeriods.toString());
      uploadFileKPMS(formData)
        .then((res: any) => {
          if (res && res.data && res.data.status === "success") {
            setMessage("Dokumen berhasil diunggah");
            formikFile.resetForm();
            setTimeout(() => {
              setMessage("");
            }, 1000);
            setLoading(false);
            setIsSuccess(true);
          }
        })
        .catch((error) => {
          setMessage(error.data.error || error.data.message);
          formikFile.resetForm();
          setLoading(false);
          setIsSuccess(false);
        });
    },
    validationSchema: yup.object({
      fileData: yup.array().min(1, "Pilih minimal 1 file"),
      // periodeId: yup.string().required('Pilih periode')
    }),
  });

  const [detail, setDetail] = useState(undefined);

  const handleModal = (row) => {
    setShowModal(true);
    setDetail(row);
  };

  console.log(detail);

  const columns = [
    {
      name: "Aksi",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleModal(row)}
        >
          Detail Approve
        </Button>
      ),
    },
    { name: "Periode", selector: (row) => row.periode_id, sortable: true },
    // { name: 'Nama Tahap', selector: row => row.periods.tahap_name, sortable: true },
    // { name: "ID Wilayah", selector: (row) => row.wilayah_id, sortable: true },
    // { name: 'province_id', selector: row => row.province_id, sortable: true },
    {
      name: "Provinsi",
      selector: (row) => row?.affected_data?.provinces?.province_name,
      sortable: true,
    },
    // { name: 'city_id', selector: row => row.city_id, sortable: true },
    {
      name: "Kota",
      selector: (row) => row?.affected_data?.cities?.city_name,
      sortable: true,
    },
    // { name: 'district_id', selector: row => row.district_id, sortable: true },
    {
      name: "Kecamatan",
      selector: (row) => row?.affected_data?.districts?.district_name,
      sortable: true,
    },
    // { name: 'subdistrict_id', selector: row => row.subdistrict_id, sortable: true },
    {
      name: "Kelurahan",
      selector: (row) => row?.affected_data?.subdistricts?.subdistrict_name,
      sortable: true,
    },
    // {
    //   name: "PBP Latitude",
    //   selector: (row) => row.kpm_latitude,
    //   sortable: true,
    // },
    // {
    //   name: "PBP Longitude",
    //   selector: (row) => row.kpm_longitude,
    //   sortable: true,
    // },
    // {
    //   name: "Lokasi Barcode",
    //   selector: (row) => row.location_barcode,
    //   sortable: true,
    // },
    {
      name: "Nomor Registrasi",
      selector: (row) => row.request_data?.reg_number,
      sortable: true,
    },
    {
      name: "Nama PBP",
      selector: (row) => row.affected_data?.kpm_name,
      sortable: true,
    },
    {
      name: "Alamat",
      selector: (row) => row.affected_data?.address,
      sortable: true,
    },
    // { name: "PBP Barcode", selector: (row) => row.kpm_barcode, sortable: true },
    // { name: 'created_by', selector: row => row.created_by, sortable: true },
    // { name: 'updated_by', selector: row => row.updated_by, sortable: true },
    { name: "Status", selector: (row) => row.status, sortable: true },

    // {
    //   name: "",
    //   button: true,
    //   cell: (row) => (
    //     <Button
    //       variant="link"
    //       className="btn-sm"
    //       style={{ display: section === "images" ? "block" : "none" }}
    //       onClick={() => handleSeeImages(row)}>
    //       Lihat foto
    //     </Button>
    //   ),
    // },
    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          style={{ display: section === "images" ? "block" : "none" }}
          onClick={() =>
            handleRowDetail(
              row,
              isSelectedProvince,
              isSelectedCity,
              isSelectedDistrict,
              isSelectedSubDistrict
            )
          }
        >
          Lihat
        </Button>
      ),
    },
    // { name: 'created_at', selector: row => row.created_at, sortable: true },
    // { name: 'updated_at', selector: row => row.updated_at, sortable: true },
    // { name: 'handover_at', selector: row => row.handover_at, sortable: true },
    // { name: 'handover_by', selector: row => row.handover_by, sortable: true },
    // {
    //   name: "Foto Handover",
    //   selector: (row) => row.photo_handover,
    //   sortable: true,
    // },
    // {
    //   name: "Foto Dokumen",
    //   selector: (row) => row.photo_document,
    //   sortable: true,
    // },
    // {
    //   name: "ID Pengiriman",
    //   selector: (row) => row.shipment_id,
    //   sortable: true,
    // },
    // { name: 'status', selector: row => row.shipments.status, sortable: true },
    // {
    //   name: "Bulk Handover",
    //   selector: (row) => row.bulk_handover,
    //   sortable: true,
    // },

    // {
    //   name: "",
    //   button: true,
    //   cell: (row) => (
    //     <Button
    //       variant="link"
    //       className="btn-sm"
    //       onClick={() => handleShowPrintInvitation(row)}
    //     >
    //       Print Undangan
    //     </Button>
    //   ),
    // },
    // {
    //   name: "Aksi",
    //   button: true,
    //   cell: (row) => (
    //     <div className="">
    //       <Button
    //         variant="link"
    //         className="btn-sm"
    //         style={{ display: section === "verval" ? "block" : "none" }}
    //         onClick={() => handleRowEdit(row, "ubah-alamat")}>
    //         Ubah Alamat
    //       </Button>
    //     </div>
    //   ),
    // },

    // {
    //   name: "",
    //   button: true,
    //   cell: (row) => (
    //     <Button
    //       variant="link"
    //       className="btn-sm"
    //       onClick={() => handleRowDeleteClick(row)}
    //     >
    //       Hapus
    //     </Button>
    //   ),
    // },
  ];

  const [selectedProvince, setSelectedProvince] = useState(0);

  const [selectedCity, setSelectedCity] = useState(0);

  const [selectedDistrict, setSelectedDistrict] = useState(0);

  const [selectedSubdistrict, setSelectedSubdistrict] = useState(0);

  const [selectedPeriods, setSelectedPeriods] = useState(0);

  const [selectedDate, setSelectedDate] = useState("");

  const [isSelectedProvince, setIsSelectedProvince] = useState(
    location?.state?.isFiltered?.isProvinceSelected || false
  );
  const [isSelectedCity, setIsSelectedCity] = useState(
    location?.state?.isFiltered?.isCitySelected || false
  );
  const [isSelectedDistrict, setIsSelectedDistrict] = useState(
    location?.state?.isFiltered?.isDistrictSelected || false
  );
  const [isSelectedSubDistrict, setIsSelectedSubDistrict] = useState(
    location?.state?.isFiltered?.isSubDistrictSelected || false
  );

  const handlePeriodsChange = (event) => {
    const newValue = event.target.value;
    setSelectedPeriods(newValue);
  };

  const handleProvinceChange = (event) => {
    const newValue = event.target.value;
    setSelectedProvince(newValue);
    setIsSelectedProvince(true);
  };

  const handleCityChange = (event) => {
    const newValue = event.target.value;
    setSelectedCity(newValue);
    setIsSelectedCity(true);
  };

  const handleDistrictChange = (event) => {
    const newValue = event.target.value;
    setSelectedDistrict(newValue);
    setIsSelectedDistrict(true);
  };

  const handleSubdistrictChange = (event) => {
    const newValue = event.target.value;
    setSelectedSubdistrict(newValue);
    setIsSelectedSubDistrict(true);
  };

  useEffect(() => {
    if (selectedProvince) {
      dispatch(resetCitiesToInit());
      dispatch(startLoadingCities());
      getCities(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedProvince
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setCitiesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingCities());
        } else {
          dispatch(setCitiesMessage("Data kosong"));
          dispatch(stopLoadingCities());
        }
      });
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (roles === "Super Admin" || roles === "Admin Cetak") {
      if (selectedCity) {
        dispatch(resetDistrictsToInit());
        dispatch(startLoadingDistricts());
        getDistricts(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          "",
          selectedCity
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setDistrictsList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingDistricts());
          } else {
            dispatch(setDistrictsMessage("Data kosong"));
            dispatch(stopLoadingDistricts());
          }
        });
      }
    } else {
      dispatch(resetDistrictsToInit());
      dispatch(startLoadingDistricts());
      getDistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        cityId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setDistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingDistricts());
        } else {
          dispatch(setDistrictsMessage("Data kosong"));
          dispatch(stopLoadingDistricts());
        }
      });
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedDistrict) {
      dispatch(resetSubdistrictsToInit());
      dispatch(startLoadingSubDistricts());
      getSubdistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedDistrict
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setSubdistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingSubDistricts());
        } else {
          dispatch(setSubdistrictsMessage("Data kosong"));
          dispatch(stopLoadingSubDistricts());
        }
      });
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (email === "hyperadmin@yasaons.com") {
      dispatch(resetPeriodsToInit());
      dispatch(startLoadingPeriods());
      getPeriods(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setPeriodsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingPeriods());
        } else {
          dispatch(setPeriodsMessage("Data kosong"));
          dispatch(stopLoadingPeriods());
        }
      });
    }
  }, []);

  const filterData = () => {
    setSearch("");
    if (roles === "Super Admin" || roles === "Admin Cetak") {
      getData(
        Constant.defaultPageNumber,
        Constant.defaultPageSize,
        "",
        periodsId,
        selectedDate,
        selectedProvince ||
          (location.state &&
            location.state.isFiltered.isProvinceSelected &&
            location.state.row.provinces.province_id) ||
          0,
        selectedCity ||
          (location.state &&
            location.state.isFiltered.isCitySelected &&
            location.state.row.cities.city_id) ||
          0,
        selectedDistrict ||
          (location.state &&
            location.state.isFiltered.isDistrictSelected &&
            location.state.row.districts.district_id) ||
          0,
        selectedSubdistrict ||
          (location.state &&
            location.state.isFiltered.isSubDistrictSelected &&
            location.state.row.subdistricts.subdistrict_id) ||
          0
      );
    } else {
      getData(
        Constant.defaultPageNumber,
        Constant.defaultPageSize,
        "",
        periodsId,
        selectedDate,
        provinceId,
        cityId,
        selectedDistrict ||
          (location.state &&
            location.state.isFiltered.isDistrictSelected &&
            location.state.row.districts.district_id) ||
          0,
        selectedSubdistrict ||
          (location.state &&
            location.state.isFiltered.isSubDistrictSelected &&
            location.state.row.subdistricts.subdistrict_id) ||
          0
      );
    }
  };

  const formikGenerate = useFormik({
    initialValues: { periode_id: periodsId, subdistrict_id: 0 },
    onSubmit: async () => {
      setGenerateProgress(0);
      setGenerateLoading(true);
      setGenerateMessagesOnError("");
      setGenerateMessagesOnSuccess("");
      var formData = new FormData();
      formData.append("periode_id", periodsId);
      formData.append("subdistrict_id", selectedSubdistrict.toString());
      await axios
        .post(
          "https://api.yasaons.com/api/auth/pbps/generate-bnba-fe",
          formData,
          {
            onUploadProgress(progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setGenerateProgress(percentCompleted);
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (data.code === 0) {
            setGenerateMessagesOnError("Gagal Generate BNBA dan SJ");
            formikGenerate.resetForm();
          } else if (data.code === 1) {
            setGenerateMessagesOnSuccess("Berhasil Generate BNBA dan SJ");
            formikGenerate.resetForm();
            setShowGenerate(false);
            // filterData();
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          if (message.bnba === "data exists" && message.sj === "data exists") {
            setGenerateMessagesOnErrorBNBA(
              "Terjadi kesalahan: Data BNBA Sudah Ada"
            );
            setGenerateMessagesOnErrorSJ(
              "Terjadi kesalahan:  Data BNBA Sudah Ada"
            );
          } else {
            setGenerateMessagesOnError("Terjadi kesalahan: Gagal Generate");
          }

          formikGenerate.resetForm();
          setGenerateLoading(false);
          setGenerateProgress(0);
        })
        .finally(() => setGenerateLoading(false));
    },
    validationSchema: yup.object({
      subdistrict_id: yup.string().required("ID Kelurahan wajib diisi"),
    }),
  });

  const [showInvitation, setShowInvitation] = useState(null);
  const [invitationData, setInvitationData] = useState([]);

  const isButtonGenerateDisabled = () => {
    const currentDateTime = new Date();
    const currentHour = currentDateTime.getUTCHours() + 7;
    if (roles !== "Super Admin") {
      return !(currentHour >= 18 && currentHour < 24)
    } else {
      return false;
    }
  };

  const componentUndanganRef = useRef();
  const handlePrintInvitation = useReactToPrint({
    content: () => componentUndanganRef.current,
    pageStyle: `
@page {
  margin: 6mm 0mm 17mm 0mm;
}`,
  });

  const handleOnClickDelete = (type, id) => {
    const result = window.confirm("Yakin Ingin Menghapus?");
    if (result) {
      deleteImage({ type: type, pbps_id: id })
        .then((response) => {
          console.log("response", response);
          window.location.reload();
        })
        .catch((error) => {
          alert("Gagal menghapus gambar" + error);
        });
    }
  };

  async function handleApprove(id) {
    const data = { verval_id: id, action: "approve" };
    setLoading(true);
    try {
      const res = await postApprove(data);
      if (res) {
        setMessage("Sukses Approve data");
        setLoading(false);
        setShowModal(false);
        getData(
          Constant.defaultPageNumber,
          Constant.defaultPageSize,
          search ?? "",
          periodsId,
          selectedDate,
          provinceId,
          cityId,
          selectedDistrict,
          selectedSubdistrict
        );
      }
    } catch (error) {
      setMessage("Gagal Approve data");
      setLoading(false);
    }
  }

  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary mb-2 mb-md-0">
          {section === "images"
            ? "Daftar Foto"
            : `Daftar Verval (${rData.totalCount}) `}
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}
          >
            <i className="fa fa-refresh"></i>
          </Button>
        </h6>
        {/* <Button
          className="btn-icon-split float-right mb-2 mb-md-0"
          onClick={handleDownloadTemplateButtonClick}
        >
          <span className="icon text-white-50">
            <i className="fas fa-add"></i>
          </span>
          <span className="text">Download Template</span>
        </Button> */}
        {/* <Button
          className="btn-icon-split float-right mr-2"
          onClick={() => handleShowPrintInvitation()}
          disabled={invitationLoading}
        >
          <span className="icon text-white-50">
            {invitationLoading ? <Spinner variant="light" animation="border" size="sm"></Spinner> : <i className="fas fa-add"></i>}
          </span>
          <span className="text">Print Undangan</span>
        </Button> */}
        {section === "" && email === "hyperadmin@yasaons.com" && (
          <Button
            className="btn-icon-split float-right mr-2"
            onClick={() => setShowUpload(true)}
          >
            <span className="icon text-white-50">
              <i className="fas fa-add"></i>
            </span>
            <span className="text">Import</span>
          </Button>
        )}
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            {/* <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control as="select" custom onChange={handlePeriodsChange} value={selectedPeriods} required>

                  <option value={0} disabled>{periodsLoading ? 'Loading...' : 'Pilih Periode'}</option>
                  {
                    periodsData.list.map((item, i) => {
                      return <option value={item.id} key={`periods-${i}`}>{item.id} ({item.month} - {item.year})</option>
                    })}
                </Form.Control>
              </Form.Group> */}
            {/* <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                type="date"
                custom
                className="form-control"
                value={selectedDate} // Menampilkan nilai dari state pada input date
                onChange={handleDateChange} //
              ></Form.Control>
            </Form.Group> */}
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                as="select"
                custom
                value={selectedProvince}
                disabled={
                  (roles !== "Super Admin" && roles !== "Admin Cetak") ||
                  rData.loading
                }
                onChange={handleProvinceChange}
              >
                <option value={0} disabled>
                  {provincesLoading
                    ? "Loading..."
                    : location.state &&
                      location.state.isFiltered?.isProvinceSelected
                    ? location.state.row.provinces.province_name
                    : roles !== "Super Admin" && roles !== "Admin Cetak"
                    ? provinceName
                    : "Pilih Provinsi"}
                </option>
                {provincesData.list.map((item, i) => {
                  return (
                    <option value={item.province_id} key={`provinces-${i}`}>
                      {item.province_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col
            md={6}
            className="d-none d-md-block"
            // className={`d-none ${section !== "verval" && "d-md-block"}`}
          >
            <InputGroup>
              <Form.Control
                placeholder="Cari"
                aria-label="Search"
                aria-describedby="basic-search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <Button
                variant="dark"
                className="rounded-0 rounded-right mr-2"
                id="button-search"
                onClick={handleSearch}
              >
                Cari
              </Button>
            </InputGroup>
          </Col>
          <Col md={6}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                as="select"
                custom
                value={selectedCity}
                disabled={
                  (roles !== "Super Admin" && roles !== "Admin Cetak") ||
                  rData.loading
                }
                onChange={handleCityChange}
              >
                <option value={0} disabled>
                  {citiesLoading
                    ? "Loading..."
                    : location.state &&
                      location.state.isFiltered?.isCitySelected
                    ? location.state.row.cities.city_name
                    : roles !== "Super Admin" && roles !== "Admin Cetak"
                    ? cityName
                    : "Pilih Kota"}
                </option>

                {citiesData.list.map((item, i) => {
                  return (
                    <option value={item.city_id} key={`cities-${i}`}>
                      {item.city_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                as="select"
                custom
                value={selectedDistrict}
                onChange={handleDistrictChange}
                disabled={rData.loading}
              >
                <option value={0} disabled>
                  {districtsLoading
                    ? "Loading..."
                    : location.state &&
                      location.state.isFiltered?.isDistrictSelected
                    ? location.state.row.districts.district_name
                    : "Pilih Kecamatan"}
                </option>

                {districtsData.list.map((item, i) => {
                  return (
                    <option value={item.district_id} key={`districts-${i}`}>
                      {item.district_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                as="select"
                custom
                value={selectedSubdistrict}
                onChange={handleSubdistrictChange}
                disabled={rData.loading}
              >
                <option value={0} disabled>
                  {subdistrictsLoading
                    ? "Loading..."
                    : location.state &&
                      location.state.isFiltered?.isSubDistrictSelected
                    ? location.state.row.subdistricts.subdistrict_name
                    : "Pilih Kelurahan"}
                </option>

                {subdistrictsData.list.map((item, i) => {
                  return (
                    <option
                      value={item.subdistrict_id}
                      key={`subdistricts-${i}`}
                    >
                      {item.subdistrict_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Button
              className="float-right d-none d-md-block"
              type="primary"
              onClick={() => filterData()}
              disabled={rData.loading}
            >
              {rData.loading ? "Loading..." : "Filter"}
            </Button>
            <Button
              className="float-right mb-3 d-md-none"
              type="primary"
              onClick={() => filterData()}
              disabled={rData.loading}
            >
              {rData.loading ? "Loading..." : "Filter"}
            </Button>
            <div
              // className={`${section === "verval" ? "d-none" : "d-md-none"}`}
              className="d-md-none"
            >
              <InputGroup>
                <Form.Control
                  placeholder="Cari"
                  aria-label="Search"
                  aria-describedby="basic-search"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <Button
                  variant="dark"
                  className="rounded-0 rounded-right"
                  id="button-search"
                  onClick={handleSearch}
                >
                  Cari
                </Button>
              </InputGroup>
            </div>
          </Col>

          <Col md={6}>
            {location?.pathname === "/pbp" && (
              <>
                <Button
                  className="mt-3 mt-md-0 float-right"
                  type="primary"
                  onClick={() =>
                    isButtonGenerateDisabled() === false
                      ? setShowGenerate(true)
                      : setShowWarningGenerate(true)
                  }
                >
                  Generate BNBA/SJ
                </Button>
                <Toast
                  autohide
                  show={warningGenerate}
                  onClose={() => setShowWarningGenerate(false)}
                >
                  <Toast.Header>
                    <strong className="me-auto">Perhatian!!!</strong>
                  </Toast.Header>
                  <Toast.Body>
                    Generate BNBA/SJ tidak bisa dilakukan pada Pukul 06.00 -
                    12.00 WIB
                  </Toast.Body>
                </Toast>
              </>
            )}
          </Col>
        </Row>

        <Row className="my-3">
          <Col className="col-12">
            {generateMessagesOnSuccess !== "" && (
              <Alert variant="success">{generateMessagesOnSuccess}</Alert>
            )}
          </Col>
        </Row>
        <Row className="my-3">
          <Col className="col-12">
            {message !== "" && (
              <Alert
                className={message.startsWith("Sukses") ? "d-block" : "d-none"}
                variant={message.startsWith("Sukses") ? "success" : "danger"}
              >
                {message}
              </Alert>
            )}
          </Col>
        </Row>

        <div className="table-responsive mt-5">
          {isLoading && rData.list.length === 0 ? (
            <Spinner
              animation="border"
              variant="warning"
              className="d-flex mx-auto"
            ></Spinner>
          ) : (
            <DataTable
              // selectableRows={true}
              onSelectedRowsChange={handleRowSelection}
              paginationPerPage={Constant.defaultPageNumber}
              paginationRowsPerPageOptions={
                Constant.paginationRowsPerPageOptions
              }
              columns={columns}
              data={rData.list}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationTotalRows={rData.totalCount}
              className="table table-bordered"
              pagination
              paginationServer
              progressPending={isLoading}
              onChangePage={handlePageChange}
            ></DataTable>
          )}
        </div>
      </Card.Body>
      <ConfirmationModal
        buttonNegative="Batal"
        buttonPositive="Print"
        title="Konfirmasi Print Undangan"
        show={showInvitation}
        body={"Apakah anda yakin?"}
        onNegative={() => setShowInvitation(false)}
        onPositive={handlePrintInvitation}
      />
      <ConfirmationModal
        buttonNegative="Batal"
        buttonPositive="Hapus"
        title="Konfirmasi Hapus"
        show={showDelete}
        body={"Apakah anda yakin?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
      <Modal
        size="lg"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail Perubahan Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="my-3">
            <Col className="col-12">
              {message !== "" && (
                <Alert
                  variant={message.startsWith("Sukses") ? "success" : "danger"}
                >
                  {message}
                </Alert>
              )}
            </Col>
          </Row>
          <Row>
            <Col>REG NUMBER</Col>
            <Col>: {detail?.request_data?.reg_number}</Col>
          </Row>
          <Row>
            <Col>NAMA</Col>
            <Col>: {detail?.request_data?.kpm_name}</Col>
          </Row>
          <Row>
            <Col>UMUR</Col>
            <Col>: {detail?.request_data?.kpm_age}</Col>
          </Row>
          <Row>
            <Col>NIK: </Col>
            <Col>: {detail?.request_data?.kpm_reg_number}</Col>
          </Row>
          <Row>
            <Col>ALAMAT</Col>
            <Col>: {detail?.request_data?.address}</Col>
          </Row>
          <Row>
            <Col>RT</Col>
            <Col>: {detail?.request_data?.rt}</Col>
          </Row>
          <Row>
            <Col>RW</Col>
            <Col>: {detail?.request_data?.rw}</Col>
          </Row>
          <Row>
            <Col>PROVINSI</Col>
            <Col>: {detail?.request_data?.provinces?.province_name}</Col>
          </Row>
          <Row>
            <Col>KAB/KOT</Col>
            <Col>: {detail?.request_data?.cities?.city_name}</Col>
          </Row>
          <Row>
            <Col>KECAMATAN</Col>
            <Col>: {detail?.request_data?.districts?.district_name}</Col>
          </Row>
          <Row>
            <Col>KELURAHAN</Col>
            <Col>: {detail?.request_data?.subdistricts?.subdistrict_name}</Col>
          </Row>
          <Toast
            autohide
            show={warningGenerate}
            onClose={() => setShowWarningGenerate(false)}
          >
            <Toast.Header>
              <strong className="me-auto">Perhatian!!!</strong>
            </Toast.Header>
            <Toast.Body>
              Approval hanya bisa dilakukan pada Pukul 18:00 - 23:59 WIB
            </Toast.Body>
          </Toast>
          <Button
            className="mt-5"
            onClick={() =>
              isButtonGenerateDisabled() === false
                ? handleApprove(detail?.id)
                : setShowWarningGenerate(true)
            }
            disabled={detail?.status === "approve"}
          >
            {loading && (
              <Spinner
                className="mr-2"
                animation="border"
                role="status"
                size="sm"
              ></Spinner>
            )}
            {detail?.status !== "approve" ? "Approve" : "Sudah diapprove"}
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        centered
        show={showGenerate}
        onHide={() => setShowGenerate(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate BNBA/SJ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center align-items-center">
            <Col md={6}>
              {generateMessagesOnError !== "" && (
                <Alert variant="danger">{generateMessagesOnError}</Alert>
              )}
              {generateMessagesOnErrorBNBA !== "" && (
                <Alert variant="danger">{generateMessagesOnErrorBNBA}</Alert>
              )}
              {generateMessagesOnErrorSJ !== "" && (
                <Alert variant="danger">{generateMessagesOnErrorSJ}</Alert>
              )}
              <Form onSubmit={formikGenerate.handleSubmit}>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Control
                    as="select"
                    custom
                    value={selectedProvince}
                    disabled={
                      (roles !== "Super Admin" && roles !== "Admin Cetak") ||
                      rData.loading
                    }
                    onChange={handleProvinceChange}
                  >
                    <option value={0} disabled>
                      {provincesLoading
                        ? "Loading..."
                        : roles !== "Super Admin" && roles !== "Admin Cetak"
                        ? provinceName
                        : "Pilih Provinsi"}
                    </option>
                    {provincesData.list.map((item, i) => {
                      return (
                        <option value={item.province_id} key={`provinces-${i}`}>
                          {item.province_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Control
                    as="select"
                    custom
                    value={selectedCity}
                    disabled={
                      (roles !== "Super Admin" && roles !== "Admin Cetak") ||
                      rData.loading
                    }
                    onChange={handleCityChange}
                  >
                    <option value={0} disabled>
                      {citiesLoading
                        ? "Loading..."
                        : roles !== "Super Admin" && roles !== "Admin Cetak"
                        ? cityName
                        : "Pilih Kota"}
                    </option>
                    {citiesData.list.map((item, i) => {
                      return (
                        <option value={item.city_id} key={`cities-${i}`}>
                          {item.city_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Control
                    as="select"
                    custom
                    value={selectedDistrict}
                    onChange={handleDistrictChange}
                    disabled={generateLoading}
                  >
                    <option value={0} disabled>
                      {districtsLoading ? "Loading..." : "Pilih Kecamatan"}
                    </option>
                    {districtsData.list.map((item, i) => {
                      return (
                        <option value={item.district_id} key={`districts-${i}`}>
                          {item.district_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Control
                    as="select"
                    custom
                    value={selectedSubdistrict}
                    onChange={handleSubdistrictChange}
                    disabled={generateLoading}
                  >
                    <option value={0} disabled>
                      {subdistrictsLoading ? "Loading..." : "Pilih Kelurahan"}
                    </option>
                    {subdistrictsData.list.map((item, i) => {
                      return (
                        <option
                          value={item.subdistrict_id}
                          key={`subdistricts-${i}`}
                        >
                          {item.subdistrict_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Button
                    type="submit"
                    className="float-right mb-3"
                    variant="primary"
                    disabled={generateLoading || selectedSubdistrict === 0}
                  >
                    {generateLoading && (
                      <Spinner
                        variant="light"
                        animation="border"
                        size="sm"
                        className="mr-1"
                      ></Spinner>
                    )}
                    Generate
                  </Button>

                  {/* <ProgressBar
                    height="10px"
                    labelSize="8px"
                    completed={generateProgress}
                    bgColor="#CC7C0E"
                  />
                  <div className="text-primary font-weight-bold mt-1">
                    Progress ({generateProgress}%)
                  </div> */}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        buttonNegative="Batal"
        buttonPositive="Selesai"
        title="Upload PBP"
        show={showUpload}
        body={
          <Card>
            {message ? (
              <Col md={12}>
                <Alert variant={isSuccess === false ? "danger" : "info"}>
                  {message}
                </Alert>
              </Col>
            ) : null}
            <Card.Header>Upload File</Card.Header>
            <Card.Body>
              <Form onSubmit={formikFile.handleSubmit}>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Control
                    as="select"
                    custom
                    onChange={handlePeriodsChange}
                    value={selectedPeriods}
                    required
                  >
                    <option value={0} disabled>
                      {periodsLoading ? "Loading..." : "Pilih Periode"}
                    </option>
                    {periodsData.list.map((item, i) => {
                      return (
                        <option value={item.id} key={`periods-${i}`}>
                          {item.id} ({item.month} - {item.year})
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control
                    type="file"
                    onChange={(event: React.ChangeEvent) => {
                      const dFile = event.target as HTMLInputElement;
                      if (dFile.files && dFile.files[0]) {
                        formikFile.setFieldValue(
                          "fileData",
                          Array.from(dFile.files)
                        );
                      }
                    }}
                    onBlur={formikFile.handleBlur}
                    isInvalid={
                      !!formikFile.touched.fileData &&
                      !!formikFile.errors.fileData
                    }
                    isValid={
                      !!formikFile.touched.fileData &&
                      !formikFile.errors.fileData
                    }
                  />
                  {formikFile.errors.fileData && (
                    <Form.Control.Feedback type="invalid">
                      {formikFile.errors.fileData}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group>
                  <Button
                    type="submit"
                    className="float-right"
                    variant="primary"
                    disabled={
                      loading || formikFile.values.fileData.length === 0
                    }
                  >
                    {loading && (
                      <Spinner
                        className="mr-2"
                        animation="border"
                        role="status"
                        size="sm"
                      ></Spinner>
                    )}
                    Upload
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        }
        onNegative={() => setShowUpload(false)}
        onPositive={() => window.location.reload()}
      />
      <div className="" style={{ display: "none" }}>
        <ComponentUndangan ref={componentUndanganRef} data={invitationData} />
      </div>
    </Card>
  );
};
