import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { setPeriodsMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addPeriods, updatePeriods } from "services/periodsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { useNavigate } from "react-router";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string;
    index?: string
};
export const PeriodsForm: React.FC<Props> = ({ row, hideShowForm, getData, action, index }) => {
    const dispatch = useAppDispatch();
    const iValue = { month: '', year: '' };
    const initialValue = action === 'edit' ? row : iValue;
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            setLoading(true)
            if (action === 'edit') {
                const response = await updatePeriods(values);
                if (response && response.data && response.data.code === 1) {
                    setLoading(false)
                    dispatch(setPeriodsMessage("Berhasil mengubah data"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setPeriodsMessage("Terjadi kesalahan"));
                    setLoading(false)
                }
            } else if (action === 'add') {
                const response = await addPeriods(values);
                if (response && response.data && response.data.code === 1) {
                    setLoading(false)
                    dispatch(setPeriodsMessage("Berhasil menambah data"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    // hideShowForm('');
                    navigate('/periods')
                } else {
                    dispatch(setPeriodsMessage("Terjadi kesalahan"));
                    setLoading(false)
                }
            }
        },
        validationSchema: yup.object({
            month: yup.string().required('Bulan wajib diisi'),
            year: yup.string().required('Tahun wajib diisi'),
            // jenjang: yup.string().required(),
            // status: yup.string().required(),
            // created_by: yup.string().nullable(),
            // updated_by: yup.string().nullable(),
            // created_at: yup.date().required('created_at is required'),
            // updated_at: yup.date().required('updated_at is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action === 'add' ? 'Tambah' : 'Ubah'} Periode
                    {/* <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Periods</span>
                    </Button> */}
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    {action === 'edit' && <Form.Group>
                        <label className="form -control-label">Periode</label>
                        <Form.Control type="text" className="form-control" value={formik.values.id} disabled
                        ></Form.Control>
                    </Form.Group>}
                    {/* {action === 'edit' && <Form.Group>
                        <label className="form -control-label">ID</label>
                        <Form.Control type="text" className="form-control" value={formik.values.id} disabled
                        ></Form.Control>
                    </Form.Group>} */}

                    {/* <Form.Group>
                        <label className="form -control-label">Nama Tahap</label>
                        <Form.Control type="text" name="tahap_name" className="form-control" value={formik.values.tahap_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.tahap_name && !!formik.errors.tahap_name}
                            isValid={!!formik.touched.tahap_name && !formik.errors.tahap_name}
                        ></Form.Control>
                        {
                            formik.errors.tahap_name && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.tahap_name}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
                    <Form.Group>
                        <label className="form -control-label">Bulan</label>
                        <Form.Control type="text" name="month" className="form-control" value={formik.values.month}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.month && !!formik.errors.month}
                            isValid={!!formik.touched.month && !formik.errors.month}
                        ></Form.Control>
                        {
                            formik.errors.month && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.month}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">Tahun</label>
                        <Form.Control type="text" name="year" className="form-control" value={formik.values.year}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.year && !!formik.errors.year}
                            isValid={!!formik.touched.year && !formik.errors.year}
                        ></Form.Control>
                        {
                            formik.errors.year && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.year}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    {/* <Form.Group>
                        <label className="form -control-label">Periode</label>
                        <Form.Control type="text" name="periode" className="form-control" value={formik.values.periode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.periode && !!formik.errors.periode}
                            isValid={!!formik.touched.periode && !formik.errors.periode}
                        ></Form.Control>
                        {
                            formik.errors.periode && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.periode}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">Jenjang</label>
                        <Form.Control type="text" name="jenjang" className="form-control" value={formik.values.jenjang}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.jenjang && !!formik.errors.jenjang}
                            isValid={!!formik.touched.jenjang && !formik.errors.jenjang}
                        ></Form.Control>
                        {
                            formik.errors.jenjang && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.jenjang}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">Status</label>
                        <Form.Control type="text" name="status" className="form-control" value={formik.values.status}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.status && !!formik.errors.status}
                            isValid={!!formik.touched.status && !formik.errors.status}
                        ></Form.Control>
                        {
                            formik.errors.status && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.status}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
                    {/* <Form.Group>
                        <label className="form -control-label">created_by</label>
                        <Form.Control type="text" name="created_by" className="form-control" value={formik.values.created_by}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.created_by && !!formik.errors.created_by}
                            isValid={!!formik.touched.created_by && !formik.errors.created_by}
                        ></Form.Control>
                        {
                            formik.errors.created_by && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.created_by}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">updated_by</label>
                        <Form.Control type="text" name="updated_by" className="form-control" value={formik.values.updated_by}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.updated_by && !!formik.errors.updated_by}
                            isValid={!!formik.touched.updated_by && !formik.errors.updated_by}
                        ></Form.Control>
                        {
                            formik.errors.updated_by && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.updated_by}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">created_at</label>
                        <Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.created_at && !!formik.errors.created_at}
                            isValid={!!formik.touched.created_at && !formik.errors.created_at}
                        ></Form.Control>
                        {
                            formik.errors.created_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.created_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">updated_at</label>
                        <Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.updated_at && !!formik.errors.updated_at}
                            isValid={!!formik.touched.updated_at && !formik.errors.updated_at}
                        ></Form.Control>
                        {
                            formik.errors.updated_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.updated_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary"disabled={loading}>{loading && <Spinner variant="light" animation="border" size="sm" className="mr-1"></Spinner>}Simpan</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

