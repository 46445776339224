import { APIService } from "services";

export const getDistricts = async (pageNo, pageSize, search, id) => {
    let res;
    if (search.length === 0) {
        res = await getAllDistricts(pageNo, pageSize, id);
    }
    else {
        try {
            res = await searchDistricts(search, pageNo, pageSize);
        } catch (err) {
            return { records: [], total_count: 0 }
        }
    }
    if (res && res.data) {
        return { records: res.data.data, total_count: 0 }
    } else {
        return { records: [], totalCount: 0 }
    }

}


export const addDistricts = (data) => {
    return APIService.api().post(`/list-districts`, data)
}
export const updateDistricts = (data) => {
    return APIService.api().put(`/list-districts/${data.district_id}`, data)
}
export const getAllDistricts = (page, paginator, id) => {
    return APIService.api().get(`/districts?city_id=${id}`)
}
export const getOneDistricts = (district_id) => {
    return APIService.api().get(`/list-districts/${district_id}`)
}
export const searchDistricts = (searchKey, page, paginator) => {
    return APIService.api().get(`/list-districts/search/${searchKey}`)
}
export const deleteDistricts = (district_id) => {
    return APIService.api().delete(`/list-districts/${district_id}`)
}
