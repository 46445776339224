import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { useAppDispatch } from "redux/store";
import * as yup from 'yup';
import { uploadImageService, uploadMultipleKPMSImageService } from "services/fileUploadService";
import { resetProvincesToInit, startLoadingProvinces, setProvincesList, stopLoadingProvinces, setProvincesMessage, resetCitiesToInit, startLoadingCities, setCitiesList, stopLoadingCities, setCitiesMessage, resetDistrictsToInit, startLoadingDistricts, setDistrictsList, stopLoadingDistricts, setDistrictsMessage, resetSubdistrictsToInit, startLoadingSubDistricts, setSubdistrictsList, stopLoadingSubDistricts, setSubdistrictsMessage } from "redux/actions";
import roles from "redux/slices/roles";
import { getCities } from "services/citiesService";
import { getDistricts } from "services/districtsService";
import { getProvinces } from "services/provincesService";
import { getSubdistricts } from "services/subdistrictsService";
import { Constant } from "template/Constant";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
export const KpmsUploadImages: React.FC = ({ }) => {
    const dispatch = useAppDispatch();
    const initialValue = { image: [] };
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [selectedProvince, setSelectedProvince] = useState(0);
    const [selectedCity, setSelectedCity] = useState(0);
    const [selectedDistrict, setSelectedDistrict] = useState(0);
    const [selectedSubdistrict, setSelectedSubdistrict] = useState(0);
    const provincesData = useSelector((state: RootState) => state.provinces);
    const citiesData = useSelector((state: RootState) => state.cities);
    const districtsData = useSelector((state: RootState) => state.districts);
    const subdistrictsData = useSelector((state: RootState) => state.subdistricts);
    const provincesLoading = useSelector((state: RootState) => state.provinces.loading);
    const citiesLoading = useSelector((state: RootState) => state.cities.loading);
    const districtsLoading = useSelector((state: RootState) => state.districts.loading);
    const subdistrictsLoading = useSelector((state: RootState) => state.subdistricts.loading);
    const periodsId = localStorage.getItem('periode_id')
    const provinceId = localStorage.getItem('province_id')
    const cityId = localStorage.getItem('city_id')
    const roles = localStorage.getItem('user')
    const provinceName = localStorage.getItem('province_name')
    const cityName = localStorage.getItem('city_name')
    const [error, setError] = useState(false)
    const handleProvinceChange = (event) => {
        const newValue = event.target.value;
        setSelectedProvince(newValue);
    };

    const handleCityChange = (event) => {
        const newValue = event.target.value;
        setSelectedCity(newValue);
    };

    const handleDistrictChange = (event) => {
        const newValue = event.target.value;
        setSelectedDistrict(newValue);
    };

    const handleSubdistrictChange = (event) => {
        const newValue = event.target.value;
        setSelectedSubdistrict(newValue);
    };
    useEffect(() => {
        if (provincesData && provincesData.list && provincesData.list.length === 0 && roles === 'Super Admin') {
            dispatch(resetProvincesToInit());
            dispatch(startLoadingProvinces())
            getProvinces(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                    dispatch(setProvincesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingProvinces())
                } else {
                    dispatch(setProvincesMessage("Data kosong"));
                    dispatch(stopLoadingProvinces())
                }
            })

        }
    }, [])
    useEffect(() => {
        if (selectedProvince) {
            dispatch(resetCitiesToInit());
            dispatch(startLoadingCities())
            getCities(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', selectedProvince).then((response) => {
                if (response && response.records) {
                    dispatch(setCitiesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingCities())
                } else {
                    dispatch(setCitiesMessage("Data kosong"));
                    dispatch(stopLoadingCities())
                }
            })
        }
    }, [selectedProvince]);

    useEffect(() => {
        if (roles === 'Super Admin') {
            if (selectedCity) {
                dispatch(resetDistrictsToInit());
                dispatch(startLoadingDistricts())
                getDistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', selectedCity).then((response) => {
                    if (response && response.records) {
                        dispatch(setDistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                        dispatch(stopLoadingDistricts())
                    } else {
                        dispatch(setDistrictsMessage("Data kosong"));
                        dispatch(stopLoadingDistricts())
                    }
                })
            }
        } else {
            dispatch(resetDistrictsToInit());
            dispatch(startLoadingDistricts())
            getDistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', cityId).then((response) => {
                if (response && response.records) {
                    dispatch(setDistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingDistricts())
                } else {
                    dispatch(setDistrictsMessage("Data kosong"));
                    dispatch(stopLoadingDistricts())
                }
            })
        }

    }, [selectedCity]);

    useEffect(() => {
        if (selectedDistrict) {
            dispatch(resetSubdistrictsToInit());
            dispatch(startLoadingSubDistricts())
            getSubdistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', selectedDistrict).then((response) => {
                if (response && response.records) {
                    dispatch(setSubdistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingSubDistricts())
                } else {
                    dispatch(setSubdistrictsMessage("Data kosong"));
                    dispatch(stopLoadingSubDistricts())
                }
            })
        }
    }, [selectedDistrict]);

    const formikImage = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            setLoading(true)
            var formData = new FormData();
            values.image.forEach((file,) => {
                formData.append(`image[]`, file);
            });
            formData.append(`periode_id`, periodsId);
            formData.append(`subdistrict_id`, selectedSubdistrict.toString());

            uploadMultipleKPMSImageService(formData).then((res: any) => {
                if (res && res.data && res.data.success) {
                    setMessage('Sukses mengupload foto penerima');
                    formikImage.resetForm();
                    setError(false)
                    setLoading(false)
                }
            }).catch((e => {
                setError(true)
                setMessage(e.data.message)
                setLoading(false)
            }))

        },
        validationSchema: yup.object({
            image: yup.array().min(1, "Pilh minimal 1 foto penerima")
        }),
    });


    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">Upload Foto PBP</h6>
            </Card.Header>
            <Card.Body>
                {message ? <Col md={24}><Alert variant={error ? 'danger' : 'success'}>{message}</Alert></Col> : null}
                <Form onSubmit={formikImage.handleSubmit} className="w-50">
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Control as="select" custom value={selectedProvince} disabled={roles !== 'Super Admin'}
                            onChange={handleProvinceChange}>
                            <option value={0} disabled>{provincesLoading ? 'Loading...' : roles !== 'Super Admin' ? provinceName : 'Pilih Provinsi'}</option>
                            {
                                provincesData.list.map((item, i) => {
                                    return <option value={item.province_id} key={`provinces-${i}`}>{item.province_name}</option>
                                })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Control as="select" custom value={selectedCity} disabled={roles !== 'Super Admin'}
                            onChange={handleCityChange}>
                            <option value={0} disabled>{citiesLoading ? 'Loading...' : roles !== 'Super Admin' ? cityName : 'Pilih Kota'}</option>
                            {
                                citiesData.list.map((item, i) => {
                                    return <option value={item.city_id} key={`cities-${i}`}>{item.city_name}</option>
                                })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Control as="select" custom value={selectedDistrict}
                            onChange={handleDistrictChange}>
                            <option value={0} disabled>{districtsLoading ? 'Loading...' : 'Pilih Kecamatan'}</option>
                            {
                                districtsData.list.map((item, i) => {
                                    return <option value={item.district_id} key={`districts-${i}`}>{item.district_name}</option>
                                })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Control as="select" custom value={selectedSubdistrict}
                            onChange={handleSubdistrictChange}>
                            <option value={0} disabled>{subdistrictsLoading ? 'Loading...' : 'Pilih Kelurahan'}</option>
                            {
                                subdistrictsData.list.map((item, i) => {
                                    return <option value={item.subdistrict_id} key={`subdistricts-${i}`}>{item.subdistrict_name}</option>
                                })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload Foto Penerima</Form.Label>
                        <Form.Control type="file"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const imgFile = event.target.files;
                                if (imgFile) {
                                    formikImage.setFieldValue("image", Array.from(imgFile));
                                }
                            }}
                            onBlur={formikImage.handleBlur}
                            isInvalid={!!formikImage.touched.image && !!formikImage.errors.image}
                            isValid={!!formikImage.touched.image && !formikImage.errors.image}
                            multiple
                        />
                        <br />
                        {formikImage.values.image.length > 0 && (
                            <div>
                                <p>Foto dipilih:</p>
                                <ul>
                                    {formikImage.values.image.map((file: File, index: number) => (
                                        <li>{index} - {file.name}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {
                            formikImage.errors.image && (
                                <Form.Control.Feedback type="invalid">
                                    {formikImage.errors.image}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <Button type="submit" variant="primary">{loading && <Spinner animation="border" variant="light" style={{ width: '15px', height: '15px', fontSize: '10px' }}></Spinner>}Upload</Button>
                    </Form.Group>
                </Form>

            </Card.Body>
        </Card>
    );
}

