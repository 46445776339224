import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "components/auth";
import { FileUpload } from "components/upload";
import { NotFound } from "./404";
import {
  Warehouses,
  Vehicles,
  Users,
  Subdistricts,
  Shipments,
  Queuings,
  Provinces,
  Periods,
  Kpms,
  Drivers,
  Districts,
  Cities,
  DashboardV3,
  Sj,
  Sptjm,
  Invitations,
  NomenklaturProv,
  NomenklaturKabkot,
  NomenklaturKecamatan,
  NomenklaturKelurahan,
  VervalApprove,
  ExportLog,
  DownloadVervalImages,
} from "components";
import { Reports } from "components/reports";
import IndexPeriode from "components/period";
import { DownloadBulkyImages } from "components/shipments/download-bulky-images";
import { ImportPBP } from "components/kpms/import";
const Pages: React.FC = () => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const periodeId = localStorage.getItem("periode_id");
  const roles = localStorage.getItem("user");
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            isLoggedIn === "true" &&
            periodeId !== "null" &&
            roles === "Super Admin" ? (
              <Kpms section="" />
            ) : isLoggedIn === "true" &&
              periodeId !== "null" &&
              roles === "Admin / Juru Serah" ? (
              <Shipments section="" />
            ) : isLoggedIn === "true" &&
              periodeId !== "null" &&
              roles === "BOD" ? (
              <DashboardV3 />
            ) : (
              <Login />
            )
          }></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route
          path="/dashboard"
          element={<AuthenticatedRoute element={<DashboardV3 />} />}></Route>
        <Route
          path="/upload"
          element={<AuthenticatedRoute element={<FileUpload />} />}></Route>
        <Route
          path="/warehouses"
          element={
            <AuthenticatedRoute element={<Warehouses section="" />} />
          }></Route>
        <Route
          path="/warehouses/add"
          element={
            <AuthenticatedRoute element={<Warehouses section="add" />} />
          }></Route>
        <Route
          path="/vehicles"
          element={
            <AuthenticatedRoute element={<Vehicles section="" />} />
          }></Route>
        <Route
          path="/vehicles/add"
          element={
            <AuthenticatedRoute element={<Vehicles section="add" />} />
          }></Route>
        <Route
          path="/users"
          element={
            <AuthenticatedRoute element={<Users section="" />} />
          }></Route>
        <Route
          path="/users/add"
          element={
            <AuthenticatedRoute element={<Users section="add" />} />
          }></Route>
        <Route
          path="/subdistricts"
          element={<AuthenticatedRoute element={<Subdistricts />} />}></Route>
        <Route
          path="/bnba"
          element={
            <AuthenticatedRoute element={<Shipments section="" />} />
          }></Route>
        <Route
          path="/bnba/import"
          element={
            <AuthenticatedRoute element={<Shipments section="" />} />
          }></Route>
        <Route
          path="/bnba/add"
          element={
            <AuthenticatedRoute element={<Shipments section="add" />} />
          }></Route>
        <Route
          path="/bnba/print"
          element={
            <AuthenticatedRoute element={<Shipments section="print" />} />
          }></Route>
        <Route
          path="/sj"
          element={<AuthenticatedRoute element={<Sj section="" />} />}></Route>
        <Route
          path="/sj/add"
          element={
            <AuthenticatedRoute element={<Sj section="add" />} />
          }></Route>
        <Route
          path="/sptjm"
          element={
            <AuthenticatedRoute element={<Sptjm section="" />} />
          }></Route>
        <Route
          path="/sptjm/add"
          element={
            <AuthenticatedRoute element={<Sptjm section="add" />} />
          }></Route>
        <Route
          path="/queuings"
          element={
            <AuthenticatedRoute element={<Queuings section="" />} />
          }></Route>
        <Route
          path="/queuings/add"
          element={
            <AuthenticatedRoute element={<Queuings section="add" />} />
          }></Route>
        <Route
          path="/provinces"
          element={<AuthenticatedRoute element={<Provinces />} />}></Route>
        <Route
          path="/periods"
          element={
            <AuthenticatedRoute element={<Periods section="" />} />
          }></Route>
        <Route
          path="/periods/add"
          element={
            <AuthenticatedRoute element={<Periods section="add" />} />
          }></Route>
        <Route
          path="/pbp"
          element={
            <AuthenticatedRoute element={<Kpms section="" />} />
          }></Route>
        <Route
          path="/pbp/add"
          element={
            <AuthenticatedRoute element={<Kpms section="add" />} />
          }></Route>
        <Route
          path="/verval"
          element={
            <AuthenticatedRoute element={<Kpms section="verval" />} />
          }></Route>
        <Route
          path="/verval/add"
          element={
            <AuthenticatedRoute element={<Kpms section="add" />} />
          }></Route>
        <Route
          path="/verval/export"
          element={<AuthenticatedRoute element={<ExportLog />} />}></Route>
        <Route
          path="/verval/download/images"
          element={
            <AuthenticatedRoute element={<DownloadVervalImages />} />
          }></Route>

        <Route
          path="/nomenklatur/add"
          element={
            <AuthenticatedRoute element={<Kpms section="add" />} />
          }></Route>
        <Route
          path="/nomenklatur/provinsi"
          element={
            <AuthenticatedRoute element={<NomenklaturProv />} />
          }></Route>
        <Route
          path="/nomenklatur/kabkot"
          element={
            <AuthenticatedRoute element={<NomenklaturKabkot />} />
          }></Route>
        <Route
          path="/nomenklatur/kecamatan"
          element={
            <AuthenticatedRoute element={<NomenklaturKecamatan />} />
          }></Route>
        <Route
          path="/nomenklatur/kelurahan"
          element={
            <AuthenticatedRoute element={<NomenklaturKelurahan />} />
          }></Route>
        <Route
          path="/verval/approve"
          element={<AuthenticatedRoute element={<VervalApprove />} />}></Route>
        <Route
          path="/chooseperiod"
          element={
            <AuthenticatedRoute element={<IndexPeriode section="" />} />
          }></Route>

        <Route
          path="/pbp/upload-images"
          element={
            <AuthenticatedRoute element={<Kpms section="upload-images" />} />
          }></Route>
        <Route
          path="/pbp/import"
          element={
            <AuthenticatedRoute element={<ImportPBP />} />
          }></Route>
        <Route
          path="/drivers"
          element={
            <AuthenticatedRoute element={<Drivers section="" />} />
          }></Route>
        <Route
          path="/drivers/add"
          element={
            <AuthenticatedRoute element={<Drivers section="add" />} />
          }></Route>
        <Route
          path="/districts"
          element={<AuthenticatedRoute element={<Districts />} />}></Route>
        <Route
          path="/cities"
          element={<AuthenticatedRoute element={<Cities />} />}></Route>
        <Route
          path="/reports"
          element={<AuthenticatedRoute element={<Reports />} />}></Route>
        <Route
          path="/dashboard-images"
          element={
            <AuthenticatedRoute element={<Kpms section="images" />} />
          }></Route>
        <Route
          path="/invitations"
          element={
            <AuthenticatedRoute element={<Invitations section="" />} />
          }></Route>
           <Route
          path="/download-bulky-images"
          element={
            <AuthenticatedRoute element={<DownloadBulkyImages/>} />
          }></Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
