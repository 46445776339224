import Layout from "template";
import { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  resetProvincesToInit,
  startLoadingProvinces,
  setProvincesList,
  stopLoadingProvinces,
  setProvincesMessage,
  resetCitiesToInit,
  startLoadingCities,
  setCitiesList,
  stopLoadingCities,
  setCitiesMessage,
  resetDistrictsToInit,
  startLoadingDistricts,
  setDistrictsList,
  stopLoadingDistricts,
  setDistrictsMessage,
  resetSubdistrictsToInit,
  startLoadingSubDistricts,
  setSubdistrictsList,
  stopLoadingSubDistricts,
  setSubdistrictsMessage,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { getCities } from "services/citiesService";
import { getDistricts } from "services/districtsService";
import { getProvinces } from "services/provincesService";
import { getSubdistricts } from "services/subdistrictsService";
import { Constant } from "template/Constant";
import { useAppDispatch } from "redux/store";
import { downloadBulkyImages } from "services/kpmsService";
export const DownloadBulkyImages = () => {
  const provincesData = useSelector((state: RootState) => state.provinces);
  const citiesData = useSelector((state: RootState) => state.cities);
  const districtsData = useSelector((state: RootState) => state.districts);
  const subdistrictsData = useSelector(
    (state: RootState) => state.subdistricts
  );
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const citiesLoading = useSelector((state: RootState) => state.cities.loading);
  const districtsLoading = useSelector(
    (state: RootState) => state.districts.loading
  );
  const subdistrictsLoading = useSelector(
    (state: RootState) => state.subdistricts.loading
  );
  const [selectedProvince, setSelectedProvince] = useState(0);
  const [selectedCity, setSelectedCity] = useState(0);
  const [selectedDistrict, setSelectedDistrict] = useState(0);
  const [selectedSubdistrict, setSelectedSubdistrict] = useState(0);
  const [selectedProvinceName, setSelectedProvinceName] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedDistrictName, setSelectedDistrictName] = useState("");
  const [selectedSubdistrictName, setSelectedSubdistrictName] = useState("");
  const periodeId = localStorage.getItem("periode_id");
  const provinceId = localStorage.getItem("province_id");
  const cityId = localStorage.getItem("city_id");
  const roles = localStorage.getItem("user");
  const provinceName = localStorage.getItem("province_name");
  const cityName = localStorage.getItem("city_name");
  const [loading, setLoading] = useState(undefined);
  const [message, setMessage] = useState("");
  const dispatch = useAppDispatch();

  const handleProvinceChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);

    if (selectedValue) {
      const { province_id, province_name } = selectedValue;
      setSelectedProvince(province_id);
      setSelectedProvinceName(province_name);
    }
  };

  const handleCityChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);

    if (selectedValue) {
      const { city_id, city_name } = selectedValue;
      setSelectedCity(city_id);
      setSelectedCityName(city_name);
    }
  };

  const handleDistrictChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);

    if (selectedValue) {
      const { district_id, district_name } = selectedValue;
      setSelectedDistrict(district_id);
      setSelectedDistrictName(district_name);
    }
  };

  const handleSubdistrictChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);

    if (selectedValue) {
      const { subdistrict_id, subdistrict_name } = selectedValue;
      setSelectedSubdistrict(subdistrict_id);
      setSelectedSubdistrictName(subdistrict_name);
    }
  };

  useEffect(() => {
    if (
      provincesData &&
      provincesData.list &&
      provincesData.list.length === 0 &&
      roles === "Super Admin"
    ) {
      dispatch(resetProvincesToInit());
      dispatch(startLoadingProvinces());
      getProvinces(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setProvincesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingProvinces());
        } else {
          dispatch(setProvincesMessage("Data kosong"));
          dispatch(stopLoadingProvinces());
        }
      });
    }
  }, []);
  useEffect(() => {
    if (selectedProvince) {
      dispatch(resetCitiesToInit());
      dispatch(startLoadingCities());
      getCities(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedProvince
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setCitiesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingCities());
        } else {
          dispatch(setCitiesMessage("Data kosong"));
          dispatch(stopLoadingCities());
        }
      });
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (roles === "Super Admin") {
      if (selectedCity) {
        dispatch(resetDistrictsToInit());
        dispatch(startLoadingDistricts());
        getDistricts(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          "",
          selectedCity
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setDistrictsList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingDistricts());
          } else {
            dispatch(setDistrictsMessage("Data kosong"));
            dispatch(stopLoadingDistricts());
          }
        });
      }
    } else {
      dispatch(resetDistrictsToInit());
      dispatch(startLoadingDistricts());
      getDistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        cityId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setDistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingDistricts());
        } else {
          dispatch(setDistrictsMessage("Data kosong"));
          dispatch(stopLoadingDistricts());
        }
      });
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedDistrict) {
      dispatch(resetSubdistrictsToInit());
      dispatch(startLoadingSubDistricts());
      getSubdistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedDistrict
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setSubdistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingSubDistricts());
        } else {
          dispatch(setSubdistrictsMessage("Data kosong"));
          dispatch(stopLoadingSubDistricts());
        }
      });
    }
  }, [selectedDistrict]);
  async function onDownload() {
    setLoading(true);
    const formData = new FormData();
    formData.append("subdistrict_id", selectedSubdistrict.toString());
    formData.append("periode_id", periodeId);
    try {
      const response = await downloadBulkyImages(formData);
      if (response) {
        setLoading(false);
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        if (roles === "Super Admin") {
          link.setAttribute(
            "download",
            `${selectedProvinceName} - ${selectedCityName} - ${selectedDistrictName} - ${selectedSubdistrictName}.zip`
          );
        } else {
          link.setAttribute(
            "download",
            `${provinceName} - ${cityName} - ${selectedDistrictName} - ${selectedSubdistrictName}.zip`
          );
        }
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }
  return (
    <Layout>
      <div className="container-fluid">
        {message && (
          <Alert
            variant={Constant.defaultAlertVarient}
            className="alert-dismissible fade"
          >
            {message}
          </Alert>
        )}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Download Foto Massal</h1>
        </div>
        <div className="w-50">
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Control
              as="select"
              custom
              value={JSON.stringify({
                province_id: selectedProvince,
                province_name: selectedProvinceName,
              })}
              disabled={roles !== "Super Admin" || loading}
              onChange={handleProvinceChange}
            >
              <option
                value={JSON.stringify({ province_id: 0, province_name: "" })}
                disabled
              >
                {provincesLoading
                  ? "Loading..."
                  : roles !== "Super Admin"
                  ? provinceName
                  : "Pilih Provinsi"}
              </option>
              {provincesData.list.map((item, i) => {
                return (
                  <option
                    value={JSON.stringify({
                      province_id: item.province_id,
                      province_name: item.province_name,
                    })}
                    key={`provinces-${i}`}
                  >
                    {item.province_name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Control
              as="select"
              custom
              value={JSON.stringify({
                city_id: selectedCity,
                city_name: selectedCityName,
              })}
              disabled={roles !== "Super Admin" || loading}
              onChange={handleCityChange}
            >
              <option
                value={JSON.stringify({ city_id: 0, city_name: "" })}
                disabled
              >
                {citiesLoading
                  ? "Loading..."
                  : roles !== "Super Admin"
                  ? cityName
                  : "Pilih Kota"}
              </option>
              {citiesData.list.map((item, i) => {
                return (
                  <option
                    value={JSON.stringify({
                      city_id: item.city_id,
                      city_name: item.city_name,
                    })}
                    key={`cities-${i}`}
                  >
                    {item.city_name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Control
              as="select"
              custom
              value={JSON.stringify({
                district_id: selectedDistrict,
                district_name: selectedDistrictName,
              })}
              onChange={handleDistrictChange}
              disabled={loading}
            >
              <option
                value={JSON.stringify({
                  district_id: 0,
                  district_name: "",
                })}
                disabled
              >
                {districtsLoading ? "Loading..." : "Pilih Kecamatan"}
              </option>
              {districtsData.list.map((item, i) => {
                return (
                  <option
                    value={JSON.stringify({
                      district_id: item.district_id,
                      district_name: item.district_name,
                    })}
                    key={`districts-${i}`}
                  >
                    {item.district_name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Control
              as="select"
              custom
              value={JSON.stringify({
                subdistrict_id: selectedSubdistrict,
                subdistrict_name: selectedSubdistrictName,
              })}
              onChange={handleSubdistrictChange}
              disabled={loading}
            >
              <option
                value={JSON.stringify({
                  subdistrict_id: 0,
                  subdistrict_name: "",
                })}
                disabled
              >
                {subdistrictsLoading ? "Loading..." : "Pilih Kelurahan"}
              </option>
              {subdistrictsData.list.map((item, i) => {
                return (
                  <option
                    value={JSON.stringify({
                      subdistrict_id: item.subdistrict_id,
                      subdistrict_name: item.subdistrict_name,
                    })}
                    key={`subdistricts-${i}`}
                  >
                    {item.subdistrict_name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Button
            onClick={onDownload}
            className="float-right"
            disabled={!selectedDistrict || !selectedSubdistrict || loading}
          >
            {loading ? "Loading..." : "Download"}
          </Button>
        </div>
      </div>
    </Layout>
  );
};
