import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_indonesia from "@amcharts/amcharts5-geodata/indonesiaHigh";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const MapComponent = (props) => {
  const { data, loading } = props;
  useLayoutEffect(() => {
    let root = am5.Root.new("mapdiv");

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        projection: am5map.geoNaturalEarth1(),
      })
    );

    if (loading) {
      let indicator = root.container.children.push(
        am5.Container.new(root, {
          width: am5.p100,
          height: am5.p100,
          layer: 1000,
          background: am5.Rectangle.new(root, {
            fill: am5.color(0xffffff),
            fillOpacity: 0.7,
          }),
        })
      );
      let hourglass = indicator.children.push(
        am5.Graphics.new(root, {
          width: 32,
          height: 32,
          fill: am5.color(0x000000),
          x: am5.p50,
          y: am5.p50,
          centerX: am5.p50,
          centerY: am5.p50,
          dy: -45,
          svgPath:
            "M12 5v10l9 9-9 9v10h24V33l-9-9 9-9V5H12zm20 29v5H16v-5l8-8 8 8zm-8-12-8-8V9h16v5l-8 8z",
        })
      );
      var hourglassanimation = hourglass.animate({
        key: "rotation",
        to: 180,
        loops: Infinity,
        duration: 2000,
        easing: am5.ease.inOut(am5.ease.cubic),
      });
      indicator.children.push(
        am5.Label.new(root, {
          text: "Loading...",
          fontSize: 25,
          x: am5.p50,
          y: am5.p50,
          centerX: am5.p50,
          centerY: am5.p50,
        })
      );
      hourglassanimation.play();
    }
    const indonesiaData = JSON.parse(JSON.stringify(am5geodata_indonesia));
    if (data && data.list && data.list.data) {
      if (
        indonesiaData.features[25] &&
        indonesiaData.features[25].id === "ID-SA" &&
        data.list.data[6]?.province_code_fe === "ID-SA"
      ) {
        indonesiaData.features[25].properties.data = data?.list?.data[6];
      }
      if (
        indonesiaData.features[5] &&
        indonesiaData.features[5].id === "ID-GO" &&
        data.list.data[0]?.province_code_fe === "ID-GO"
      ) {
        indonesiaData.features[5].properties.data = data?.list?.data[0];
      }
      // if (indonesiaData.features[8] && indonesiaData.features[8].id === 'ID-JI' && data.list.data[2].name === 'Jawa Timur') {
      //   indonesiaData.features[8].properties.data = data?.list?.data[2]
      // }
      if (
        indonesiaData.features[22] &&
        indonesiaData.features[22].id === "ID-PA" &&
        data.list.data[5]?.province_code_fe === "ID-PA"
      ) {
        indonesiaData.features[22].properties.data = data?.list?.data[5];
      }
      if (
        indonesiaData.features[23] &&
        indonesiaData.features[23].id === "ID-PB" &&
        data.list.data[1]?.province_code_fe === "ID-PB"
      ) {
        indonesiaData.features[23].properties.data = data?.list?.data[1];
      }
    }
    // indonesiaData.features.forEach((feature) => {
    //   feature.properties.population = 1000000; // Ganti dengan nilai yang sesuai
    // });

    // Create polygon series
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: indonesiaData,
      })
    );

    let pointSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        latitudeField: "lat",
        longitudeField: "long",
      })
    );
    pointSeries.bullets.push(function () {
      var circle = am5.Circle.new(root, {
        radius: 5,
        fill: am5.color(0xebc43a),
        tooltipText: "{name}\nTotal Pengiriman : {jumlah}",
      });

      return am5.Bullet.new(root, {
        sprite: circle,
      });
    });
    if (data && data.list && data.list.data) {
      pointSeries.data.setAll([
        {
          long: 136.56555,
          lat: -4.54357,
          name: "Papua Tengah",
          jumlah: data?.list?.data[3]?.percentage_selesai
            ? data?.list?.data[3]?.percentage_selesai + "%"
            : 0 + "%",
        },
        {
          long: 138.91667,
          lat: -4.08333,
          name: "Papua Pegunungan",
          jumlah: data?.list?.data[4]?.percentage_selesai
            ? data?.list?.data[4]?.percentage_selesai + "%"
            : 0 + "%",
        },
        {
          long: 139.68194,
          lat: -7.69611,
          name: "Papua Selatan",
          jumlah: data?.list?.data[2]?.percentage_selesai
            ? data?.list?.data[2]?.percentage_selesai + "%"
            : 0 + "%",
        },
      ]);

      polygonSeries.mapPolygons.template.setAll({
        tooltipText: "{name}\nTotal Pengiriman : {data.percentage_selesai}%",
        interactive: true,
        templateField: "polygonSettings",
      });

      polygonSeries.data.setAll([
        {
          id: "ID-SA",
          polygonSettings: {
            fill: am5.color(0xa23e48),
          },
        },
        {
          id: "ID-GO",
          polygonSettings: {
            fill: am5.color(0xa23e48),
          },
        },
        {
          id: "ID-JI",
          polygonSettings: {
            fill: am5.color(0xa23e48),
          },
        },
        {
          id: "ID-PA",
          polygonSettings: {
            fill: am5.color(0xa23e48),
          },
        },
        {
          id: "ID-PB",
          polygonSettings: {
            fill: am5.color(0xa23e48),
          },
        },
      ]);
    }

    // polygonSeries.mapPolygons.template.states.create("hover", {
    //   fill: am5.color(0xebc43a),
    // });

    root.current = root;

    return () => {
      root.dispose();
    };
  }, [loading]);

  return (
    <div id="mapdiv">
      <h3 className="font-weight-bold pt-4 pl-4" style={{ color: "#040D12" }}>
        Lokasi penyerahan paket
      </h3>
    </div>
  );
};

export default MapComponent;
