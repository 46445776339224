import setting from "./../logos/logo-pengaturan.svg";

const user = localStorage.getItem("user");

export const MenuItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: "fas fa-fw fa-tachometer-alt",
    logopath: "../../logos/logo-dashboard.svg",
    hidden:
      user === "Admin / Juru Serah" ||
      user === "Admin Cetak" ||
      user === "Admin STPJM" ||
      user === "Admin Reset" ||
      user === "Admin Verval",
    subMenu: [],
  },
  {
    title: "PBP",
    path: "/pbp",
    icon: "fas fa-fw fa-boxes-stacked",
    logopath: "./../logos/logo-pbp.svg",
    hidden:
      user === "Admin / Juru Serah" ||
      user === "Admin Reset" ||
      user === "BOD" ||
      user === "Admin STPJM" ||
      user === "Admin Verval",
    subMenu: [
      {
        title: "Daftar PBP",
        path: "/pbp",
        icon: "fas fa-fw fa-table",
      },
      {
        title: "Upload Foto PBP",
        path: "/pbp/upload-images",
        icon: "fas fa-fw fa-upload",
        hidden: user === "Admin Cetak",
      },
      {
        title: "Import",
        path: "/pbp/import",
        icon: "fas fa-fw fa-upload",
        hidden:
          user === "Admin Cetak" ||
          user === "Developer" ||
          user === "Admin Reset",
      },
      // {
      //   title: "Tambah PBP",
      //   path: "/pbp/add",
      //   icon: "fas fa-fw fa-plus",
      // },
    ],
  },
  {
    title: "VERVAL",
    path: "/verval",
    icon: "fas fa-fw fa-boxes-stacked",
    logopath: "./../logos/logo-pbp.svg",
    hidden:
      user === "Admin Reset" ||
      user === "Admin / Juru Serah" ||
      user === "BOD" ||
      user === "Admin STPJM",
    subMenu: [
      {
        title: "Daftar Verval",
        path: "/verval",
        icon: "fas fa-fw fa-table",
      },
      {
        title: "Tambah Verval",
        path: "/verval/add",
        icon: "fas fa-fw fa-plus",
      },
      {
        title: "Approve Verval",
        path: "/verval/approve",
        icon: "fas fa-fw fa-edit",
      },
      {
        title: "Export Log Verval",
        path: "/verval/export",
        icon: "fas fa-fw fa-file-text",
      },
    ],
  },
  {
    title: "Nomenklatur",
    path: "/Nomenklatur",
    icon: "fas fa-fw fa-boxes-stacked",
    logopath: "./../logos/logo-pbp.svg",
    hidden:
      user === "Admin Reset" ||
      user === "Admin / Juru Serah" ||
      user === "BOD" ||
      user === "Admin STPJM" ||
      user === "Admin Verval",
    subMenu: [
      {
        title: "Tambah/Ubah Provinsi",
        path: "/nomenklatur/provinsi",
        icon: "fas fa-fw fa-edit",
      },
      {
        title: "Tambah/Ubah Kota",
        path: "/nomenklatur/kabkot",
        icon: "fas fa-fw fa-edit",
      },
      {
        title: "Tambah/Ubah Kecamatan",
        path: "/nomenklatur/kecamatan",
        icon: "fas fa-fw fa-edit",
      },
      {
        title: "Tambah/Ubah Kelurahan",
        path: "/nomenklatur/kelurahan",
        icon: "fas fa-fw fa-edit",
      },
    ],
  },
  {
    title: "Periode",
    path: "/periods",
    icon: "fas fa-fw fa-calendar",
    logopath: "./../logos/logo-periode.svg",
    hidden:
      user === "Admin / Juru Serah" ||
      user === "BOD" ||
      user === "Admin Cetak" ||
      user === "Admin STPJM" ||
      user === "Admin Reset" ||
      user === "Admin Verval",
    subMenu: [
      {
        title: "Daftar Periode",
        path: "/periods",
        icon: "fas fa-fw fa-table",
      },
      {
        title: "Tambah Periode",
        path: "/periods/add",
        icon: "fas fa-fw fa-plus",
      },
    ],
  },
  {
    title: "Surat Jalan",
    path: "/sj",
    icon: "fas fa-fw fa-boxes-stacked",
    logopath: "../../logos/logo-sj.svg",
    hidden:
      user === "BOD" ||
      user === "Admin Cetak" ||
      user === "Admin STPJM" ||
      user === "Admin Reset" ||
      user === "Admin Verval",
    subMenu: [
      {
        title: "Daftar Surat Jalan",
        path: "/sj",
        icon: "fas fa-fw fa-table",
      },
      // {
      //   title: "Tambah Surat Jalan",
      //   path: "/sj/add",
      //   icon: "fas fa-fw fa-plus",
      // },
    ],
  },
  {
    title: "BNBA",
    path: "/bnba",
    icon: "fas fa-fw fa-truck-fast",
    hidden:
      user === "Admin Reset" ||
      user === "BOD" ||
      user === "Admin STPJM" ||
      user === "Admin Verval",
    logopath: "./../logos/logo-bnba.svg",
    subMenu: [
      {
        title: "Daftar BNBA",
        path: "/bnba",
        icon: "fas fa-fw fa-table",
      },
      // {
      //   title: "Tambah BNBA",
      //   path: "/bnba/add",
      //   icon: "fas fa-fw fa-plus",
      // },
      {
        title: "Cetak BNBA",
        path: "/bnba/print",
        icon: "fas fa-fw fa-print",
        hidden: user === "Admin Cetak",
      },
      {
        title: "Import BNBA",
        path: "/bnba/import",
        icon: "fas fa-fw fa-upload",
      },
    ],
  },
  {
    title: "Undangan",
    path: "/bnba",
    hidden:
      user === "BOD" ||
      user === "Admin Cetak" ||
      user === "Admin Reset" ||
      user === "Admin STPJM" ||
      user === "Admin Verval",
    logopath: "../../logos/logo-undangan.svg",
    subMenu: [
      {
        title: "Cetak Undangan",
        path: "/invitations",
        icon: "fas fa-fw fa-print",
      },
    ],
  },
  {
    title: "Dashboard Foto",
    path: "/dashboard-images",
    icon: "fas fa-fw fa-images",
    logopath: "./../logos/logo-data-foto.svg",
    hidden:
      user === "Admin / Juru Serah" ||
      // user === "BOD" ||
      user === "Admin Cetak" ||
      user === "Admin STPJM" ||
      user === "Admin Verval",
    subMenu: [
      {
        title: "Daftar Foto",
        path: "/dashboard-images",
        icon: "fas fa-fw fa-images",
        hidden: user === "Admin / Juru Serah",
      },
      {
        title: "Download Foto",
        path: "/verval/download/images",
        icon: "fas fa-fw fa-image",
        hidden: user === "Admin / Juru Serah" || user === "BOD",
      },
      {
        title: "Download Foto Massal",
        path: "/download-bulky-images",
        icon: "fas fa-fw fa-image",
        hidden: user === "BOD",
      },
    ],
  },
  // {
  //   title: "Gudang",
  //   path: "/warehouses",
  //   icon: "fas fa-fw fa-warehouse",
  //   hidden: user === 'BOD',
  //   subMenu: [
  //     {
  //       title: "Daftar Gudang",
  //       path: "/warehouses",
  //       icon: "fas fa-fw fa-table",
  //     },
  //     {
  //       title: "Tambah Gudang",
  //       path: "/warehouses/add",
  //       icon: "fas fa-fw fa-plus",
  //     },
  //   ],
  // },
  // {
  //   title: "Kendaraan",
  //   path: "/vehicles",
  //   icon: "fas fa-fw fa-truck-moving",
  //   hidden: user === 'BOD',
  //   subMenu: [
  //     {
  //       title: "Daftar Kendaraan",
  //       path: "/vehicles",
  //       icon: "fas fa-fw fa-table",
  //     },
  //     {
  //       title: "Tambah Kendaraan",
  //       path: "/vehicles/add",
  //       icon: "fas fa-fw fa-plus",
  //     },
  //   ],
  // },
  // {
  //   title: "Supir",
  //   path: "/drivers",
  //   icon: "fas fa-fw fa-user-astronaut",
  //   hidden: user === 'BOD',
  //   subMenu: [
  //     {
  //       title: "Daftar Supir",
  //       path: "/drivers",
  //       icon: "fas fa-fw fa-table",
  //     },
  //     {
  //       title: "Tambah Supir",
  //       path: "/drivers/add",
  //       icon: "fas fa-fw fa-plus",
  //     },
  //   ],
  // },
  {
    title: "SPTJM",
    path: "/sptjm",
    icon: "fas fa-fw fa-user-astronaut",
    logopath: "./../logos/logo-sptjm.svg",
    hidden:
      user === "BOD" ||
      user === "Admin Cetak" ||
      user === "Admin Reset" ||
      user === "Admin / Juru Serah" ||
      user === "Admin Verval",
    subMenu: [
      {
        title: "Daftar SPTJM",
        path: "/sptjm",
        icon: "fas fa-fw fa-table",
        hidden: user === "Admin / Juru Serah",
      },
      // {
      //   title: "Tambah STPJM",
      //   path: "/sptjm/add",
      //   icon: "fas fa-fw fa-plus",
      // },
    ],
  },
  {
    title: "Pengguna",
    path: "/users",
    icon: "fas fa-fw fa-user",
    logopath: "./../logos/logo-pengguna.svg",
    hidden:
      user === "Admin / Juru Serah" ||
      user === "BOD" ||
      user === "Admin Cetak" ||
      user === "Admin STPJM" ||
      user === "Admin Reset" ||
      user === "Admin Verval",
    subMenu: [
      {
        title: "Daftar Pengguna",
        path: "/users",
        icon: "fas fa-fw fa-table",
      },
      {
        title: "Tambah Pengguna",
        path: "/users/add",
        icon: "fas fa-fw fa-plus",
      },
    ],
  },
  //   {
  //     title: "File Upload",
  //     path: "/upload",
  //     icon: "fas fa-fw fa-upload",
  //     subMenu: [],
  //   },
  {
    title: "Laporan",
    path: "/reports",
    icon: "fas fa-fw fa-scroll",
    logopath: "./../logos/logo-laporan.svg",
    hidden:
      user === "Admin / Juru Serah" ||
      user === "Admin Cetak" ||
      user === "Admin Reset" ||
      user === "Admin STPJM" ||
      user === "Admin Verval",
    subMenu: [
      {
        title: "Lihat Laporan",
        path: "/reports",
        icon: "fas fa-fw fa-table",
      },
    ],
  },
  {
    title: "Pengaturan",
    path: "/settings",
    icon: "fa-fw fas fa-cog",
    logopath: setting,
    hidden:
      // user === "Admin / Juru Serah" ||
      // user === "Admin Cetak" ||
      // user === "Admin STPJM" ||
      // user === "Admin Verval",
      user !== "Developer",
    subMenu: [
      {
        title: "Atur Periode",
        path: "/chooseperiod",
        icon: "fa-fw fas fa-timeline",
      },
    ],
  },

  //   {
  //     title: "Subdistricts",
  //     path: "/subdistricts",
  //     icon: "fas fa-fw fa-table",
  //     subMenu: [],
  //   },

  // {
  //   title: "Antrian",
  //   path: "/queuings",
  //   icon: "fas fa-fw fa-user-group",
  //   hidden: user === 'BOD',
  //   subMenu: [
  //     {
  //       title: "Daftar Antrian",
  //       path: "/queuings",
  //       icon: "fas fa-fw fa-table",
  //     },
  //     {
  //       title: "Tambah Antrian",
  //       path: "/queuings/add",
  //       icon: "fas fa-fw fa-plus",
  //     },
  //   ],
  // },
  //   {
  //     title: "Provinces",
  //     path: "/provinces",
  //     icon: "fas fa-fw fa-table",
  //     subMenu: [],
  //   },

  //   {
  //     title: "Districts",
  //     path: "/districts",
  //     icon: "fas fa-fw fa-table",
  //     subMenu: [],
  //   },
  //   { title: "Cities", path: "/cities", icon: "fas fa-fw fa-table", subMenu: [] },
];
