import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import {
  setCitiesMessage,
  setDistrictsMessage,
  setKpmsMessage,
  setPeriodsMessage,
  setProvincesMessage,
  setShipmentsMessage,
  setSubdistrictsMessage,
  startLoadingCities,
  startLoadingDistricts,
  startLoadingPeriods,
  startLoadingProvinces,
  startLoadingShipments,
  startLoadingSubDistricts,
  stopLoadingCities,
  stopLoadingDistricts,
  stopLoadingPeriods,
  stopLoadingProvinces,
  stopLoadingShipments,
  stopLoadingSubDistricts,
} from "redux/actions";
import { resetPeriodsToInit, setPeriodsList } from "redux/actions";
import { resetProvincesToInit, setProvincesList } from "redux/actions";
import { resetCitiesToInit, setCitiesList } from "redux/actions";
import { resetDistrictsToInit, setDistrictsList } from "redux/actions";
import { resetSubdistrictsToInit, setSubdistrictsList } from "redux/actions";
import { resetShipmentsToInit, setShipmentsList } from "redux/actions";

import { getPeriods } from "services/periodsService";
import { getProvinces } from "services/provincesService";
import { getCities } from "services/citiesService";
import { getDistricts } from "services/districtsService";
import { getSubdistricts } from "services/subdistrictsService";
import { getShipments } from "services/shipmentsService";

import Swal from "sweetalert2";

import { useAppDispatch } from "redux/store";
import {
  addKpms,
  deleteImage,
  updateKpms,
  updateVerval,
} from "services/kpmsService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router";
import { uploadImageService } from "services/fileUploadService";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (
    page,
    pageSize,
    searchKey,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id
  ) => void;
  action?: string;
  isFiltered?: any;
  editAction?: string;
};
interface provinceState {
  id: string;
}
export const KpmsForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
  isFiltered,
  editAction,
}) => {
  const roles = localStorage.getItem("user");
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    document_id: 0,
    periode_id: 0,
    wilayah_id: 0,
    province_id: 0,
    city_id: 0,
    district_id: 0,
    subdistrict_id: 0,
    kpm_latitude: 0,
    kpm_longitude: 0,
    province_lat: 0,
    province_lng: 0,
    city_lat: 0,
    city_lng: 0,
    district_lat: 0,
    district_lng: 0,
    subdistrict_lat: 0,
    subdistrict_lng: 0,
    location_barcode: "",
    reg_number: "",
    kpm_name: "",
    address: "",
    kpm_barcode: "",
    created_by: "",
    updated_by: "",
    status: "",
    created_at: "",
    updated_at: "",
    handover_at: "",
    handover_by: "",
    photo_handover: "",
    photo_document: "",
    shipment_id: 0,
    bulk_handover: "",
    rt: "",
    rw: "",
    kpm_age: "",
  };
  const initialValue = action === "edit" || action === "detail" ? row : iValue;
  const periodsData = useSelector((state: RootState) => state.periods);
  const provincesData = useSelector((state: RootState) => state.provinces);
  const citiesData = useSelector((state: RootState) => state.cities);
  const districtsData = useSelector((state: RootState) => state.districts);
  const subdistrictsData = useSelector(
    (state: RootState) => state.subdistricts
  );
  const shipmentsData = useSelector((state: RootState) => state.shipments);
  const periodsLoading = useSelector(
    (state: RootState) => state.periods.loading
  );
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const citiesLoading = useSelector((state: RootState) => state.cities.loading);
  const districtsLoading = useSelector(
    (state: RootState) => state.districts.loading
  );
  const subdistrictsLoading = useSelector(
    (state: RootState) => state.subdistricts.loading
  );
  const shipmentsLoading = useSelector(
    (state: RootState) => state.shipments.loading
  );
  const periodsId = localStorage.getItem("periode_id");
  const provinceId = localStorage.getItem("province_id");
  const cityId = localStorage.getItem("city_id");
  const districtId = localStorage.getItem("district_id");
  const subdistrictId = localStorage.getItem("subdistrict_id");
  const initialState: provinceState = {
    id: null,
  };
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      setLoading(true);
      if (action === "edit") {
        const data = {
          province_id: values.province_id,
          city_id: values.city_id,
          district_id: values.district_id,
          subdistrict_id: values.subdistrict_id,
          kpm_reg_number: values.kpm_reg_number,
          kpm_name: values.kpm_name,
          kpm_age: values.kpm_age,
          address: values.address,
          rt: values.rt,
          rw: values.rw,
        };
        const dataUbahAlamat = {
          province_id: values.province_id,
          city_id: values.city_id,
          district_id: values.district_id,
          subdistrict_id: values.subdistrict_id,
        };
        const finalData = {
          periode_id: periodsId,
          type: "pbp",
          change_type: "move",
          data: data,
          affected_id: values.id,
        };
        const finalDataUbahAlamat = {
          periode_id: periodsId,
          type: "pbp",
          change_type: "update",
          data: dataUbahAlamat,
          affected_id: values.id,
        };
        if (location?.pathname === "/verval") {
          if (editAction === "ubah-alamat") {
            updateVerval(finalDataUbahAlamat)
              .then((response: any) => {
                if (response) {
                  setLoading(false);
                  dispatch(setKpmsMessage("Berhasil mengubah data"));
                  getData(
                    Constant.defaultPageNumber,
                    Constant.defaultPageSize,
                    "",
                    periodsId,
                    "",
                    provinceId,
                    cityId,
                    districtId,
                    subdistrictId
                  );
                  hideShowForm("");
                }
              })
              .catch((error) => {
                setLoading(false);
                formik.resetForm();
                dispatch(
                  setKpmsMessage(
                    error.data.message.error || "Terjadi kesalahan"
                  )
                );
              });
          } else {
            updateVerval(finalData)
              .then((response: any) => {
                if (response) {
                  setLoading(false);
                  dispatch(
                    setKpmsMessage("Berhasil mengajukan perubahan data")
                  );
                  getData(
                    Constant.defaultPageNumber,
                    Constant.defaultPageSize,
                    "",
                    periodsId,
                    "",
                    provinceId,
                    cityId,
                    districtId,
                    subdistrictId
                  );
                  hideShowForm("");
                }
              })
              .catch((error) => {
                setLoading(false);
                formik.resetForm();
                dispatch(
                  setKpmsMessage(
                    error.data.message.error || "Terjadi kesalahan"
                  )
                );
              });
          }
        } else {
          updateKpms(values)
            .then((response: any) => {
              if (response && response.data && response.data.code === 1) {
                setLoading(false);
                dispatch(setKpmsMessage("Berhasil mengubah data"));
                getData(
                  Constant.defaultPageNumber,
                  Constant.defaultPageSize,
                  "",
                  periodsId,
                  "",
                  provinceId,
                  cityId,
                  districtId,
                  subdistrictId
                );
                hideShowForm("");
              }
            })
            .catch((error) => {
              setLoading(false);
              formik.resetForm();
              dispatch(setKpmsMessage("Terjadi kesalahan"));
            });
        }
      } else if (action === "add") {
        const data = {
          province_id: values.province_id,
          city_id: values.city_id,
          district_id: values.district_id,
          subdistrict_id: values.subdistrict_id,
          kpm_reg_number: values.kpm_reg_number,
          kpm_name: values.kpm_name,
          kpm_age: values.kpm_age,
          address: values.address,
          rt: values.rt,
          rw: values.rw,
        };

        const finalData = {
          periode_id: periodsId,
          type: "pbp",
          change_type: "add",
          data: data,
        };
        if (location?.pathname === "/verval/add") {
          updateVerval(finalData)
            .then((response: any) => {
              if (response) {
                setLoading(false);
                dispatch(setKpmsMessage("Berhasil menambah data"));
                getData(
                  Constant.defaultPageNumber,
                  Constant.defaultPageSize,
                  "",
                  periodsId,
                  "",
                  provinceId,
                  cityId,
                  districtId,
                  subdistrictId
                );
                navigate("/verval");
              }
            })
            .catch((error) => {
              setLoading(false);
              formik.resetForm();
              dispatch(setKpmsMessage("Terjadi kesalahan"));
            });
        } else {
          updateKpms(values)
            .then((response: any) => {
              if (response && response.data && response.data.code === 1) {
                setLoading(false);
                dispatch(setKpmsMessage("Berhasil menambah data"));
                getData(
                  Constant.defaultPageNumber,
                  Constant.defaultPageSize,
                  "",
                  periodsId,
                  "",
                  provinceId,
                  cityId,
                  districtId,
                  subdistrictId
                );
                navigate("/pbp");
              }
            })
            .catch((error) => {
              setLoading(false);
              formik.resetForm();
              dispatch(setKpmsMessage("Terjadi kesalahan"));
            });
        }
      }
    },
    validationSchema: yup.object({
      document_id: yup.number().nullable(),
      wilayah_id: yup.number().nullable(),
      kpm_latitude: yup.number().nullable(),
      kpm_longitude: yup.number().nullable(),
      province_lat: yup.number().nullable(),
      province_lng: yup.number().nullable(),
      city_lat: yup.number().nullable(),
      city_lng: yup.number().nullable(),
      district_lat: yup.number().nullable(),
      district_lng: yup.number().nullable(),
      subdistrict_lat: yup.number().nullable(),
      subdistrict_lng: yup.number().nullable(),
      location_barcode: yup.string().nullable(),
      kpm_reg_number:
        editAction === "ubah-alamat"
          ? yup.string().nullable()
          : yup.string().required("NIK wajib diisi"),
      kpm_name:
        editAction === "ubah-alamat"
          ? yup.string().nullable()
          : yup.string().required("Nama PBP wajib diisi"),
      kpm_age:
        editAction === "ubah-alamat"
          ? yup.string().nullable()
          : yup.string().required("Umur PBP wajib diisi"),
      address:
        editAction === "ubah-alamat"
          ? yup.string().nullable()
          : yup.string().required("Alamat wajib diisi"),
      rt:
        editAction === "ubah-alamat"
          ? yup.string().nullable()
          : yup.string().required("RT wajib diisi"),
      rw:
        editAction === "ubah-alamat"
          ? yup.string().nullable()
          : yup.string().required("RW wajib diisi"),
      kpm_barcode: yup.string().nullable(),
      status: yup.string().nullable(),
      photo_handover: yup.string().nullable(),
      photo_document: yup.string().nullable(),
      bulk_handover: yup.string().nullable(),
      periode_id: yup.string().nullable(),
      province_id: yup.string().required("ID Provinsi wajib diisi"),
      city_id: yup.string().required("ID Kota wajib diisi"),
      district_id: yup.string().required("ID Kecamatan wajib diisi"),
      subdistrict_id: yup.string().required("ID Kelurahan wajib diisi"),
      shipment_id: yup.string().nullable(),
    }),
  });
  const formikProvinceId = formik.values.province_id;
  const formikCityId = formik.values.city_id;
  const formikDistrictId = formik.values.district_id;
  useEffect(() => {
    if (action === "edit" || action == "add") {
      if (periodsData && periodsData.list && periodsData.list.length === 0) {
        dispatch(resetPeriodsToInit());
        dispatch(startLoadingPeriods());
        getPeriods(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          ""
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setPeriodsList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingPeriods());
          } else {
            dispatch(setPeriodsMessage("Data kosong"));
            dispatch(stopLoadingPeriods());
          }
        });
      }
      if (
        provincesData &&
        provincesData.list &&
        provincesData.list.length === 0
      ) {
        dispatch(resetProvincesToInit());
        dispatch(startLoadingProvinces());
        getProvinces(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          ""
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setProvincesList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingProvinces());
          } else {
            dispatch(setProvincesMessage("Data kosong"));
            dispatch(stopLoadingProvinces());
          }
        });
      }
      if (
        shipmentsData &&
        shipmentsData.list &&
        shipmentsData.list.length === 0
      ) {
        dispatch(resetShipmentsToInit());
        dispatch(startLoadingShipments());
        getShipments(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          "",
          periodsId,
          "",
          provinceId,
          cityId,
          districtId,
          subdistrictId
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setShipmentsList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingShipments());
          } else {
            dispatch(setShipmentsMessage("Data kosong"));
            dispatch(stopLoadingShipments());
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (action === "edit" || action === "add") {
      if (formikProvinceId) {
        dispatch(resetCitiesToInit());
        dispatch(startLoadingCities());
        getCities(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          "",
          formikProvinceId
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setCitiesList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingCities());
          } else {
            dispatch(setCitiesMessage("Data kosong"));
            dispatch(stopLoadingCities());
          }
        });
      }
    }
  }, [formikProvinceId]);

  useEffect(() => {
    if (action === "edit" || action === "add") {
      if (formikCityId) {
        dispatch(resetDistrictsToInit());
        dispatch(startLoadingDistricts());
        getDistricts(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          "",
          formikCityId
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setDistrictsList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingDistricts());
          } else {
            dispatch(setDistrictsMessage("Data kosong"));
            dispatch(stopLoadingDistricts());
          }
        });
      }
    }
  }, [formikCityId]);

  useEffect(() => {
    if (action === "edit" || action === "add") {
      if (formikDistrictId) {
        dispatch(resetSubdistrictsToInit());
        dispatch(startLoadingSubDistricts());
        getSubdistricts(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          "",
          formikDistrictId
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setSubdistrictsList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingSubDistricts());
          } else {
            dispatch(setSubdistrictsMessage("Data kosong"));
            dispatch(stopLoadingSubDistricts());
          }
        });
      }
    }
  }, [formikDistrictId]);

  const formikImage = useFormik({
    initialValues: { image: [] },
    onSubmit: async (values) => {
      var formData = new FormData();
      formData.append("image[]", values.image[0]);
      formData.append("type", "kpms");
      formData.append("id", row.id);
      formData.append("description", "completed");
      formData.append("is_represented", false.toString());
      formData.append("receiver_name", null);
      formData.append("photo_type", "represent");

      uploadImageService(formData).then((res: any) => {
        if (res && res.data && res.data.success) {
          setMessage("Sukses mengupload foto penerima");
          formikImage.resetForm();
          setIsUpload(true);
        } else {
          setMessage("Gagal mengupload foto penerima");
          formikImage.resetForm();
          setIsUpload(false);
        }
      });
    },
    validationSchema: yup.object({
      image: yup.array().min(1, "Pilh 1 foto penerima"),
    }),
  });

  const resetForm = () => {
    formik.resetForm();
  };

  const [searchShipments, setSearchShipments] = useState("");
  const [filteredShipments, setFilteredShipments] = useState(
    shipmentsData.list
  );

  const handleSearchShipments = (e) => {
    const searchValue = e.target.value;
    setSearchShipments(searchValue);
    const filtered = shipmentsData.list.filter((item) =>
      item.document_id.toString().includes(searchValue)
    );
    setFilteredShipments(filtered);
  };

  const handleOnClickDelete = (type, id) => {
    if (roles === "BOD") {
      Swal.fire({ title: "Hubungi Admin untuk Menghapus", icon: "error" });
    } else {
      const result = window.confirm("Yakin Ingin Menghapus?");
      if (result) {
        deleteImage({ type: type, pbps_id: id })
          .then((response) => {
            hideShowForm("");
            dispatch(setKpmsMessage("Berhasil menghapus foto"));
            navigate("/dashboard-images", {
              state: { isFiltered: isFiltered, row: row },
            });
          })
          .catch((error) => {
            alert("Gagal menghapus gambar" + error);
          });
      }
    }
  };

  const handleBack = () => {
    if (location?.pathname === "/pbp") {
      navigate("/pbp", {
        state: { isFiltered: isFiltered, row: row },
      });
    } else if (location?.pathname === "/dashboard-images") {
      navigate("/dashboard-images", {
        state: { isFiltered: isFiltered, row: row },
      });
    } else if (location?.pathname === "/verval/add") {
      navigate("/verval");
    }
    hideShowForm("");
  };
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action === "add" ? "Tambah" : action === "edit" ? "Ubah" : "Lihat"}{" "}
          {editAction === "ubah-alamat"
            ? "Alamat PBP"
            : location?.pathname === "/verval/add"
            ? "Verval"
            : "PBP"}
          <Button className="btn-icon-split float-right" onClick={handleBack}>
            <span className="icon text-white-50">
              <i className="fas fa-arrow-left"></i>
            </span>
            <span className="text">Kembali</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        {action !== "view" &&
          action !== "detail" &&
          action !== "add" &&
          action !== "edit" && (
            <Form onSubmit={formikImage.handleSubmit}>
              {message ? (
                <Col md={12}>
                  <Alert variant={isUpload ? "info" : "danger"}>
                    {message}
                  </Alert>
                </Col>
              ) : null}
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload Foto Penerima</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(event: React.ChangeEvent) => {
                    const imgFile = event.target as HTMLInputElement;
                    if (imgFile.files && imgFile.files[0]) {
                      formikImage.setFieldValue(
                        "image",
                        Array.from(imgFile.files)
                      );
                    }
                  }}
                  onBlur={formikImage.handleBlur}
                  isInvalid={
                    !!formikImage.touched.image && !!formikImage.errors.image
                  }
                  isValid={
                    !!formikImage.touched.image && !formikImage.errors.image
                  }
                />
                {formikImage.errors.image && (
                  <Form.Control.Feedback type="invalid">
                    {formikImage.errors.image}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group>
                <Button type="submit" variant="primary">
                  Upload
                </Button>
              </Form.Group>
            </Form>
          )}

        {action === "detail" && (
          <div>
            <h5 className="font-weight-bold text-primary text-capitalize mb-4">
              {row?.reg_number}
            </h5>
            <h6
              className="font-weight-bold text-capitalize mb-5 p-2"
              style={{ backgroundColor: "#edd580", borderRadius: "2px" }}
            >
              Detil foto
            </h6>
            <Row>
              <Col>
                <div className="text-center mb-3 font-weight-bold">
                  Foto Barang Sampai
                </div>
                {row?.attachments?.attachment_arrived ? (
                  <>
                    <img
                      src={row?.attachments?.attachment_arrived}
                      className="d-flex mx-auto"
                      style={{ width: "50%" }}
                    />
                    {roles !== "BOD" && (
                      <div className="text-center mt-3">
                        <Button
                          variant="danger"
                          onClick={() => {
                            handleOnClickDelete(
                              "arrived",
                              row?.attachments?.pbps_id
                            );
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center">-</div>
                )}
              </Col>
              <Col>
                <div className="text-center mb-3 font-weight-bold">
                  Foto Diterima
                </div>
                {row?.attachments?.attachment_received ? (
                  <>
                    <img
                      src={row?.attachments?.attachment_received}
                      className="d-flex mx-auto"
                      style={{ width: "50%" }}
                    />
                    {roles !== "BOD" && (
                      <div className="text-center mt-3">
                        <Button
                          variant="danger"
                          onClick={() => {
                            handleOnClickDelete(
                              "received",
                              row?.attachments?.pbps_id
                            );
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center">-</div>
                )}
              </Col>
              <Col>
                <div className="text-center mb-3 font-weight-bold">
                  Foto Diwakilkan
                </div>
                {row?.attachments?.attachments_represent ? (
                  <>
                    <img
                      src={row?.attachments?.attachments_represent}
                      className="d-flex mx-auto"
                      style={{ width: "50%" }}
                    />
                    {roles !== "BOD" && (
                      <div className="text-center mt-3">
                        <Button
                          variant="danger"
                          onClick={() => {
                            handleOnClickDelete(
                              "represent",
                              row?.attachments?.pbps_id
                            );
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center">-</div>
                )}
              </Col>
            </Row>
            <h6
              className="font-weight-bold text-capitalize mb-4 p-2 mt-5"
              style={{ backgroundColor: "#edd580", borderRadius: "2px" }}
            >
              Detil PBP
            </h6>
            <Row>
              <Col xs={12} lg={6}>
                <Row>
                  <Col>Nama Penerima </Col>
                  <Col className="font-weight-bold">: {row?.kpm_name}</Col>
                </Row>
                <Row>
                  <Col>NIK</Col>
                  <Col className="font-weight-bold">
                    : {row?.kpm_reg_number}
                  </Col>
                </Row>
                <Row>
                  <Col>Alamat</Col>
                  <Col className="font-weight-bold">: {row?.address}</Col>
                </Row>
                <Row>
                  <Col>RT</Col>
                  <Col className="font-weight-bold">: {row?.rt ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>RW</Col>
                  <Col className="font-weight-bold">: {row?.rw ?? "-"}</Col>
                </Row>

                <Row>
                  <Col>Status</Col>
                  <Col className="font-weight-bold">: {row?.status}</Col>
                </Row>
              </Col>
              <Col xs={12} lg={6}>
                <Row>
                  <Col>Periode</Col>
                  <Col className="font-weight-bold">
                    : {row?.periods?.periode_name}
                  </Col>
                </Row>
                <Row>
                  <Col>Provinsi</Col>
                  <Col className="font-weight-bold">
                    : {row?.provinces?.province_name}
                  </Col>
                </Row>
                <Row>
                  <Col>Kabupaten/Kota</Col>
                  <Col className="font-weight-bold">
                    : {row?.cities?.city_name}
                  </Col>
                </Row>
                <Row>
                  <Col>Kecamatan</Col>
                  <Col className="font-weight-bold">
                    : {row?.districts?.district_name}
                  </Col>
                </Row>
                <Row>
                  <Col>Kelurahan</Col>
                  <Col className="font-weight-bold">
                    : {row?.subdistricts?.subdistrict_name}
                  </Col>
                </Row>
              </Col>
            </Row>
            <h6
              className="font-weight-bold text-capitalize mb-4 p-2 mt-5"
              style={{ backgroundColor: "#edd580", borderRadius: "2px" }}
            >
              Detil Perwakilan
            </h6>
            <Row>
              <Col xs={12} lg={6}>
                <Row>
                  <Col>Nama Perwakilan </Col>
                  <Col className="font-weight-bold">
                    : {row?.attachments?.receiver_name || "-"}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}

        {location?.pathname !== "/dashboard-images" && (
          <Form
            onSubmit={formik.handleSubmit}
            className="w-100 d-flex flex-wrap"
          >
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">ID Dokumen</label>
              <Form.Control
                type="text"
                name="document_id"
                className="form-control"
                value={formik.values.document_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.document_id && !!formik.errors.document_id
                }
                isValid={
                  !!formik.touched.document_id && !formik.errors.document_id
                }
              ></Form.Control>
              {formik.errors.document_id && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.document_id}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">ID Wilayah</label>
              <Form.Control
                type="text"
                name="wilayah_id"
                className="form-control"
                value={formik.values.wilayah_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.wilayah_id && !!formik.errors.wilayah_id
                }
                isValid={
                  !!formik.touched.wilayah_id && !formik.errors.wilayah_id
                }
              ></Form.Control>
              {formik.errors.wilayah_id && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.wilayah_id}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">PBP Latitude</label>
              <Form.Control
                type="text"
                name="kpm_latitude"
                className="form-control"
                value={formik.values.kpm_latitude}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.kpm_latitude && !!formik.errors.kpm_latitude
                }
                isValid={
                  !!formik.touched.kpm_latitude && !formik.errors.kpm_latitude
                }
              ></Form.Control>
              {formik.errors.kpm_latitude && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.kpm_latitude}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">PBP Longitude</label>
              <Form.Control
                type="text"
                name="kpm_longitude"
                className="form-control"
                value={formik.values.kpm_longitude}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.kpm_longitude &&
                  !!formik.errors.kpm_longitude
                }
                isValid={
                  !!formik.touched.kpm_longitude && !formik.errors.kpm_longitude
                }
              ></Form.Control>
              {formik.errors.kpm_longitude && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.kpm_longitude}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Latitude Provinsi</label>
              <Form.Control
                type="text"
                name="province_lat"
                className="form-control"
                value={formik.values.province_lat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.province_lat && !!formik.errors.province_lat
                }
                isValid={
                  !!formik.touched.province_lat && !formik.errors.province_lat
                }
              ></Form.Control>
              {formik.errors.province_lat && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.province_lat}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Longitude Provinsi</label>
              <Form.Control
                type="text"
                name="province_lng"
                className="form-control"
                value={formik.values.province_lng}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.province_lng && !!formik.errors.province_lng
                }
                isValid={
                  !!formik.touched.province_lng && !formik.errors.province_lng
                }
              ></Form.Control>
              {formik.errors.province_lng && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.province_lng}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Latitude Kota</label>
              <Form.Control
                type="text"
                name="city_lat"
                className="form-control"
                value={formik.values.city_lat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.city_lat && !!formik.errors.city_lat
                }
                isValid={!!formik.touched.city_lat && !formik.errors.city_lat}
              ></Form.Control>
              {formik.errors.city_lat && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.city_lat}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Longitude Kota</label>
              <Form.Control
                type="text"
                name="city_lng"
                className="form-control"
                value={formik.values.city_lng}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.city_lng && !!formik.errors.city_lng
                }
                isValid={!!formik.touched.city_lng && !formik.errors.city_lng}
              ></Form.Control>
              {formik.errors.city_lng && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.city_lng}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Latitude Kecamatan</label>
              <Form.Control
                type="text"
                name="district_lat"
                className="form-control"
                value={formik.values.district_lat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.district_lat && !!formik.errors.district_lat
                }
                isValid={
                  !!formik.touched.district_lat && !formik.errors.district_lat
                }
              ></Form.Control>
              {formik.errors.district_lat && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.district_lat}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Longitude Kecamatan</label>
              <Form.Control
                type="text"
                name="district_lng"
                className="form-control"
                value={formik.values.district_lng}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.district_lng && !!formik.errors.district_lng
                }
                isValid={
                  !!formik.touched.district_lng && !formik.errors.district_lng
                }
              ></Form.Control>
              {formik.errors.district_lng && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.district_lng}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Latitude Kelurahan</label>
              <Form.Control
                type="text"
                name="subdistrict_lat"
                className="form-control"
                value={formik.values.subdistrict_lat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.subdistrict_lat &&
                  !!formik.errors.subdistrict_lat
                }
                isValid={
                  !!formik.touched.subdistrict_lat &&
                  !formik.errors.subdistrict_lat
                }
              ></Form.Control>
              {formik.errors.subdistrict_lat && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.subdistrict_lat}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Longitude Kelurahan</label>
              <Form.Control
                type="text"
                name="subdistrict_lng"
                className="form-control"
                value={formik.values.subdistrict_lng}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.subdistrict_lng &&
                  !!formik.errors.subdistrict_lng
                }
                isValid={
                  !!formik.touched.subdistrict_lng &&
                  !formik.errors.subdistrict_lng
                }
              ></Form.Control>
              {formik.errors.subdistrict_lng && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.subdistrict_lng}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Lokasi Barcode</label>
              <Form.Control
                type="text"
                name="location_barcode"
                className="form-control"
                value={formik.values.location_barcode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.location_barcode &&
                  !!formik.errors.location_barcode
                }
                isValid={
                  !!formik.touched.location_barcode &&
                  !formik.errors.location_barcode
                }
              ></Form.Control>
              {formik.errors.location_barcode && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.location_barcode}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group
              className="w-100 px-2 split-form"
              style={{ display: editAction === "ubah-alamat" && "none" }}
            >
              <label className="form -control-label">NIK</label>
              <Form.Control
                type="text"
                name="kpm_reg_number"
                className="form-control"
                value={formik.values.kpm_reg_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.kpm_reg_number &&
                  !!formik.errors.kpm_reg_number
                }
                isValid={
                  !!formik.touched.kpm_reg_number &&
                  !formik.errors.kpm_reg_number
                }
              ></Form.Control>
              {formik.errors.kpm_reg_number && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.kpm_reg_number}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group
              className="w-100 px-2 split-form"
              style={{ display: editAction === "ubah-alamat" && "none" }}
            >
              <label className="form -control-label">Nomor Registrasi</label>
              <Form.Control
                type="text"
                name="reg_number"
                className="form-control"
                value={formik.values.reg_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.reg_number && !!formik.errors.reg_number
                }
                isValid={
                  !!formik.touched.reg_number && !formik.errors.reg_number
                }
              ></Form.Control>
              {formik.errors.reg_number && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.reg_number}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group
              className="w-100 px-2 split-form"
              style={{ display: editAction === "ubah-alamat" && "none" }}
            >
              <label className="form -control-label">Nama PBP</label>
              <Form.Control
                type="text"
                name="kpm_name"
                className="form-control"
                value={formik.values.kpm_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.kpm_name && !!formik.errors.kpm_name
                }
                isValid={!!formik.touched.kpm_name && !formik.errors.kpm_name}
              ></Form.Control>
              {formik.errors.kpm_name && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.kpm_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group
              className="w-100 px-2 split-form"
              style={{ display: editAction === "ubah-alamat" && "none" }}
            >
              <label className="form -control-label">Umur</label>
              <Form.Control
                type="text"
                name="kpm_age"
                className="form-control"
                value={formik.values.kpm_age}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.kpm_age && !!formik.errors.kpm_age}
                isValid={!!formik.touched.kpm_age && !formik.errors.kpm_age}
              ></Form.Control>
              {formik.errors.kpm_age && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.kpm_age}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group
              className="w-100 px-2 split-form"
              style={{ display: editAction === "ubah-alamat" && "none" }}
            >
              <label className="form -control-label">Alamat</label>
              <Form.Control
                type="text"
                name="address"
                className="form-control"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.address && !!formik.errors.address}
                isValid={!!formik.touched.address && !formik.errors.address}
              ></Form.Control>
              {formik.errors.address && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.address}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group
              className="w-100 px-2 split-form"
              style={{ display: editAction === "ubah-alamat" && "none" }}
            >
              <label className="form -control-label">RT</label>
              <Form.Control
                type="text"
                name="rt"
                className="form-control"
                value={formik.values.rt}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.rt && !!formik.errors.rt}
                isValid={!!formik.touched.rt && !formik.errors.rt}
              ></Form.Control>
              {formik.errors.rt && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.rt}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group
              className="w-100 px-2 split-form"
              style={{ display: editAction === "ubah-alamat" && "none" }}
            >
              <label className="form -control-label">RW</label>
              <Form.Control
                type="text"
                name="rw"
                className="form-control"
                value={formik.values.rw}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.rw && !!formik.errors.rw}
                isValid={!!formik.touched.rw && !formik.errors.rw}
              ></Form.Control>
              {formik.errors.rw && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.rw}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">PBP Barcode</label>
              <Form.Control
                type="text"
                name="kpm_barcode"
                className="form-control"
                value={formik.values.kpm_barcode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.kpm_barcode && !!formik.errors.kpm_barcode
                }
                isValid={
                  !!formik.touched.kpm_barcode && !formik.errors.kpm_barcode
                }
              ></Form.Control>
              {formik.errors.kpm_barcode && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.kpm_barcode}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {/* <Form.Group>
                        <label className="form -control-label">created_by</label>
                        <Form.Control type="text" name="created_by" className="form-control" value={formik.values.created_by}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.created_by && !!formik.errors.created_by}
                            isValid={!!formik.touched.created_by && !formik.errors.created_by}
                        ></Form.Control>
                        {
                            formik.errors.created_by && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.created_by}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">updated_by</label>
                        <Form.Control type="text" name="updated_by" className="form-control" value={formik.values.updated_by}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.updated_by && !!formik.errors.updated_by}
                            isValid={!!formik.touched.updated_by && !formik.errors.updated_by}
                        ></Form.Control>
                        {
                            formik.errors.updated_by && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.updated_by}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Status</label>
              <Form.Control
                type="text"
                name="status"
                className="form-control"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.status && !!formik.errors.status}
                isValid={!!formik.touched.status && !formik.errors.status}
              ></Form.Control>
              {formik.errors.status && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.status}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {/* <Form.Group>
                        <label className="form -control-label">created_at</label>
                        <Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.created_at && !!formik.errors.created_at}
                            isValid={!!formik.touched.created_at && !formik.errors.created_at}
                        ></Form.Control>
                        {
                            formik.errors.created_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.created_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">updated_at</label>
                        <Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.updated_at && !!formik.errors.updated_at}
                            isValid={!!formik.touched.updated_at && !formik.errors.updated_at}
                        ></Form.Control>
                        {
                            formik.errors.updated_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.updated_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
            {/* <Form.Group>
                        <label className="form -control-label">handover_at</label>
                        <Form.Control type="text" name="handover_at" className="form-control" value={formik.values.handover_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.handover_at && !!formik.errors.handover_at}
                            isValid={!!formik.touched.handover_at && !formik.errors.handover_at}
                        ></Form.Control>
                        {
                            formik.errors.handover_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.handover_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">handover_by</label>
                        <Form.Control type="text" name="handover_by" className="form-control" value={formik.values.handover_by}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.handover_by && !!formik.errors.handover_by}
                            isValid={!!formik.touched.handover_by && !formik.errors.handover_by}
                        ></Form.Control>
                        {
                            formik.errors.handover_by && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.handover_by}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Foto Handover</label>
              <Form.Control
                type="text"
                name="photo_handover"
                className="form-control"
                value={formik.values.photo_handover}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.photo_handover &&
                  !!formik.errors.photo_handover
                }
                isValid={
                  !!formik.touched.photo_handover &&
                  !formik.errors.photo_handover
                }
              ></Form.Control>
              {formik.errors.photo_handover && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.photo_handover}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Foto Dokumen</label>
              <Form.Control
                type="text"
                name="photo_document"
                className="form-control"
                value={formik.values.photo_document}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.photo_document &&
                  !!formik.errors.photo_document
                }
                isValid={
                  !!formik.touched.photo_document &&
                  !formik.errors.photo_document
                }
              ></Form.Control>
              {formik.errors.photo_document && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.photo_document}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">Bulk Handover</label>
              <Form.Control
                type="text"
                name="bulk_handover"
                className="form-control"
                value={formik.values.bulk_handover}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.bulk_handover &&
                  !!formik.errors.bulk_handover
                }
                isValid={
                  !!formik.touched.bulk_handover && !formik.errors.bulk_handover
                }
              ></Form.Control>
              {formik.errors.bulk_handover && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.bulk_handover}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">ID Periode</label>
              <Form.Control
                as="select"
                name="periode_id"
                className="form-control"
                value={formik.values.periode_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.periode_id && !!formik.errors.periode_id
                }
                isValid={
                  !!formik.touched.periode_id && !formik.errors.periode_id
                }
                disabled={periodsLoading}
              >
                <option value={0}>
                  {periodsLoading ? "Loading..." : "Pilih Periode"}
                </option>
                {periodsData.list.map((item, i) => {
                  return (
                    <option value={item.id} key={`periods-${i}`}>
                      {item.month} - {item.year}
                    </option>
                  );
                })}
              </Form.Control>
              {formik.errors.periode_id && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.periode_id}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form">
              <label className="form -control-label">ID Provinsi</label>
              <Form.Control
                as="select"
                name="province_id"
                className="form-control"
                value={formik.values.province_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.province_id && !!formik.errors.province_id
                }
                isValid={
                  !!formik.touched.province_id && !formik.errors.province_id
                }
                disabled={provincesLoading}
              >
                <option value={0}>
                  {provincesLoading ? "Loading..." : "Pilih Provinsi"}
                </option>
                {provincesData.list.map((item, i) => {
                  return (
                    <option value={item.province_id} key={`provinces-${i}`}>
                      {item.province_name}
                    </option>
                  );
                })}
              </Form.Control>
              {formik.errors.province_id && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.province_id}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form">
              <label className="form -control-label">ID Kota</label>
              <Form.Control
                as="select"
                name="city_id"
                className="form-control"
                value={formik.values.city_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.city_id && !!formik.errors.city_id}
                isValid={!!formik.touched.city_id && !formik.errors.city_id}
                disabled={citiesLoading}
              >
                <option value={0}>
                  {citiesLoading ? "Loading..." : "Pilih Kota"}
                </option>
                {citiesData.list.map((item, i) => {
                  return (
                    <option value={item.city_id} key={`cities-${i}`}>
                      {item.city_name}
                    </option>
                  );
                })}
              </Form.Control>
              {formik.errors.city_id && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.city_id}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form">
              <label className="form -control-label">ID Kecamatan</label>
              <Form.Control
                as="select"
                name="district_id"
                className="form-control"
                value={formik.values.district_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.district_id && !!formik.errors.district_id
                }
                isValid={
                  !!formik.touched.district_id && !formik.errors.district_id
                }
                disabled={districtsLoading}
              >
                <option value={0}>
                  {districtsLoading ? "Loading..." : "Pilih Kecamatan"}
                </option>
                {districtsData.list.map((item, i) => {
                  return (
                    <option value={item.district_id} key={`districts-${i}`}>
                      {item.district_name}
                    </option>
                  );
                })}
              </Form.Control>
              {formik.errors.district_id && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.district_id}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form">
              <label className="form -control-label">ID Kelurahan</label>
              <Form.Control
                as="select"
                name="subdistrict_id"
                className="form-control"
                value={formik.values.subdistrict_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.subdistrict_id &&
                  !!formik.errors.subdistrict_id
                }
                isValid={
                  !!formik.touched.subdistrict_id &&
                  !formik.errors.subdistrict_id
                }
                disabled={subdistrictsLoading}
              >
                <option value={0}>
                  {subdistrictsLoading ? "Loading..." : "Pilih Kelurahan"}
                </option>
                {subdistrictsData.list.map((item, i) => {
                  return (
                    <option
                      value={item.subdistrict_id}
                      key={`subdistricts-${i}`}
                    >
                      {item.subdistrict_name}
                    </option>
                  );
                })}
              </Form.Control>
              {formik.errors.subdistrict_id && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.subdistrict_id}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="w-100 px-2 split-form d-none">
              <label className="form -control-label">ID Pengiriman</label>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    <i className="fas fa-search"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Cari Pengiriman"
                  value={searchShipments}
                  onChange={handleSearchShipments}
                  disabled={shipmentsLoading}
                />
              </InputGroup>
              <Form.Control
                as="select"
                name="shipment_id"
                className="form-control"
                value={formik.values.shipment_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.shipment_id && !!formik.errors.shipment_id
                }
                isValid={
                  !!formik.touched.shipment_id && !formik.errors.shipment_id
                }
                disabled={shipmentsLoading}
              >
                <option value={0}>
                  {shipmentsLoading ? "Loading..." : "Pilih Pengiriman"}
                </option>
                {searchShipments
                  ? filteredShipments.map((item, i) => {
                      return (
                        <option value={item.id} key={`shipments-${i}`}>
                          {item.document_id}
                        </option>
                      );
                    })
                  : shipmentsData.list.map((item, i) => {
                      return (
                        <option value={item.id} key={`shipments-${i}`}>
                          {item.document_id}
                        </option>
                      );
                    })}
              </Form.Control>
              {formik.errors.shipment_id && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.shipment_id}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="px-2 w-100">
              <Button
                type="submit"
                className="float-left mr-3"
                variant="primary"
                disabled={loading}
              >
                {loading && (
                  <Spinner
                    variant="light"
                    animation="border"
                    size="sm"
                    className="mr-1"
                  ></Spinner>
                )}
                Simpan
              </Button>
              <Button
                className="float-left"
                variant="secondary"
                onClick={() => resetForm()}
              >
                Reset
              </Button>
            </Form.Group>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};
