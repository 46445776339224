import { useFormik } from "formik";
import React from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { updateVerval } from "services/kpmsService";

function ActionButton({
  row,
  section,
  handleRowEdit,
  setMessage,
  isSelectedProvince,
  isSelectedCity,
  isSelectedDistrict,
  isSelectedSubDistrict,
  handleRowDetail,
}) {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (value) => {
    console.log("value", value);
    setShow(true);
  };

  const buttonData = [
    {
      name: "Ubah Alamat",
      onClick: () => handleRowEdit(row, "ubah-alamat"),
      style: { display: section === "verval" ? "block" : "none" },
      icon: <i className="fa-solid fa-location-dot" />,
    },
    {
      name: "Ubah PBP",
      onClick: () => handleRowEdit(row, ""),
      style: { display: section === "verval" ? "block" : "none" },
      icon: <i className="fa-regular fa-address-book" />,
    },
    {
      name: "Non-Active",
      onClick: () => handleShow(row),
      style: { display: section === "verval" ? "block" : "none" },
      icon: <i className="fa-solid fa-power-off text-danger" />,
    },
    {
      name: "Lihat Detail",
      onClick: () =>
        handleRowDetail(
          row,
          isSelectedProvince,
          isSelectedCity,
          isSelectedDistrict,
          isSelectedSubDistrict
        ),
      style: { display: section === "images" ? "block" : "none" },
      icon: <i className="fa-solid fa-eye text-dark" />,
    },
  ];

  const tooltip = (value) => {
    return (
      <Tooltip id="tooltip">
        <strong>{value}</strong>
      </Tooltip>
    );
  };

  const iValue = {
    is_archived: true,
  };

  const formik = useFormik({
    initialValues: iValue,
    onSubmit: async (value) => {
      const finalData = {
        periode_id: row.periode_id,
        type: "pbp",
        change_type: row.is_archived ? "unarchived" : "archived",
        affected_id: row.id,
        data: { ...value },
      };
      console.log("finalData", finalData);
      try {
        const res = await updateVerval(finalData);
        if (res) {
          setMessage("Sukses Menonaktifkan");
          setShow(false);
          //   setLoading(false);
        }
      } catch (error) {
        setMessage("Gagal Menonaktifkan");
        // setLoading(false);
      }
    },
  });

  return (
    <div className="d-flex justify-content-center">
      {buttonData.map((item) => (
        <OverlayTrigger placement="top" overlay={tooltip(item.name)}>
          <Button
            variant="link"
            className="btn-sm"
            style={item.style}
            onClick={item.onClick}>
            {item.icon}
          </Button>
        </OverlayTrigger>
      ))}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Menghapus PBP</Modal.Title>
        </Modal.Header>
        <Modal.Body>Yakin Menghapus PBP?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="primary" onClick={() => formik.handleSubmit(row)}>
            Ya
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ActionButton;
