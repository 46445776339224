import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IKpms {
    id: number,
    document_id?: number,
    periode_id?: number,
    wilayah_id?: number,
    province_id?: number,
    city_id?: number,
    district_id?: number,
    subdistrict_id?: number,
    kpm_latitude?: number,
    kpm_longitude?: number,
    location_barcode?: string,
    reg_number?: string,
    kpm_name?: string,
    address?: string,
    kpm_barcode?: string,
    created_by?: string,
    updated_by?: string,
    status?: string,
    created_at: Date,
    updated_at: Date,
    handover_at?: Date,
    handover_by?: string,
    photo_handover?: string,
    photo_document?: string,
    shipment_id?: number,
    bulk_handover?: string
}

interface IKpmsData {
    list?: Array<IKpms>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?: boolean;
    lastPage?: number
}

const initialState: IKpmsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading: false,
    lastPage: 0
};

const kpmsSlice = createSlice({
    name: "kpms",
    initialState,
    reducers: {
        setKpmsList: (state, _action: PayloadAction<IKpmsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
            state.lastPage = _action.payload.lastPage
        },
        resetKpmsToInit: (state) => {
            state = initialState;
        },
        setKpmsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingKpms: (state) => {
            state.loading = true
        },
        stopLoadingKpms: (state) => {
            state.loading = false
        }
    },
});

export const { setKpmsList, resetKpmsToInit, setKpmsMessage, startLoadingKpms, stopLoadingKpms } = kpmsSlice.actions;

export default kpmsSlice.reducer;

