import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setDriversMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addDrivers, updateDrivers } from "services/driversService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { useNavigate } from "react-router";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const DriversForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue = { id: 0, name: '', email: '', phone: '', license_number: '', created_at: '', updated_at: '' };
    const initialValue = action === 'edit' ? row : iValue;
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            setLoading(true)
            if (action === 'edit') {
                updateDrivers(values).then((response:any)=>{
                    if (response && response.data && response.data.code === 1) {
                        setLoading(false)
                        dispatch(setDriversMessage("Berhasil mengubah data"));
                        getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                        hideShowForm('');
                    }
                }).catch(error=>{
                    dispatch(setDriversMessage("Terjadi kesalahan"));
                    setLoading(false)
                })
            } else if (action === 'add') {
                addDrivers(values).then((response:any)=>{
                    if (response && response.data && response.data.code === 1) {
                        setLoading(false)
                        dispatch(setDriversMessage("Berhasil menambah data"));
                        getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                        navigate('/drivers')
                    }
                }).catch(error=>{
                    dispatch(setDriversMessage("Terjadi kesalahan"));
                    setLoading(false)
                })
            }
        },
        validationSchema: yup.object({
            name: yup.string().required('Nama wajib diisi'),
            email: yup.string().nullable(),
            phone: yup.string().required(),
            license_number: yup.string().nullable(),
            // created_at: yup.date().nullable(),
            // updated_at: yup.date().nullable(),

        }),
    });
    const resetForm = () => {
        formik.resetForm()
    }
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action === 'add' ? 'Tambah' : 'Ubah'} Supir
                    {/* <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Drivers</span>
                    </Button> */}
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit} className="w-100 d-flex flex-wrap">
                        <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">Nama</label>
                            <Form.Control type="text" name="name" className="form-control" value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.name && !!formik.errors.name}
                                isValid={!!formik.touched.name && !formik.errors.name}
                            ></Form.Control>
                            {
                                formik.errors.name && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.name}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                        <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">Email</label>
                            <Form.Control type="text" name="email" className="form-control" value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.email && !!formik.errors.email}
                                isValid={!!formik.touched.email && !formik.errors.email}
                            ></Form.Control>
                            {
                                formik.errors.email && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.email}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                        <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">Telepon</label>
                            <Form.Control type="text" name="phone" className="form-control" value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.phone && !!formik.errors.phone}
                                isValid={!!formik.touched.phone && !formik.errors.phone}
                            ></Form.Control>
                            {
                                formik.errors.phone && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.phone}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                        <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">Nomor Lisensi</label>
                            <Form.Control type="text" name="license_number" className="form-control" value={formik.values.license_number}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.license_number && !!formik.errors.license_number}
                                isValid={!!formik.touched.license_number && !formik.errors.license_number}
                            ></Form.Control>
                            {
                                formik.errors.license_number && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.license_number}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                    {/* <Form.Group>
                        <label className="form -control-label">created_at</label>
                        <Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.created_at && !!formik.errors.created_at}
                            isValid={!!formik.touched.created_at && !formik.errors.created_at}
                        ></Form.Control>
                        {
                            formik.errors.created_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.created_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">updated_at</label>
                        <Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.updated_at && !!formik.errors.updated_at}
                            isValid={!!formik.touched.updated_at && !formik.errors.updated_at}
                        ></Form.Control>
                        {
                            formik.errors.updated_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.updated_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}

                    <Form.Group className="w-100 px-2">
                        <Button type="submit" className="float-left mr-3" variant="primary" disabled={loading}>{loading && <Spinner variant="light" animation="border" size="sm" className="mr-1"></Spinner>}Simpan</Button>
                        <Button className="float-left" variant="secondary" onClick={() => resetForm()}>Reset</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

