import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface Province {
    province_id: number;
    province_name: string;
}
export interface ICities {
    city_id: number;
    province_id: number;
    city_name: string;
    city_lat: null | number; // Replace 'null' with an appropriate type if you have more specific information
    city_lng: null | number; // Replace 'null' with an appropriate type if you have more specific information
    created_at: string;
    updated_at: string;
    provinces: Province;
}


interface ICitiesData {
    list?: Array<ICities>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?: boolean;
}

const initialState: ICitiesData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading: false
};

const citiesSlice = createSlice({
    name: "cities",
    initialState,
    reducers: {
        setCitiesList: (state, _action: PayloadAction<ICitiesData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetCitiesToInit: (state) => {
            state = initialState;
        },
        setCitiesMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingCities: (state) => {
            state.loading = true
        },
        stopLoadingCities: (state) => {
            state.loading = false
        }
    },
});

export const { setCitiesList, resetCitiesToInit, setCitiesMessage, startLoadingCities, stopLoadingCities } = citiesSlice.actions;

export default citiesSlice.reducer;

