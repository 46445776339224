import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import {
  resetCitiesToInit,
  resetDistrictsToInit,
  resetKpmsToInit,
  resetProvincesToInit,
  resetSubdistrictsToInit,
  setCitiesList,
  setCitiesMessage,
  setDistrictsList,
  setDistrictsMessage,
  setKpmsMessage,
  setProvincesList,
  setProvincesMessage,
  setSubdistrictsList,
  setSubdistrictsMessage,
  startLoadingCities,
  startLoadingDistricts,
  startLoadingProvinces,
  startLoadingSubDistricts,
  stopLoadingCities,
  stopLoadingDistricts,
  stopLoadingProvinces,
  stopLoadingSubDistricts,
} from "redux/actions";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteKpms } from "services/kpmsService";
// import { ComponentUndangan } from "./print/printInvitation";
import { useReactToPrint } from "react-to-print";
import { getCities } from "services/citiesService";
import { getDistricts } from "services/districtsService";
import { getProvinces } from "services/provincesService";
import { getSubdistricts } from "services/subdistrictsService";
type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: (row) => void;
  getData: (
    page,
    pageSize,
    searchKey,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id
  ) => void;
};
export const SptjmTable: React.FC<Props> = ({ handleRowEdit, getData }) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const [showUpload, setShowUpload] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const rData = useSelector((state: RootState) => state.kpms);
  const periodsData = useSelector((state: RootState) => state.periods);
  const provincesData = useSelector((state: RootState) => state.provinces);
  const citiesData = useSelector((state: RootState) => state.cities);
  const districtsData = useSelector((state: RootState) => state.districts);
  const subdistrictsData = useSelector(
    (state: RootState) => state.subdistricts
  );
  const periodsLoading = useSelector(
    (state: RootState) => state.periods.loading
  );
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const citiesLoading = useSelector((state: RootState) => state.cities.loading);
  const districtsLoading = useSelector(
    (state: RootState) => state.districts.loading
  );
  const subdistrictsLoading = useSelector(
    (state: RootState) => state.subdistricts.loading
  );
  const isLoading = useSelector((state: RootState) => state.kpms.loading);
  const periodsId = localStorage.getItem("periode_id");
  const provinceId = localStorage.getItem("province_id");
  const cityId = localStorage.getItem("city_id");
  const districtId = localStorage.getItem("district_id");
  const subdistrictId = localStorage.getItem("subdistrict_id");
  const roles = localStorage.getItem("user");
  const provinceName = localStorage.getItem("province_name");
  const cityName = localStorage.getItem("city_name");

  const handleSearch = () => {
    if (search.length > 0) {
      if (roles === "Super Admin" || roles === "Admin STPJM") {
        setSelectedDate("");
        setSelectedProvince(0);
        setSelectedCity(0);
        setSelectedDistrict(0);
        setSelectedSubdistrict(0);
        getData(
          Constant.defaultPageNumber,
          Constant.defaultPageSize,
          search ?? "",
          periodsId,
          selectedDate,
          selectedProvince,
          selectedCity,
          selectedDistrict,
          selectedSubdistrict
        );
      } else {
        setSelectedDate("");
        setSelectedDistrict(0);
        setSelectedSubdistrict(0);
        getData(
          Constant.defaultPageNumber,
          Constant.defaultPageSize,
          search ?? "",
          periodsId,
          selectedDate,
          provinceId,
          cityId,
          "",
          ""
        );
      }
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (roles === "Super Admin" || roles === "Admin STPJM") {
      if (selectedProvince) {
        await getData(
          page,
          newPerPage,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          selectedProvince,
          selectedCity,
          selectedDistrict,
          selectedSubdistrict
        );
      } else {
        await getData(
          page,
          newPerPage,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          "",
          "",
          "",
          ""
        );
      }
    } else {
      if (selectedDistrict) {
        await getData(
          page,
          newPerPage,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          provinceId,
          cityId,
          selectedDistrict,
          selectedSubdistrict
        );
      } else {
        await getData(
          page,
          newPerPage,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          provinceId,
          cityId,
          "",
          ""
        );
      }
    }
  };

  const handlePageChange = (page) => {
    if (roles === "Super Admin") {
      if (selectedProvince) {
        getData(
          page,
          rData.pageSize,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          selectedProvince,
          selectedCity,
          selectedDistrict,
          selectedSubdistrict
        );
      } else {
        getData(
          page,
          rData.pageSize,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          "",
          "",
          "",
          ""
        );
      }
    } else {
      if (selectedDistrict) {
        getData(
          page,
          rData.pageSize,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          provinceId,
          cityId,
          selectedDistrict,
          selectedSubdistrict
        );
      } else {
        getData(
          page,
          rData.pageSize,
          search ?? "",
          periodsId ?? "",
          selectedDate ?? "",
          provinceId,
          cityId,
          "",
          ""
        );
      }
    }
  };
  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };

  useEffect(() => {
    if (roles === "Super Admin" || roles === "Admin STPJM") {
      if (
        rData &&
        rData.list &&
        rData.list.length === 0 &&
        !search &&
        !selectedDate &&
        !selectedProvince &&
        !selectedCity &&
        !selectedDistrict &&
        !selectedSubdistrict
      ) {
        getData(
          Constant.defaultPageNumber,
          Constant.defaultPageSize,
          "",
          periodsId,
          "",
          "",
          "",
          "",
          ""
        );
      }
    } else {
      if (
        rData &&
        rData.list &&
        rData.list.length === 0 &&
        !search &&
        !selectedDate &&
        !selectedDistrict &&
        !selectedSubdistrict
      ) {
        getData(
          Constant.defaultPageNumber,
          Constant.defaultPageSize,
          "",
          periodsId,
          "",
          provinceId,
          cityId,
          "",
          ""
        );
      }
    }

    if (
      (provincesData &&
        provincesData.list &&
        provincesData.list.length === 0 &&
        roles === "Super Admin") ||
      (provincesData &&
        provincesData.list &&
        provincesData.list.length === 0 &&
        roles === "Admin STPJM")
    ) {
      dispatch(resetProvincesToInit());
      dispatch(startLoadingProvinces());
      getProvinces(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setProvincesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingProvinces());
        } else {
          dispatch(setProvincesMessage("Data kosong"));
          dispatch(stopLoadingProvinces());
        }
      });
    }
    // if (provincesData && provincesData.list && provincesData.list.length === 0) {
    //   dispatch(resetProvincesToInit());
    //   dispatch(startLoadingProvinces())
    //   getProvinces(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
    //     if (response && response.records) {
    //       dispatch(setProvincesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
    //       dispatch(stopLoadingProvinces())
    //     } else {
    //       dispatch(setProvincesMessage("Data kosong"));
    //       dispatch(stopLoadingProvinces())
    //     }
    //   })
    // }
    // if (periodsData && periodsData.list && periodsData.list.length === 0) {
    //   dispatch(resetPeriodsToInit());
    //   dispatch(startLoadingPeriods())
    //   getPeriods(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
    //     if (response && response.records) {
    //       dispatch(setPeriodsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
    //       dispatch(stopLoadingPeriods())
    //     } else {
    //       dispatch(setPeriodsMessage("Data kosong"));
    //       dispatch(stopLoadingPeriods())
    //     }
    //   })
    // }
  }, [rData.list.length]);

  const handleReset = () => {
    setSearch("");
    setSelectedDate("");
    setSelectedProvince(0);
    setSelectedCity(0);
    setSelectedDistrict(0);
    setSelectedSubdistrict(0);
    dispatch(resetKpmsToInit());
    if (roles === "Super Admin" || roles === "Admin STPJM") {
      getData(
        Constant.defaultPageNumber,
        rData.pageSize,
        "",
        periodsId,
        "",
        "",
        "",
        "",
        ""
      );
    } else {
      getData(
        Constant.defaultPageNumber,
        rData.pageSize,
        "",
        periodsId,
        "",
        provinceId,
        cityId,
        "",
        ""
      );
    }
  };

  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deleteKpms(rowData.id);
      if (response) {
        dispatch(resetKpmsToInit());
        dispatch(setKpmsMessage("Berhasil menghapus data"));
        if (roles === "Super Admin") {
          getData(
            Constant.defaultPageNumber,
            Constant.defaultPageSize,
            "",
            periodsId,
            "",
            "",
            "",
            "",
            ""
          );
        } else {
          getData(
            Constant.defaultPageNumber,
            Constant.defaultPageSize,
            "",
            periodsId,
            "",
            provinceId,
            cityId,
            "",
            ""
          );
        }
        setShowDelete(false);
      } else {
        dispatch(setKpmsMessage("Terjadi kesalahan"));
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };
  // const handleAddButtonClick = () => {
  //     dispatch(setKpmsMessage(''));
  //     hideShowForm('add');
  // }
  // const handleUpload = () => {
  //     console.log('first')
  // }

  const columns = [
    // { name: 'id', selector: row => row.id, sortable: true },
    // { name: "ID Dokumen", selector: (row) => row.document_id, sortable: true },
    { name: "Periode", selector: (row) => row.periode_id, sortable: true },
    // { name: 'Nama Tahap', selector: row => row.periods.tahap_name, sortable: true },
    // { name: "ID Wilayah", selector: (row) => row.wilayah_id, sortable: true },
    // { name: 'province_id', selector: row => row.province_id, sortable: true },
    {
      name: "Provinsi",
      selector: (row) => row?.provinces?.province_name,
      sortable: true,
    },
    // { name: 'city_id', selector: row => row.city_id, sortable: true },
    { name: "Kota", selector: (row) => row?.cities?.city_name, sortable: true },
    // { name: 'district_id', selector: row => row.district_id, sortable: true },
    {
      name: "Kecamatan",
      selector: (row) => row?.districts?.district_name,
      sortable: true,
    },
    // { name: 'subdistrict_id', selector: row => row.subdistrict_id, sortable: true },
    {
      name: "Kelurahan",
      selector: (row) => row?.subdistricts?.subdistrict_name,
      sortable: true,
    },
    // {
    //   name: "PBP Latitude",
    //   selector: (row) => row.kpm_latitude,
    //   sortable: true,
    // },
    // {
    //   name: "PBP Longitude",
    //   selector: (row) => row.kpm_longitude,
    //   sortable: true,
    // },
    // {
    //   name: "Lokasi Barcode",
    //   selector: (row) => row.location_barcode,
    //   sortable: true,
    // },
    {
      name: "Nomor Registrasi",
      selector: (row) => row.reg_number,
      sortable: true,
    },
    { name: "Nama PBP", selector: (row) => row.kpm_name, sortable: true },
    {
      name: "Nama PBP Pengganti",
      selector: (row) => row.pbps_old?.slice(-1)[0]?.kpm_name,
      sortable: true,
    },
    { name: "Alamat", selector: (row) => row.address, sortable: true },
    // { name: "PBP Barcode", selector: (row) => row.kpm_barcode, sortable: true },
    // { name: 'created_by', selector: row => row.created_by, sortable: true },
    // { name: 'updated_by', selector: row => row.updated_by, sortable: true },
    { name: "Status", selector: (row) => row.status, sortable: true },
    // { name: 'created_at', selector: row => row.created_at, sortable: true },
    // { name: 'updated_at', selector: row => row.updated_at, sortable: true },
    // { name: 'handover_at', selector: row => row.handover_at, sortable: true },
    // { name: 'handover_by', selector: row => row.handover_by, sortable: true },
    // {
    //   name: "Foto Handover",
    //   selector: (row) => row.photo_handover,
    //   sortable: true,
    // },
    // {
    //   name: "Foto Dokumen",
    //   selector: (row) => row.photo_document,
    //   sortable: true,
    // },
    // {
    //   name: "ID Pengiriman",
    //   selector: (row) => row.shipment_id,
    //   sortable: true,
    // },
    // { name: 'status', selector: row => row.shipments.status, sortable: true },
    // {
    //   name: "Bulk Handover",
    //   selector: (row) => row.bulk_handover,
    //   sortable: true,
    // },

    // {
    //   name: "",
    //   button: true,
    //   cell: (row) => (
    //     <Button
    //       variant="link"
    //       className="btn-sm"
    //       onClick={() => handleShowPrintInvitation(row)}
    //     >
    //       Print Undangan
    //     </Button>
    //   ),
    // },
    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleRowEdit(row)}>
          Ubah
        </Button>
      ),
    },
    // {
    //   name: "",
    //   button: true,
    //   cell: (row) => (
    //     <Button
    //       variant="link"
    //       className="btn-sm"
    //       onClick={() => handleRowDeleteClick(row)}
    //     >
    //       Hapus
    //     </Button>
    //   ),
    // },
  ];

  const [selectedProvince, setSelectedProvince] = useState(0);
  const [selectedCity, setSelectedCity] = useState(0);
  const [selectedDistrict, setSelectedDistrict] = useState(0);
  const [selectedSubdistrict, setSelectedSubdistrict] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value); // Memperbarui nilai state saat input date berubah
  };
  const handleProvinceChange = (event) => {
    const newValue = event.target.value;
    setSelectedProvince(newValue);
  };

  const handleCityChange = (event) => {
    const newValue = event.target.value;
    setSelectedCity(newValue);
  };

  const handleDistrictChange = (event) => {
    const newValue = event.target.value;
    setSelectedDistrict(newValue);
  };

  const handleSubdistrictChange = (event) => {
    const newValue = event.target.value;
    setSelectedSubdistrict(newValue);
  };

  useEffect(() => {
    if (selectedProvince) {
      dispatch(resetCitiesToInit());
      dispatch(startLoadingCities());
      getCities(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedProvince
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setCitiesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingCities());
        } else {
          dispatch(setCitiesMessage("Data kosong"));
          dispatch(stopLoadingCities());
        }
      });
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (roles === "Super Admin" || roles === "Admin STPJM") {
      if (selectedCity) {
        dispatch(resetDistrictsToInit());
        dispatch(startLoadingDistricts());
        getDistricts(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          "",
          selectedCity
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setDistrictsList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingDistricts());
          } else {
            dispatch(setDistrictsMessage("Data kosong"));
            dispatch(stopLoadingDistricts());
          }
        });
      }
    } else {
      dispatch(resetDistrictsToInit());
      dispatch(startLoadingDistricts());
      getDistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        cityId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setDistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingDistricts());
        } else {
          dispatch(setDistrictsMessage("Data kosong"));
          dispatch(stopLoadingDistricts());
        }
      });
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedDistrict) {
      dispatch(resetSubdistrictsToInit());
      dispatch(startLoadingSubDistricts());
      getSubdistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedDistrict
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setSubdistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingSubDistricts());
        } else {
          dispatch(setSubdistrictsMessage("Data kosong"));
          dispatch(stopLoadingSubDistricts());
        }
      });
    }
  }, [selectedDistrict]);
  const filterData = () => {
    setSearch("");
    if (roles === "Super Admin" || roles === "Admin STPJM") {
      getData(
        Constant.defaultPageNumber,
        Constant.defaultPageSize,
        "",
        periodsId,
        selectedDate,
        selectedProvince,
        selectedCity,
        selectedDistrict,
        selectedSubdistrict
      );
    } else {
      getData(
        Constant.defaultPageNumber,
        Constant.defaultPageSize,
        "",
        periodsId,
        selectedDate,
        provinceId,
        cityId,
        selectedDistrict,
        selectedSubdistrict
      );
    }
  };

  const [showInvitation, setShowInvitation] = useState(null);
  const [invitationData, setInvitationData] = useState([]);
  const componentUndanganRef = useRef();
  const handlePrintInvitation = useReactToPrint({
    content: () => componentUndanganRef.current,
    pageStyle: `
@page {
  margin: 6mm 0mm 17mm 0mm;
}`,
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary mb-2 mb-md-0">
          Daftar STPJM ({rData.totalCount})
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}>
            <i className="fa fa-refresh"></i>
          </Button>
        </h6>
        {/* <Button
          className="btn-icon-split float-right mb-2 mb-md-0"
          onClick={handleDownloadTemplateButtonClick}
        >
          <span className="icon text-white-50">
            <i className="fas fa-add"></i>
          </span>
          <span className="text">Download Template</span>
        </Button> */}
        {/* <Button
          className="btn-icon-split float-right mr-2"
          onClick={() => handleShowPrintInvitation()}
          disabled={invitationLoading}
        >
          <span className="icon text-white-50">
            {invitationLoading ? <Spinner variant="light" animation="border" size="sm"></Spinner> : <i className="fas fa-add"></i>}
          </span>
          <span className="text">Print Undangan</span>
        </Button> */}
        {/* <Button
          className="btn-icon-split float-right mr-2"
          onClick={() => setShowUpload(true)}
        >
          <span className="icon text-white-50">
            <i className="fas fa-add"></i>
          </span>
          <span className="text">Import</span>
        </Button> */}
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            {/* <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control as="select" custom onChange={handlePeriodsChange} value={selectedPeriods} required>

                  <option value={0}>{periodsLoading ? 'Loading...' : 'Pilih Periode'}</option>
                  {
                    periodsData.list.map((item, i) => {
                      return <option value={item.id} key={`periods-${i}`}>{item.id} ({item.month} - {item.year})</option>
                    })}
                </Form.Control>
              </Form.Group> */}
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                type="date"
                custom
                className="form-control"
                value={selectedDate} // Menampilkan nilai dari state pada input date
                onChange={handleDateChange} //
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                as="select"
                custom
                value={selectedProvince}
                disabled={roles !== "Super Admin" && roles !== "Admin STPJM"}
                onChange={handleProvinceChange}>
                <option
                  value={0}
                  // disabled
                >
                  {provincesLoading
                    ? "Loading..."
                    : roles !== "Super Admin"
                    ? provinceName
                    : "Pilih Provinsi"}
                </option>
                {provincesData.list.map((item, i) => {
                  return (
                    <option value={item.province_id} key={`provinces-${i}`}>
                      {item.province_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6} className="d-none d-md-block">
            <InputGroup>
              <Form.Control
                placeholder="Cari"
                aria-label="Search"
                aria-describedby="basic-search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <Button
                variant="dark"
                className="rounded-0 rounded-right mr-2"
                id="button-search"
                onClick={handleSearch}>
                Cari
              </Button>
            </InputGroup>
          </Col>
          <Col md={6}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                as="select"
                custom
                value={selectedCity}
                disabled={roles !== "Super Admin" && roles !== "Admin STPJM"}
                onChange={handleCityChange}>
                <option value={0} disabled>
                  {citiesLoading
                    ? "Loading..."
                    : roles !== "Super Admin"
                    ? cityName
                    : "Pilih Kota"}
                </option>
                {citiesData.list.map((item, i) => {
                  return (
                    <option value={item.city_id} key={`cities-${i}`}>
                      {item.city_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                as="select"
                custom
                value={selectedDistrict}
                onChange={handleDistrictChange}>
                <option value={0} disabled>
                  {districtsLoading ? "Loading..." : "Pilih Kecamatan"}
                </option>
                {districtsData.list.map((item, i) => {
                  return (
                    <option value={item.district_id} key={`districts-${i}`}>
                      {item.district_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                as="select"
                custom
                value={selectedSubdistrict}
                onChange={handleSubdistrictChange}>
                <option value={0} disabled>
                  {subdistrictsLoading ? "Loading..." : "Pilih Kelurahan"}
                </option>
                {subdistrictsData.list.map((item, i) => {
                  return (
                    <option
                      value={item.subdistrict_id}
                      key={`subdistricts-${i}`}>
                      {item.subdistrict_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Button
              className="float-right d-none d-md-block"
              type="primary"
              onClick={() => filterData()}>
              Filter
            </Button>
            <Button
              className="float-right mb-3 d-md-none"
              type="primary"
              onClick={() => filterData()}>
              Filter
            </Button>
            <div className="d-md-none">
              <InputGroup>
                <Form.Control
                  placeholder="Cari"
                  aria-label="Search"
                  aria-describedby="basic-search"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <Button
                  variant="dark"
                  className="rounded-0 rounded-right"
                  id="button-search"
                  onClick={handleSearch}>
                  Cari
                </Button>
              </InputGroup>
            </div>
          </Col>
        </Row>

        <div className="table-responsive mt-5">
          {isLoading && rData.list.length === 0 ? (
            <Spinner
              animation="border"
              variant="warning"
              className="d-flex mx-auto"></Spinner>
          ) : (
            <DataTable
              // selectableRows={true}
              onSelectedRowsChange={handleRowSelection}
              paginationPerPage={Constant.defaultPageNumber}
              paginationRowsPerPageOptions={
                Constant.paginationRowsPerPageOptions
              }
              columns={columns}
              data={rData.list}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationTotalRows={rData.totalCount}
              className="table table-bordered"
              pagination
              paginationServer
              progressPending={isLoading}
              onChangePage={handlePageChange}></DataTable>
          )}
        </div>
      </Card.Body>
      <ConfirmationModal
        buttonNegative="Batal"
        buttonPositive="Print"
        title="Konfirmasi Print Undangan"
        show={showInvitation}
        body={"Apakah anda yakin?"}
        onNegative={() => setShowInvitation(false)}
        onPositive={handlePrintInvitation}
      />
      <ConfirmationModal
        buttonNegative="Batal"
        buttonPositive="Hapus"
        title="Konfirmasi Hapus"
        show={showDelete}
        body={"Apakah anda yakin?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
    </Card>
  );
};
