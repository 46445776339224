import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";

import warehouses from "redux/slices/warehouses";
import vehicles from "redux/slices/vehicles";
import users from "redux/slices/users";
import subdistricts from "redux/slices/subdistricts";
import shipments from "redux/slices/shipments";
import queuings from "redux/slices/queuings";
import provinces from "redux/slices/provinces";
import periods from "redux/slices/periods";
import kpms from "redux/slices/kpms";
import drivers from "redux/slices/drivers";
import districts from "redux/slices/districts";
import cities from "redux/slices/cities";
import reports from "./slices/reports";
import dashboard from "./slices/dashboard";
import bast from "./slices/bast";
import roles from "./slices/roles";
import recap from "./slices/recap";
import pbp from "./slices/pbp";
import pengiriman from "./slices/pengiriman";
import createdShipments from "./slices/createdShipments";
import sptjm from "./slices/sptjm";
import vervalApprove from './slices/approveVerval'

const rootReducer = combineReducers({
  template,
  authToken,
  warehouses,
  vehicles,
  users,
  subdistricts,
  shipments,
  queuings,
  provinces,
  periods,
  kpms,
  drivers,
  districts,
  cities,
  reports,
  dashboard,
  bast,
  roles,
  recap,
  pbp,
  pengiriman,
  createdShipments,
  sptjm,
  vervalApprove
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
