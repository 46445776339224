import { APIService } from "services";

export const getDrivers = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllDrivers(pageNo,pageSize);
    }
    else{
        try {
            res = await searchDrivers(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addDrivers = (data) => {
return APIService.api().post(`/drivers`,data)
}
export const updateDrivers = (data) => {
return APIService.api().put(`/drivers/${data.id}`,data)
}
export const getAllDrivers = (page,paginator) => {
return APIService.api().get(`/drivers?page=${page}&paginator=${paginator}`)
}
export const getOneDrivers = (id) => {
return APIService.api().get(`/drivers/${id}`)
}
export const searchDrivers = (searchKey,page,paginator) => {
return APIService.api().get(`/drivers/search/${searchKey}/?page=${page}&paginator=${paginator}`)
}
export const deleteDrivers = (id) => {
return APIService.api().delete(`/drivers/${id}`)
}
