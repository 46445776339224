import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetCitiesToInit, resetDistrictsToInit, resetProvincesToInit, resetSubdistrictsToInit, resetUsersToInit, setCitiesList, setCitiesMessage, setDistrictsList, setDistrictsMessage, setProvincesList, setProvincesMessage, setSubdistrictsList, setSubdistrictsMessage, setUsersMessage, startLoadingCities, startLoadingDistricts, startLoadingProvinces, startLoadingSubDistricts, stopLoadingCities, stopLoadingDistricts, stopLoadingProvinces, stopLoadingSubDistricts } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Spinner, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteUsers } from "services/usersService";
import { getCities } from "services/citiesService";
import { getDistricts } from "services/districtsService";
import { getProvinces } from "services/provincesService";
import { getSubdistricts } from "services/subdistrictsService";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey, periode_id, date, province_id, city_id, district_id, subdistrict_id) => void;
};
export const UsersTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.users);
    const isLoading = useSelector((state: RootState) => state.users.loading);
    const provincesData = useSelector((state: RootState) => state.provinces);
    const citiesData = useSelector((state: RootState) => state.cities);
    const districtsData = useSelector((state: RootState) => state.districts);
    const subdistrictsData = useSelector((state: RootState) => state.subdistricts);
    const provincesLoading = useSelector((state: RootState) => state.provinces.loading);
    const citiesLoading = useSelector((state: RootState) => state.cities.loading);
    const districtsLoading = useSelector((state: RootState) => state.districts.loading);
    const subdistrictsLoading = useSelector((state: RootState) => state.subdistricts.loading);
    const periodsId = localStorage.getItem('periode_id')
    const districtId = localStorage.getItem('district_id')
    const roles = localStorage.getItem('user')
    const provinceName = localStorage.getItem('province_name')
    const cityName = localStorage.getItem('city_name')
    const handleSearch = () => {
        if (search.length > 0) {
            setSelectedProvince(0)
            setSelectedDate('')
            setSelectedCity(0)
            setSelectedDistrict(0)
            setSelectedSubdistrict(0)
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search ?? '', periodsId ?? '', selectedDate ?? '', selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, search ?? '', periodsId ?? '', selectedDate ?? '', selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict);
    };
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, search ?? '', periodsId ?? '', selectedDate ?? '', selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict);
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    };
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0 && !search && !selectedDate && !selectedProvince && !selectedCity && !selectedDistrict && !selectedSubdistrict) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId, '', selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict);
        }
        if (provincesData && provincesData.list && provincesData.list.length === 0) {
            dispatch(resetProvincesToInit());
            dispatch(startLoadingProvinces())
            getProvinces(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                    dispatch(setProvincesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingProvinces())
                } else {
                    dispatch(setProvincesMessage("Data kosong"));
                    dispatch(stopLoadingProvinces())
                }
            })
        }
        // if (periodsData && periodsData.list && periodsData.list.length === 0) {
        //     dispatch(resetPeriodsToInit());
        //     dispatch(startLoadingPeriods())
        //     getPeriods(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
        //         if (response && response.records) {
        //             dispatch(setPeriodsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
        //             dispatch(stopLoadingPeriods())
        //         } else {
        //             dispatch(setPeriodsMessage("Data kosong"));
        //             dispatch(stopLoadingPeriods())
        //         }
        //     })
        // }
    }, [rData.list.length])
    const handleReset = () => {
        setSearch('');
        dispatch(resetUsersToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '', periodsId, '', selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict);
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteUsers(rowData.id);
            if (response) {
                dispatch(resetUsersToInit());
                dispatch(setUsersMessage("Berhasil menghapus data"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId, '', selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict);
                setShowDelete(false);
            } else {
                dispatch(setUsersMessage("Terjadi kesalahan"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }

    const columns = [
        // { name: 'id', selector: row => row.id, sortable: true },
        // { name: 'Nama Gudang', selector: row => row.warehouse_name, sortable: true },
        // {name: 'code', selector: row => row.warehouses.code, sortable: true},
        { name: 'Email', selector: row => row.email, sortable: true },
        // { name: 'password', selector: row => row.password, sortable: true },
        { name: 'Roles', selector: row => row.role?.role_name, sortable: true },
        { name: 'Levels', selector: row => row.levels, sortable: true },
        // { name: 'remember_token', selector: row => row.remember_token, sortable: true },
        // { name: 'created_at', selector: row => row.created_at, sortable: true },
        // { name: 'updated_at', selector: row => row.updated_at, sortable: true },

        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Lihat</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Hapus</Button>,
        },
    ];
    const [selectedPeriods, setSelectedPeriods] = useState(0)
    const [selectedProvince, setSelectedProvince] = useState(0);
    const [selectedCity, setSelectedCity] = useState(0);
    const [selectedDistrict, setSelectedDistrict] = useState(0);
    const [selectedSubdistrict, setSelectedSubdistrict] = useState(0);
    const [selectedDate, setSelectedDate] = useState(""); // State untuk mengelola nilai input date
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value); // Memperbarui nilai state saat input date berubah
    };
    const handleProvinceChange = (event) => {
        const newValue = event.target.value;
        setSelectedProvince(newValue);
    };

    const handleCityChange = (event) => {
        const newValue = event.target.value;
        setSelectedCity(newValue);
    };

    const handleDistrictChange = (event) => {
        const newValue = event.target.value;
        setSelectedDistrict(newValue);
    };

    const handleSubdistrictChange = (event) => {
        const newValue = event.target.value;
        setSelectedSubdistrict(newValue);
    };
    useEffect(() => {
        if (selectedProvince) {
            dispatch(resetCitiesToInit());
            dispatch(startLoadingCities())
            getCities(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', selectedProvince).then((response) => {
                if (response && response.records) {
                    dispatch(setCitiesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingCities())
                } else {
                    dispatch(setCitiesMessage("Data kosong"));
                    dispatch(stopLoadingCities())
                }
            })
        }
    }, [selectedProvince]);

    useEffect(() => {
        if (selectedCity) {
            dispatch(resetDistrictsToInit());
            dispatch(startLoadingDistricts())
            getDistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', selectedCity).then((response) => {
                if (response && response.records) {
                    dispatch(setDistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingDistricts())
                } else {
                    dispatch(setDistrictsMessage("Data kosong"));
                    dispatch(stopLoadingDistricts())
                }
            })
        }
    }, [selectedCity]);

    useEffect(() => {
        if (selectedDistrict) {
            dispatch(resetSubdistrictsToInit());
            dispatch(startLoadingSubDistricts())
            getSubdistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', selectedDistrict).then((response) => {
                if (response && response.records) {
                    dispatch(setSubdistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingSubDistricts())
                } else {
                    dispatch(setSubdistrictsMessage("Data kosong"));
                    dispatch(stopLoadingSubDistricts())
                }
            })
        }
    }, [selectedDistrict]);
    const filterData = () => {
        setSearch('')
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId, selectedDate, selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict)
    }
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary">Daftar Pengguna ({rData.totalCount})
                    <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
                    {/* <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
                        <span className="icon text-white-50">
                            <i className="fas fa-add"></i>
                        </span>
                        <span className="text">Add New</span>
                    </Button> */}
                </h6>

            </Card.Header>
            <Card.Body>
                <Row>
                    <Col md={6}>
                        {/* <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control as="select" custom onChange={handlePeriodsChange} value={selectedPeriods} required>

                  <option value={0}>{periodsLoading ? 'Loading...' : 'Pilih Periode'}</option>
                  {
                    periodsData.list.map((item, i) => {
                      return <option value={item.id} key={`periods-${i}`}>{item.id} ({item.month} - {item.year})</option>
                    })}
                </Form.Control>
              </Form.Group> */}
                          <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                type="date"
                custom
                className="form-control"
                value={selectedDate} // Menampilkan nilai dari state pada input date
                onChange={handleDateChange} //
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control as="select" custom value={selectedProvince} disabled={roles !== 'Super Admin'}
                onChange={handleProvinceChange}>
                <option value={0} disabled>{provincesLoading ? 'Loading...' : roles !== 'Super Admin' ? provinceName : 'Pilih Provinsi'}</option>
                {
                  provincesData.list.map((item, i) => {
                    return <option value={item.province_id} key={`provinces-${i}`}>{item.province_name}</option>
                  })}
              </Form.Control>
            </Form.Group>
                    </Col>
                    <Col md={6} className="d-none d-md-block">
                        <InputGroup>
                            <Form.Control
                                placeholder="Cari"
                                aria-label="Search"
                                aria-describedby="basic-search"
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                            />
                            <Button
                                variant="dark"
                                className="rounded-0 rounded-right mr-2"
                                id="button-search"
                                onClick={handleSearch}
                            >
                                Cari
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col md={6}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control as="select" custom value={selectedCity} disabled={roles !== 'Super Admin'}
                onChange={handleCityChange}>
                <option value={0} disabled>{citiesLoading ? 'Loading...' : roles !== 'Super Admin' ? cityName : 'Pilih Kota'}</option>
                {
                  citiesData.list.map((item, i) => {
                    return <option value={item.city_id} key={`cities-${i}`}>{item.city_name}</option>
                  })}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control as="select" custom value={selectedDistrict}
                onChange={handleDistrictChange}>
                <option value={0} disabled>{districtsLoading ? 'Loading...' : 'Pilih Kecamatan'}</option>
                {
                  districtsData.list.map((item, i) => {
                    return <option value={item.district_id} key={`districts-${i}`}>{item.district_name}</option>
                  })}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control as="select" custom value={selectedSubdistrict}
                onChange={handleSubdistrictChange}>
                <option value={0} disabled>{subdistrictsLoading ? 'Loading...' : 'Pilih Kelurahan'}</option>
                {
                  subdistrictsData.list.map((item, i) => {
                    return <option value={item.subdistrict_id} key={`subdistricts-${i}`}>{item.subdistrict_name}</option>
                  })}
              </Form.Control>
            </Form.Group>
            <Button className="float-right d-none d-md-block" type="primary" onClick={() => filterData()}>Filter</Button>
            <Button className="float-right mb-3 d-md-none" type="primary" onClick={() => filterData()}>Filter</Button>
            <div className="d-md-none">
              <InputGroup>
                <Form.Control
                  placeholder="Cari"
                  aria-label="Search"
                  aria-describedby="basic-search"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <Button
                  variant="dark"
                  className="rounded-0 rounded-right"
                  id="button-search"
                  onClick={handleSearch}
                >
                  Cari
                </Button>
              </InputGroup>
            </div>
          </Col>
                </Row>

                <div className="table-responsive mt-5">
                    {isLoading && rData.list.length === 0 ? <Spinner animation="border" variant="warning" className="d-flex mx-auto"></Spinner> : <DataTable
                        // selectableRows={true}
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageNumber}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={columns} data={rData.list}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-bordered"
                        pagination
                        paginationServer
                        progressPending={isLoading}
                        onChangePage={handlePageChange}></DataTable>}

                </div>
            </Card.Body>
            <ConfirmationModal buttonNegative="Batal" buttonPositive="Hapus" title="Konfirmasi Hapus" show={showDelete} body={"Apakah anda yakin?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        </Card>
    );
}

