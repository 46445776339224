import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setVehiclesMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addVehicles, updateVehicles } from "services/vehiclesService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { useNavigate } from "react-router";

type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const VehiclesForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue = { id: 0, make: '', model: '', year: 0, plate_number: '', created_at: '', updated_at: '' };
    const initialValue = action === 'edit' ? row : iValue;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            setLoading(true)
            if (action === 'edit') {
                updateVehicles(values).then((response: any) => {
                    if (response && response.data && response.data.code === 1) {
                        setLoading(false)
                        dispatch(setVehiclesMessage("Berhasil mengubah data"));
                        getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                        hideShowForm('');
                    }
                }).catch(error => {
                    dispatch(setVehiclesMessage("Terjadi kesalahan"));
                    formik.resetForm()
                    setLoading(false)
                })
            } else if (action === 'add') {
                addVehicles(values).then((response: any) => {
                    if (response && response.data && response.data.code === 1) {
                        setLoading(false)
                        dispatch(setVehiclesMessage("Berhasil menambah data"));
                        getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                        navigate('/vehicles')
                    }
                }).catch(error => {
                    dispatch(setVehiclesMessage("Terjadi kesalahan"));
                    formik.resetForm()
                    setLoading(false)
                })
            }
        },
        validationSchema: yup.object({
            make: yup.string().required('Merk wajib diisi'),
            model: yup.string().required('Model wajib diisi'),
            year: yup.number().required('Tahun wajib diisi'),
            plate_number: yup.string().required('Plat Nomor wajib diisi'),
            // created_at: yup.date().nullable(),
            // updated_at: yup.date().nullable(),

        }),
    });
    const resetForm = () => {
        formik.resetForm()
    }
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action === 'add' ? 'Tambah' : 'Ubah'} Kendaraan
                    {/* <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Vehicles</span>
                    </Button> */}
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit} className="w-100 d-flex flex-wrap">
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Merk</label>
                        <Form.Control type="text" name="make" className="form-control" value={formik.values.make}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.make && !!formik.errors.make}
                            isValid={!!formik.touched.make && !formik.errors.make}
                        ></Form.Control>
                        {
                            formik.errors.make && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.make}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Model</label>
                        <Form.Control type="text" name="model" className="form-control" value={formik.values.model}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.model && !!formik.errors.model}
                            isValid={!!formik.touched.model && !formik.errors.model}
                        ></Form.Control>
                        {
                            formik.errors.model && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.model}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Tahun</label>
                        <Form.Control type="text" name="year" className="form-control" value={formik.values.year}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.year && !!formik.errors.year}
                            isValid={!!formik.touched.year && !formik.errors.year}
                        ></Form.Control>
                        {
                            formik.errors.year && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.year}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Nomor Plat</label>
                        <Form.Control type="text" name="plate_number" className="form-control" value={formik.values.plate_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.plate_number && !!formik.errors.plate_number}
                            isValid={!!formik.touched.plate_number && !formik.errors.plate_number}
                        ></Form.Control>
                        {
                            formik.errors.plate_number && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.plate_number}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    {/* <Form.Group>
                        <label className="form -control-label">created_at</label>
                        <Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.created_at && !!formik.errors.created_at}
                            isValid={!!formik.touched.created_at && !formik.errors.created_at}
                        ></Form.Control>
                        {
                            formik.errors.created_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.created_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">updated_at</label>
                        <Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.updated_at && !!formik.errors.updated_at}
                            isValid={!!formik.touched.updated_at && !formik.errors.updated_at}
                        ></Form.Control>
                        {
                            formik.errors.updated_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.updated_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}

                    <Form.Group className="w-100 px-2">
                        <Button type="submit" className="float-left mr-3" variant="primary" disabled={loading}>{loading && <Spinner variant="light" animation="border" size="sm" className="mr-1"></Spinner>}Simpan</Button>
                        <Button className="float-left" variant="secondary" onClick={() => resetForm()}>Reset</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

